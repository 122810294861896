import querystring from 'querystring';
import * as R from 'ramda';

/*
 * search = ?foo=bar
 * // { foo: 'bar' }
 *
 * search = ?foo=bar&foo=baz
 * // { foo: ['bar', 'baz'] }
 */

// parseQueryString :: string -> object
export function parseQueryString(
  search: string,
): Record<string, string | string[]> {
  return R.o(querystring.parse, R.drop(1))(search);
}

/*
 * key = 'foo'
 * qs = '?foo=bar'
 * // 'bar'
 */

// qsValue:: string -> string -> string | string[] | undefined
export const qsValue = R.curry((key: string, search: string) =>
  R.o(R.prop(key), parseQueryString)(search),
);

/*
 * defaultValue = 'baz'
 * key = 'foo'
 * qs = '?foo=bar'
 * // 'bar'
 *
 *
 * defaultValue = 'baz'
 * key = 'foo'
 * qs = '?hello=world'
 * // 'baz'
 */

// qsValue:: string -> string -> string
export const qsValueOr = R.curry(
  (defaultValue: string, key: string, search: string) =>
    R.defaultTo(defaultValue, qsValue(key, search)),
);

/*
 * search = '?foo=bar'
 * param = 'hello'
 * value = 'world'
 * // ?foo=bar&hello=world
 */

// addParam:: string -> string -> unknown -> string
export const addParam = R.curry(
  (search: string, param: string, value: unknown): string => {
    return R.compose(
      R.concat('?'),
      querystring.stringify,
      R.unless(R.always(R.isEmpty(value)), R.assoc(param, value)),
      querystring.parse,
      R.when(R.length, R.drop(1)),
    )(search);
  },
);

/*
 * search = '?foo=bar&hello=world'
 * param = 'hello'
 * // ?foo=bar
 */

// removeParam:: string -> string -> string
export const removeParam = R.curry((search: string, param: string): string => {
  return R.compose(
    R.concat('?'),
    querystring.stringify,
    R.dissoc(param),
    querystring.parse,
    R.when(R.length, R.drop(1)),
  )(search);
});

/*
 * search = '?foo=bar'
 * param = 'hello'
 * value = 'biz'
 * // ?foo=biz
 */

// replaceParam:: string -> string -> string
export const replaceParam = R.curry(
  (search: string, param: string, value: unknown): string => {
    return R.o(addParam(R.__, param, value), removeParam(search))(param);
  },
);

/*
 * key = 'foo'
 * search = '?foo=bar'
 * value = 'baz'
 * // '?foo=baz'
 */

// toggleParam :: string -> string -> unknown -> string
export const toggleParam = R.curry(
  (key: string, search: string, value: unknown) =>
    R.ifElse(
      R.o(R.isEmpty, R.defaultTo('')),
      R.always(removeParam(search, key)),
      replaceParam(search, key),
    )(value),
);
