import { EditorView } from '@codemirror/view';
import * as R from 'ramda';

export const altEnter = R.curry(
  (mode: string, setError: (val: string) => void, target: EditorView) => {
    import('@x/utils').then(({ formatJavaScript }) => {
      if (mode === 'javascript') {
        formatJavaScript(setError, target);
      }
    });
    return true;
  },
);
