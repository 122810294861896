import _keys from 'lodash/keys';
import _transform from 'lodash/transform';
import { ArrayMapInput } from '../components/ArrayMapInput';
import { ContainedObjectInput } from '../components/ContainedObjectInput';
import { JsonFormSchema } from '../types';

export function formatPostActionProperties(schema: JsonFormSchema): any {
  const properties = schema.properties || schema.items?.properties;

  if (!properties) {
    return {};
  }

  return _transform<JsonFormSchema, Record<string, any>>(
    properties,
    (acc, prop, propName) => {
      const normalizedType =
        prop.type instanceof Array ? prop.type : [prop.type];
      const isObject = normalizedType.indexOf('object') > -1;
      const isArray = normalizedType.indexOf('array') > -1;
      const isArrayOfObjects = isArray && prop.items?.type === 'object';

      acc[propName] = prop;

      if (isArrayOfObjects) {
        acc[propName].items.properties = formatPostActionProperties(prop);

        acc[propName].renderer = ArrayMapInput;
      } else if (isObject && _keys(acc[propName].properties).length) {
        acc[propName].properties = formatPostActionProperties(prop);

        acc[propName].renderer = acc[propName].renderer || ContainedObjectInput;
      } else {
        acc[propName].title = acc[propName].title || propName;
        acc[propName].inputProps = acc[propName].inputProps || {};
        acc[propName].inputProps.hideLabel =
          acc[propName].inputProps.hideLabel ?? true;
        acc[propName].inputProps.layout = acc[propName].inputProps.layout || {
          wrapperCol: 24,
        };
        acc[propName].renderer = acc[propName].renderer || 'codeBlock';
      }
    },
    {},
  );
}

export default formatPostActionProperties;
