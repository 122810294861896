import { deriveProp } from '@x/utils';
import { InputSelector } from 'components/Form';
import * as R from 'ramda';
import React, { useState } from 'react';
import { expressionOption } from '../config';
import { InputProps } from '../types/FormProps.interface';

const hideLabelSchema = {
  schema: { inputProps: { hideLabel: true } },
};

function getInitialInput(val: unknown): string | undefined {
  if (val === null) return 'null';
  if (typeof val === 'string' && val?.[0] === '=') return 'expression';
  if (typeof val === 'string' && val !== '') return 'string';
  if (typeof val === 'number') return 'number';
  if (typeof val === 'boolean') return 'boolean';
  if (typeof val === 'object' && val !== null) return 'array';
  return undefined;
}

export function DynamicInput(props: InputProps): React.JSX.Element {
  const { InputWrapper, schema, handleChange, currentValue } = props;
  const { inputProps } = schema;
  const inputOptions = inputProps?.inputOptions ?? [];
  // needs to be flattened because CodeBlockInput can pass in a nested array of allowed types
  const allowedTypes = inputProps?.allowedTypes
    ?.flat()
    .map((t: string) =>
      t.toLowerCase() === 'integer' ? 'number' : t.toLowerCase(),
    );
  const hasAllowedTypes = R.always(allowedTypes);
  const isNotAllowed = R.pipe(
    R.prop('type'),
    R.toLower,
    R.includes(R.__, allowedTypes),
    R.not,
  );
  const optionsToUse = R.pipe(
    R.prepend(expressionOption),
    R.when(hasAllowedTypes, R.map(deriveProp('disabled', isNotAllowed))),
  )(inputOptions);
  const defaultInputType = inputProps?.defaultInputType?.toLowerCase();
  const [activeInput, setActiveInput] = useState(getInitialInput(currentValue));
  const updatedProps = R.pipe(
    R.mergeDeepRight(R.__, hideLabelSchema),
    R.dissocPath(['schema', 'inputProps', 'inputOptions']),
    R.dissocPath(['schema', 'inputProps', 'allowedTypes']),
    R.dissocPath(['schema', 'inputProps', 'defaultInputType']),
  )(props);

  function onInputChange(next: string, prev: string) {
    setActiveInput(next);

    if (next === 'null') {
      handleChange(null);
    }

    if (!currentValue) return;

    if (next === 'expression') {
      // prepends '=' when switching to Expression
      if (inputProps?.parseEqualsSign !== false && currentValue[0] !== '=') {
        handleChange('=' + currentValue);
      }

      // in case there's an extra leading '=' when switching to Expression
      if (currentValue?.toString().indexOf('==') === 0) {
        handleChange(currentValue.substring(1));
      }
    }

    if (next === 'string') {
      // removes leading '=' when coming from Expression
      if (prev === 'expression' && currentValue[0] === '=') {
        handleChange(currentValue.substring(1));
      }

      if (typeof currentValue !== 'string') {
        handleChange(String(currentValue));
      }
    }

    if (next === 'number' && typeof currentValue !== 'number') {
      const newValue = isNaN(+currentValue) ? undefined : +currentValue;

      handleChange(newValue);
    }

    if (next === 'array') {
      if (typeof currentValue === 'string') {
        const preppedVal =
          currentValue[0] === '=' ? currentValue.substring(1) : currentValue;
        const newValue = preppedVal.split(',');

        handleChange(newValue);
      } else {
        handleChange([currentValue]);
      }
    }
  }

  return (
    <InputWrapper {...props}>
      <InputSelector
        inputProps={updatedProps}
        inputOptions={optionsToUse}
        activeInput={activeInput}
        defaultInput={defaultInputType}
        onInputChange={onInputChange}
      />
    </InputWrapper>
  );
}
