import { useTheme } from '@emotion/react';
import { LayoutInterface } from '@x/types';
import * as R from 'ramda';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

function getIsMobile(mobileBreakpoint: number) {
  const screenSize = window.innerWidth;

  return screenSize < mobileBreakpoint;
}

export const LayoutContext = createContext<LayoutInterface>({
  closeSideNav: R.F,
  hasSideNav: false,
  isMobile: false,
  openSideNav: R.F,
  setHasSideNav: R.F,
  sideNavOpen: false,
  toggleSideNav: R.F,
});

type LayoutProviderProps = {
  children: ReactNode;
};

export function LayoutProvider({
  children,
}: LayoutProviderProps): React.JSX.Element {
  const theme = useTheme();
  const { mobileBreakpoint } = theme.layout;
  const [isMobile, setIsMobile] = useState(() => getIsMobile(mobileBreakpoint));
  const [hasSideNav, setHasSideNav] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(false);

  const openSideNav = useCallback(() => setSideNavOpen(true), []);
  const closeSideNav = useCallback(() => setSideNavOpen(false), []);
  const toggleSideNav = useCallback(() => setSideNavOpen(R.not), []);

  const layout = {
    closeSideNav,
    hasSideNav,
    isMobile,
    openSideNav,
    setHasSideNav,
    sideNavOpen,
    toggleSideNav,
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(getIsMobile(mobileBreakpoint));

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobileBreakpoint]);

  useEffect(() => {
    setSideNavOpen(!isMobile);
  }, [isMobile]);

  return (
    <LayoutContext.Provider value={layout}>{children}</LayoutContext.Provider>
  );
}
