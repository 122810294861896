import { ErrorObject } from 'ajv';
import * as R from 'ramda';

export function mapAjvErrors(
  valErrors?: ErrorObject<string, Record<string, any>, unknown>[] | null,
): string[] {
  if (!valErrors) return [];

  return R.uniq(
    valErrors.map((e) => `${e.instancePath || '/root'}  |  ${e.message}`),
  );
}
