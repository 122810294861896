import * as R from 'ramda';

const tryToParseJson = R.tryCatch(R.unary(JSON.parse));

export const safeParseJson = R.curry(
  (defaultValue: unknown, str: string | null) =>
    R.ifElse(
      R.identity,
      tryToParseJson(R.always(defaultValue)),
      R.always(defaultValue),
    )(str),
);
