import { convertForCodeMirror } from '@x/utils';
import React, { useEffect, useRef, useState } from 'react';

export function useValues(
  initialValue?: Record<string, unknown> | string,
  value?: Record<string, unknown> | string,
): {
  codeMirrorValue: string;
  memoizedInitialValue: React.MutableRefObject<string>;
} {
  const [storedInitial, setStoredInitial] = useState(false);
  const codeMirrorValue = convertForCodeMirror(value);
  const codeMirrorInitialValue = convertForCodeMirror(initialValue);
  const memoizedInitialValue = useRef(codeMirrorInitialValue);

  useEffect(() => {
    if (codeMirrorInitialValue && !storedInitial) {
      memoizedInitialValue.current = codeMirrorInitialValue;
      setStoredInitial(true);
    }
  }, [codeMirrorInitialValue, storedInitial]);

  return { memoizedInitialValue, codeMirrorValue };
}
