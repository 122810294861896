import { useEffect, useState } from 'react';

export function useLoaded(): boolean {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // This is a bit of a hack to address an issue when we can sometimes load 100’s of codemirrors it can freeze the page until they are all done. By doing a 1ms delay, it defers the rendering to a non-blocking cycle.
    // Updating to React v18 and using transition/concurrency should do away with the need for this.
    const cancel = setTimeout(() => {
      if (!loaded) {
        setLoaded(true);
      }
    }, 1);

    return () => {
      clearTimeout(cancel);
    };
  }, [loaded]);

  return loaded;
}
