import { VSpace } from '@x/components';
import { Space, Switch } from 'antd';
import * as R from 'ramda';
import React from 'react';
import { defaultDynamicOptions } from '../config';
import { InputProps } from '../types';
import { ArrayInput } from './ArrayInput';
import { FormRenderer } from './FormRenderer';
import { ObjectInput } from './ObjectInput';
import { OptionalInputContainer } from './OptionalInputContainer';

function MappedArray(props: InputProps): React.JSX.Element {
  const { path, schema } = props;

  return (
    <ObjectInput {...props} path={`${path}.Map`} schema={schema.items ?? {}} />
  );
}

export function ArrayMapInput(props: InputProps): React.JSX.Element {
  const { currentValue, handleChange, path, readOnly } = props;
  const isMappedArray = currentValue && !(currentValue instanceof Array);

  function handleArrayMapChange(checked: boolean) {
    if (checked) {
      handleChange({
        $InputJson: '',
        Map: R.path([0], currentValue),
      });
    } else {
      handleChange(currentValue?.Map ? [currentValue?.Map] : []);
    }
  }

  return (
    <OptionalInputContainer inputProps={props}>
      <VSpace size="middle">
        <label>
          <Space>
            <Switch
              disabled={readOnly}
              title="Array Map"
              checked={isMappedArray}
              onChange={handleArrayMapChange}
            />
            Array Map
          </Space>
        </label>
        {isMappedArray && (
          <FormRenderer
            {...props}
            schema={{
              type: 'string',
              title: 'List or Object',
              renderer: 'dynamic',
              inputProps: {
                inputOptions: defaultDynamicOptions,
                allowedTypes: ['expression'],
              },
            }}
            path={`${path}.$InputJson`}
          />
        )}
        {isMappedArray ? <MappedArray {...props} /> : <ArrayInput {...props} />}
      </VSpace>
    </OptionalInputContainer>
  );
}

export default ArrayMapInput;
