import { LoadingOutlined } from '@ant-design/icons';
import { XTableProps } from '@x/types';
import { parseQueryString, toggleParam } from '@x/utils';
import { Alert, Table, TableProps } from 'antd';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import _toNumber from 'lodash/toNumber';
import * as R from 'ramda';
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

function getSortKey(field: string | string[]): string {
  if (Array.isArray(field)) {
    return field.join('.');
  }

  return field;
}

export function XTable<TData>({
  tableData,
  offset = -32,
  error,
  qsKeys,
  loading,
  ...props
}: XTableProps<TData>): React.JSX.Element {
  const [, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const { PageSize = '25', PageNumber = '1' } = parseQueryString(search);
  const defaultProps: TableProps<TData> = {
    size: 'middle',
    dataSource: tableData?.data ?? [],
    scroll: { x: true },
    sticky: { offsetHeader: offset },
    bordered: true,
    showSorterTooltip: false,
    pagination: {
      total: tableData?.total ? tableData.total : undefined,
      current: _toNumber(PageNumber),
      pageSize: _toNumber(PageSize),
      pageSizeOptions: ['10', '25', '50', '100'],
      position: ['topRight', 'bottomRight'],
      showTotal: (_total: number, [start, end]: unknown[]) =>
        `${start}-${end} of ${Number(tableData?.total).toLocaleString()}`,
    },
    loading: {
      indicator: <LoadingOutlined spin />,
      spinning: Boolean(loading),
    },
    onChange: (
      pagination: TablePaginationConfig,
      _filters: unknown,
      sort: SorterResult<TData> | SorterResult<TData>[],
    ) => {
      const newParams = R.compose(
        toggleParam(
          qsKeys?.orderBy ?? 'OrderBy',
          R.__,
          R.ifElse(
            R.prop('order'),
            (srt: SorterResult<TData> | SorterResult<TData>[]) => {
              const field = R.path(['field'], srt);

              return getSortKey(field);
            },
            R.always(null),
          )(sort),
        ),
        toggleParam(
          qsKeys?.ascending ?? 'Ascending',
          R.__,
          R.ifElse(
            R.prop('order'),
            R.propEq('order', 'ascend'),
            R.always(null),
          )(sort),
        ),
        toggleParam(
          qsKeys?.pageNumber ?? 'PageNumber',
          R.__,
          R.ifElse(
            R.propEq('current', _toNumber(PageNumber)),
            R.always(''),
            R.prop('current'),
          )(pagination),
        ),
        toggleParam(qsKeys?.pageSize ?? 'PageSize', R.__, pagination.pageSize),
      )(search);

      setSearchParams(newParams);
    },
  };
  const allProps = R.mergeDeepRight(defaultProps, props);

  if (error) {
    return (
      <Alert
        type="error"
        showIcon
        message={`Error fetching table data${
          typeof error === 'string' ? `: ${error}` : ''
        }`}
      />
    );
  }

  return <Table {...allProps} />;
}
