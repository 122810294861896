import { javascript } from '@codemirror/lang-javascript';
import { json } from '@codemirror/lang-json';
import { markdown } from '@codemirror/lang-markdown';
import { sql } from '@codemirror/lang-sql';
import { xml } from '@codemirror/lang-xml';
import { StreamLanguage } from '@codemirror/language';
import { csharp } from '@codemirror/legacy-modes/mode/clike';
import { yaml } from '@codemirror/legacy-modes/mode/yaml';
import { linter, lintGutter } from '@codemirror/lint';
import { Extension } from '@codemirror/state';
import { jsonLinter } from '@x/utils';
import { jsCompletionExt } from './jsCodeCompletions';
import { noActionJsLint } from './jsHelpers';

export function getLanguageExtensions(
  mode?: string,
  isFlow?: boolean,
  readOnly?: boolean,
): Extension[] | StreamLanguage<unknown>[] {
  const jsonLinting = readOnly ? [] : [linter(jsonLinter), lintGutter()];
  const jsLinting = readOnly ? [] : [linter(noActionJsLint), lintGutter()];

  switch (mode) {
    case 'application/json':
      return [json(), ...jsonLinting];
    case 'javascript':
      return [javascript(), ...jsLinting, isFlow ? jsCompletionExt : []];
    case 'sql':
      return [sql()];
    case 'text/x-csharp':
      return [StreamLanguage.define(csharp)];
    case 'xml':
      return [xml()];
    case 'yaml':
      return [StreamLanguage.define(yaml)];
    case 'markdown':
      return [markdown()];
    default:
      return [];
  }
}
