import * as R from 'ramda';
import { omitByDeep } from './omitBy';

const configTypeIsObject = R.curry(
  <T>(config: T, _value: unknown, key: string) =>
    R.pathEq([key, 'type'], 'object', config),
);
const isNilObject = <T>(config: T) =>
  R.allPass([R.isNil, configTypeIsObject(config)]);

export const completeConfigValues = <TVal, TConfig>(
  values: TVal,
  config: TConfig,
): Record<string, unknown> => {
  return omitByDeep(isNilObject(config), values);
};

export const completeConfig = (
  config: Record<string, any>,
): Record<string, any> => {
  const newObj = { ...config };

  if (newObj.properties) {
    newObj.required =
      newObj.required ||
      Object.keys(newObj.properties).reduce((arr: any[], prop: string) => {
        if (newObj.properties[prop].required) {
          arr.push(prop);
          delete newObj.properties[prop].required;
        }

        return arr;
      }, []);

    if (newObj.hidden) {
      newObj['ui:hidden'] = true;
    }

    newObj.properties = Object.keys(newObj.properties).reduce(
      (obj: any, key: string) => {
        obj[key] = {
          ...newObj.properties[key],
          title: newObj.properties[key].title || key,
        };

        if (obj[key].hidden) {
          obj[key]['ui:hidden'] = true;
        }

        obj[key].message = {
          required: 'This field is required.',
        };

        if (obj[key].type === 'array') {
          if ((obj[key].items || {}).enum) {
            obj[key].enum = obj[key].items.enum;
          }

          if (obj[key].items?.properties) {
            obj[key].items = completeConfig(obj[key].items);
          }
        }

        if (obj[key].enum && obj[key].type === 'string') {
          obj[key].enum = ['', ...obj[key].enum];
        }

        obj[key] = completeConfig(obj[key]);

        return obj;
      },
      {},
    );
  }

  return newObj;
};

export const lsBoolean = (property: string): boolean => {
  const lsValue = localStorage.getItem(property);

  if (!lsValue) {
    return false;
  }

  return !!JSON.parse(lsValue);
};

const isNilOrEmptyString = R.anyPass([R.isNil, R.equals('')]);

export const cleanSchemaData = omitByDeep(isNilOrEmptyString);
