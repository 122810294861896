import { SelectOption, TCurry2 } from '@x/types';
import _startCase from 'lodash/startCase';
import * as R from 'ramda';
import { renameKeys } from './renameKeys';

type OptionMap = { value: string; label: string };

export const makeOptionFromString: (str: string) => SelectOption = R.converge(
  R.mergeRight,
  [R.assoc('value', R.__, {}), R.assoc('label', R.__, {})],
);

export const makeOptionsFromString = R.map(makeOptionFromString);

const valueEqualsLabel = R.converge(R.equals, [
  R.prop('value'),
  R.prop('label'),
]);

const makeOptionFromObject = ({ value, label }: OptionMap): SelectOption =>
  R.pipe(
    renameKeys({ [value]: 'value', [label]: 'label' }),
    R.pick(['value', 'label']),
  );

const makeOptionsFromObject = (optionMap: OptionMap) =>
  R.map(makeOptionFromObject(optionMap));

/**Converts an array of objects into value/label options for ant design Select Inputs & Dropdowns */
export const makeOptions: TCurry2<
  OptionMap,
  Record<string, unknown>[],
  SelectOption[]
> = R.curry(
  (optionMap: OptionMap, arr: Record<string, unknown>[]): SelectOption[] =>
    R.ifElse(
      R.always(valueEqualsLabel(optionMap)),
      R.pipe(R.pluck(optionMap.value), makeOptionsFromString),
      makeOptionsFromObject(optionMap),
    )(arr),
);

/**Converts an array of values to value/label options for ant design Select Inputs & Dropdowns */
export const makeOptionsFromArray = (arr: string[]) =>
  arr.map((value) => ({
    label: _startCase(value),
    value,
  }));
