import { AutoComplete, AutoCompleteProps } from 'antd';
import React from 'react';

export function XAutoComplete(props: AutoCompleteProps): React.JSX.Element {
  return (
    <AutoComplete
      getPopupContainer={(trigger) => trigger.parentElement}
      {...props}
    />
  );
}
