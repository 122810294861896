import { CompletionConstructor } from '../types';

export const uuidCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'NIL',
      info: 'The nil UUID string (all zeros).',
    },
    {
      label: 'parse()',
      info: 'Convert UUID string to array of bytes.',
    },
    {
      label: 'stringify()',
      info: 'Convert array of bytes to UUID strin.',
    },
    {
      label: 'v1()',
      info: 'Create a version 1 (timestamp) UUID.',
    },
    {
      label: 'v3()',
      info: 'Create a version 3 (namespace w/ MD5) UUID.',
    },
    {
      label: 'v4()',
      info: 'Create a version 4 (random) UUID.',
    },
    {
      label: 'v5()',
      info: 'Create a version 5 (namespace w/ SHA-1) UUID.',
    },
    {
      label: 'validate()',
      info: 'Test a string to see if it is a valid UUID.',
    },
    {
      label: 'version()',
      info: 'Detect RFC version of a UUID.',
    },
  ],
  defaultValues: { type: 'function', detail: 'uuid v9.0.0' },
};
