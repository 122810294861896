import { CloseCircleFilled } from '@ant-design/icons';
import { Card, Space, theme, Typography } from 'antd';
import React from 'react';

type ComponentErrorProps = {
  componentName: string;
};

export function ComponentError({
  componentName,
}: ComponentErrorProps): React.JSX.Element {
  const { token } = theme.useToken();

  return (
    <Card style={{ width: '100%' }}>
      <Space direction="vertical" align="center" style={{ display: 'flex' }}>
        <Typography.Text
          type="danger"
          style={{ fontSize: token.fontSizeHeading1 }}
        >
          <CloseCircleFilled />
        </Typography.Text>
        <Typography.Text>{`Error loading ${componentName}`}</Typography.Text>
      </Space>
    </Card>
  );
}
