import { xTheme } from '@x/styles';
import { Button } from 'antd';
import React from 'react';

interface AllSelectedTableHeaderProps {
  tableData?: { data?: unknown[]; total?: number | null };
  selectedCount: number;
  allRecordsSelected: boolean;
  setSelectedRowKeys: (selectedRowKeys: unknown[]) => void;
  setAllRecordsSelected: (allRecordsSelected: boolean) => void;
}

export function AllSelectedTableHeader({
  tableData,
  selectedCount,
  allRecordsSelected,
  setSelectedRowKeys,
  setAllRecordsSelected,
}: AllSelectedTableHeaderProps): React.JSX.Element {
  return (
    <thead
      style={{
        backgroundColor: xTheme.colors.grayLighter,
        textAlign: 'center',
        padding: '12px 8px',
      }}
    >
      <tr style={{ cursor: 'normal' }}>
        <td colSpan={5} style={{ cursor: 'normal', height: 46 }}>
          {allRecordsSelected ? (
            <>
              All {tableData?.total} objects are selected{' '}
              <Button
                type="link"
                onClick={() => {
                  setSelectedRowKeys([]);
                  setAllRecordsSelected(false);
                }}
              >
                Clear selection.
              </Button>
            </>
          ) : (
            <>
              All {selectedCount} objects on this page are selected.{' '}
              <Button
                type="link"
                onClick={() => {
                  setAllRecordsSelected(true);
                }}
              >
                Select all {tableData?.total} objects.
              </Button>
            </>
          )}
        </td>
      </tr>
    </thead>
  );
}

export default AllSelectedTableHeader;
