import { Form, Tooltip, Typography } from 'antd';
import { Pre } from 'components/Pre';
import React from 'react';
import { InputProps, InputWrapperProps } from '../types';

const Text = Typography.Text;

export function InputWrapper({
  children,
  ...props
}: InputWrapperProps): React.JSX.Element {
  const {
    schema,
    required,
    label,
    errors,
    flattenedPath,
    name,
    id,
  }: InputProps = props;
  const error = errors[flattenedPath];
  const hasError = Boolean(error);
  const { description, inputProps = {} } = schema;
  const { hideLabel, layout = {} } = inputProps;
  const errorMsg = typeof error === 'string' ? error : `${label} is required`;
  const descriptionToUse =
    typeof description === 'function' ? description(props) : description;
  const showExtra = descriptionToUse || hasError;
  const extra = showExtra ? (
    <>
      {descriptionToUse || false}
      {descriptionToUse && hasError && <br />}
      {hasError && <Text type="danger">{errorMsg}</Text>}
    </>
  ) : undefined;

  return (
    <Form.Item
      htmlFor={id}
      data-testid={`inputWrapper-${name}`}
      label={!hideLabel && label}
      extra={
        extra ? (
          <Pre>
            <Typography.Text type="secondary">{extra}</Typography.Text>
          </Pre>
        ) : null
      }
      required={required}
      validateStatus={hasError ? 'error' : 'success'}
      {...layout}
    >
      <Tooltip title={inputProps.tooltip}>{children}</Tooltip>
    </Form.Item>
  );
}

export default InputWrapper;
