import * as R from 'ramda';

function _firstValidResultBy(predicate: any, fns: any, val: any): any {
  return R.reduce(
    R.pipe(R.nthArg(1), R.apply(R.__, [val]), R.when(predicate, R.reduced)),
    val,
    fns,
  );
}

const firstResultBy = R.curry(_firstValidResultBy);
const firstTruthyResult = firstResultBy(R.identity);

export const firstPath = R.curry(
  (paths: string[][], obj: Record<string, any>) =>
    R.pipe(R.map(R.path), firstTruthyResult(R.__, obj))(paths),
);
