import * as R from 'ramda';

/*
 * defaultValue = ''
 * value = undefined
 * // ''
 *
 * defaultValue = ''
 * value = 'test'
 * // 'test'
 *
 * defaultValue = 'foo'
 * value = ['bar', 'baz']
 * // 'bar,baz'
 *
 * defaultValue = 'foo'
 * value = true
 * // 'true'
 *
 */

// stringOr :: string -> unknown -> string
export const stringOr = R.curry((defaultValue: string, value: unknown) =>
  R.o(R.unless(R.is(String), R.toString), R.defaultTo(defaultValue))(value),
);
