import { Typography } from 'antd';
import React from 'react';
import { InputProps } from '../types';

export function NullInput(props: InputProps): React.JSX.Element {
  const { name } = props;

  return (
    <Typography.Text data-testid={`null-${name}`} code>
      Null
    </Typography.Text>
  );
}
