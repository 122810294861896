import { Button, Flex, theme } from 'antd';
import React from 'react';

type InfiniteScrollLoaderProps = {
  fetchNext: () => any;
  loading: boolean;
};

export function InfiniteScrollLoader({
  fetchNext,
  loading,
}: InfiniteScrollLoaderProps): React.JSX.Element {
  const { token } = theme.useToken();

  return (
    <Flex justify="center" style={{ padding: token.paddingLG }}>
      <Button type="primary" onClick={fetchNext} loading={loading}>
        Load more
      </Button>
    </Flex>
  );
}
