export function getPathToAttrToCheck(
  attrToCheck: string,
  path: string,
): string {
  const pathParts = path.split('.');
  const lastIndex = pathParts.length - 1;

  pathParts[lastIndex] = attrToCheck;

  return pathParts.join('.');
}
