import { hasValue } from '@x/utils';
import { InputNumber } from 'antd';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { InputProps } from '../types';

export function NumericInput(props: InputProps): React.JSX.Element {
  const {
    id,
    InputWrapper,
    schema,
    handleChange,
    currentValue,
    name,
    value,
    flattenedPath,
    setValue,
  } = props;
  const inputVal = currentValue ?? '';
  const { inputProps = {} } = schema;
  const { removeOnEmpty, noWrapper, changeOnBlur } = inputProps;
  const [newVal, setNewVal] = useState(inputVal);
  const inputOptions = R.omit(
    ['removeOnEmpty', 'hideLabel', 'layout', 'noWrapper', 'changeOnBlur'],
    inputProps,
  );

  useEffect(() => {
    setNewVal(inputVal);
  }, [inputVal]);

  function changeHandler(val: number | string | null) {
    if (removeOnEmpty && !hasValue(val)) {
      const valWithoutField = R.omit([flattenedPath], value);

      setValue(valWithoutField);
    } else {
      handleChange(val);
    }
  }

  function renderInput() {
    return (
      <InputNumber
        id={id}
        data-testid={`numeric-input-${name}`}
        onBlur={() => changeOnBlur && changeHandler(newVal)}
        name={name}
        value={changeOnBlur ? newVal : inputVal}
        onChange={(val) => {
          return changeOnBlur ? setNewVal(val) : changeHandler(val);
        }}
        controls={false}
        style={{ width: '100%' }}
        {...inputOptions}
      />
    );
  }

  if (noWrapper) {
    return renderInput();
  }

  return <InputWrapper {...props}>{renderInput()}</InputWrapper>;
}
