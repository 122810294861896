import * as R from 'ramda';
import { parseIfNumber } from './parseIfNumber';

/*
 * stringPath = 'steps.input'
 * // ['steps', 'input']
 *
 * stringPath = 'foo[0].bar'
 * // ['foo', 0, 'bar']
 *
 * stringPath = undefined
 * // []
 */

const shouldParseIndex = R.pipe(R.match(/\[\d+\]/), R.prop('length'));
const parseIndex = R.pipe(
  R.split(/\[|\]/),
  R.filter(Boolean),
  R.map(parseIfNumber),
);

// stringPathToArrayPath :: string? -> (string | number)[]
export function stringPathToArrayPath(stringPath: string): (string | number)[] {
  return R.compose(
    R.chain(R.when(shouldParseIndex, parseIndex)),
    R.split('.'),
    R.defaultTo(''),
  )(stringPath);
}

export function ensureArrayPath(
  path: string | (string | number)[],
): (string | number)[] {
  return R.when(R.is(String), stringPathToArrayPath)(path);
}

export default stringPathToArrayPath;
