import * as R from 'ramda';
import { omitBy, omitByDeep } from './omitBy';

/*
  cleans an object, removing any undefined or null key values

  cleanObject({ foo: false, bar: 0, baz: undefined, biz: null})
  // { foo: false, bar: 0 }
*/
export const cleanObject = omitBy(R.isNil);
export const cleanObjectDeep = omitByDeep(R.isNil);
