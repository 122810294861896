import { Badge, Space, theme, Tooltip, Typography } from 'antd';
import React from 'react';

type DynamicTabProps = {
  hasChanges: boolean;
  label: string;
  errors?: string[];
  disabled?: boolean;
  disabledTooltip?: string;
};

export function DynamicTab({
  hasChanges,
  label,
  errors,
  disabled,
  disabledTooltip = 'This tab is disabled.',
}: DynamicTabProps): React.JSX.Element {
  const { token } = theme.useToken();
  const hasErrors = errors?.length;
  const tooltip = hasErrors ? (
    <ul>
      {errors.map((error, i) => (
        <li key={i}>{error}</li>
      ))}
    </ul>
  ) : undefined;

  function textType() {
    if (hasErrors) {
      return 'danger';
    }

    if (disabled) {
      return 'secondary';
    }

    return undefined;
  }

  return (
    <Tooltip
      title={disabled ? disabledTooltip : tooltip}
      color={hasErrors ? token.colorError : undefined}
    >
      <Space>
        <Typography.Text type={textType()}>{label}</Typography.Text>
        {hasChanges && (
          <Badge
            data-testid={`tab-${hasErrors ? 'error' : 'changed'}`}
            color={hasErrors ? token.colorError : token.colorSuccess}
            style={{ width: 8 }}
          />
        )}
      </Space>
    </Tooltip>
  );
}
