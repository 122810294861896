import classnames from 'classnames';
import React, { useState } from 'react';
import styles from './xDrawer.module.less';

interface Props {
  drawerWidth: number;
  setDrawerWidth: any;
}

function DrawerWidthAdjuster({
  drawerWidth,
  setDrawerWidth,
}: Props): React.JSX.Element {
  const [active, setActive] = useState<boolean>(false);

  function startMoving(
    startEvent: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    const start = startEvent.clientX;

    setActive(true);

    function move(moveEvent: MouseEvent) {
      const end = moveEvent.clientX;
      const widthDelta = start - end;

      setDrawerWidth(drawerWidth + widthDelta);
    }

    window.addEventListener('mousemove', move);

    window.addEventListener(
      'mouseup',
      () => {
        setActive(false);

        window.removeEventListener('mousemove', move);
      },
      { once: true },
    );
  }

  return (
    <div
      onMouseDown={startMoving}
      className={classnames({
        [styles.adjuster]: true,
        [styles.active]: active,
      })}
    />
  );
}

export default DrawerWidthAdjuster;
