import { TCurry2 } from '@x/types';
import * as R from 'ramda';
import { whereEquals } from './whereEquals';

export const includesWhereObjEquals: TCurry2<
  Record<string, unknown>,
  Record<string, unknown>[],
  boolean
> = R.curry((obj: Record<string, unknown>, arr: Record<string, unknown>[]) =>
  R.any(whereEquals(obj), arr),
);
