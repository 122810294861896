import { EditorView, showPanel } from '@codemirror/view';
import { xTheme } from '@x/styles';

const { fontSizes, colors, space } = xTheme;

const panelDefaults = {
  padding: `${space[2]}px`,
  fontSize: `${fontSizes[0]}px`,
  fontFamily: 'monospace',
};

const warningTheme = EditorView.baseTheme({
  '.cm-warning-panel': {
    ...panelDefaults,
    backgroundColor: colors.warning,
  },
});

export function getWarningPanel(warningMessage?: string): any {
  if (!warningMessage) return [];
  const dom = document.createElement('div');

  dom.textContent = warningMessage;
  dom.className = 'cm-warning-panel';
  return [showPanel.of(() => ({ dom })), warningTheme];
}

const errorTheme = EditorView.baseTheme({
  '.cm-error-panel': {
    ...panelDefaults,
    color: colors.error,
  },
});

export function getErrorPanel(errorMessage?: string): any {
  if (!errorMessage) return [];
  const dom = document.createElement('div');
  const messageLines = errorMessage.split('\n');

  dom.setAttribute('style', 'white-space: pre;');
  messageLines.forEach((line) => {
    dom.textContent += `${line}\r\n`;
  });
  dom.className = 'cm-error-panel';
  return [showPanel.of(() => ({ dom })), errorTheme];
}
