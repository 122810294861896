import { NavigationState, QueryStringValues } from '@x/types';
import { useMemo } from 'react';
import { useUpdateQueryString } from './useUpdateQueryString';

type TUseUpdatePaginatedQueryString = {
  pushPaginatedQueryString: (
    newQsValues: QueryStringValues,
    ...args: [NavigationState] | []
  ) => void;
  replacePaginatedQueryString: (
    newQsValues: QueryStringValues,
    ...args: [NavigationState] | []
  ) => void;
};

const DEFAULT_PAGINATION_PARAMS = {
  PageNumber: '',
  pageNumber: '',
};

export function useUpdatePaginatedQueryString(): TUseUpdatePaginatedQueryString {
  const { pushQueryStringWithDefaults, replaceQueryStringWithDefaults } =
    useUpdateQueryString();

  return useMemo(
    () => ({
      pushPaginatedQueryString: pushQueryStringWithDefaults(
        DEFAULT_PAGINATION_PARAMS,
      ),
      replacePaginatedQueryString: replaceQueryStringWithDefaults(
        DEFAULT_PAGINATION_PARAMS,
      ),
    }),
    [pushQueryStringWithDefaults, replaceQueryStringWithDefaults],
  );
}
