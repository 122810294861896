import { Location } from 'history';
import * as R from 'ramda';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Blocker, useBlocker } from 'react-router-dom';

type Prompt = {
  active: boolean | ((nextLocation: Location) => boolean);
  passed: (nextLocation: Location) => Promise<boolean>;
};

type Prompts = Record<string, Prompt>;

type PromptProviderProps = {
  children: React.ReactNode;
};

const getFirstKey = R.o(R.last, R.keys);
const doIfTrue = R.curry(
  (recurseFunc: (...args: unknown[]) => unknown, shouldRecurse: boolean) =>
    R.when(R.equals(true), recurseFunc)(shouldRecurse),
);

const promptPasses = R.curry(
  async (nextLocation: Location, { active, passed }: Prompt) =>
    R.ifElse(
      R.when(R.is(Function), R.apply(R.__, [nextLocation])),
      R.thunkify(passed)(nextLocation),
      R.T,
    )(active),
);

async function allPromptsPass(
  nextLocation: Location,
  prompts: Prompts,
): Promise<boolean> {
  const key = getFirstKey(prompts);
  const recurse = R.o(
    R.thunkify(R.curry(allPromptsPass)(nextLocation)),
    R.dissoc(key),
  )(prompts);

  if (!key) {
    return true;
  }

  return await R.compose(
    R.andThen(doIfTrue(recurse)),
    promptPasses(nextLocation),
    R.prop(key),
  )(prompts);
}

export const PromptContext = createContext<{
  setPrompt: (key: string, prompt: Prompt) => void;
  removePrompt: (key: string) => void;
  blocker: Blocker | undefined;
}>({ setPrompt: R.F, removePrompt: R.F, blocker: undefined });

export function PromptProvider({
  children,
}: PromptProviderProps): React.JSX.Element {
  const [prompts, setPrompts] = useState<Prompts>({});
  const [prompted, setPrompted] = useState(false);
  const blocker = useBlocker(() => Object.keys(prompts).length > 0);

  const promptValue = useMemo(
    () => ({
      setPrompt: (key: string, prompt: Prompt) => {
        setPrompts((curPrompts) => ({ ...curPrompts, [key]: prompt }));
      },
      removePrompt: (key: string) => setPrompts(R.compose(R.dissoc(key))),
      blocker,
    }),
    [blocker],
  );

  useEffect(() => {
    if (blocker.state === 'blocked' && !prompted) {
      if (blocker.location.state?.skipPrompt) {
        return blocker.proceed();
      }

      setPrompted(true);

      allPromptsPass(blocker.location, prompts).then(
        (goToNextLocation: boolean) => {
          if (goToNextLocation) {
            blocker.proceed();
          } else {
            blocker.reset();
          }

          setPrompted(false);
        },
      );
    }
  }, [blocker, prompts, prompted]);

  return (
    <PromptContext.Provider value={promptValue}>
      {children}
    </PromptContext.Provider>
  );
}
