import { useUserToken } from 'features/user/hooks';
import React, { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const AuthenticatedRoute = ({
  children,
  ...props
}: {
  children?: React.JSX.Element;
}): React.JSX.Element | null => {
  const auth = useAuth();
  const token = useUserToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }

    if (auth.error || !token) {
      navigate('/login-error', { replace: true });
    }
  }, [auth, token, navigate]);

  return children ?? null;
};

export default AuthenticatedRoute;
