import * as R from 'ramda';
import React from 'react';

/*
 * render('div', { title: 'test' })
 *
 * is identical to
 *
 * <div title="test" />
 *
 * only currying 2 args because the 3rd one is for
 * children, which we rarely have when composing elements
 */

// render :: type -> props -> ReactNode
export const render = R.curryN(2, React.createElement);

export default render;
