export const noWhitespace = { whitespace: true, message: 'Cannot be empty' };
export const startsWithForwardSlash = {
  pattern: /^\//,
  message: 'Must begin with "/"',
};
export const lettersAndNumbersOnly = {
  pattern: /^[A-Za-z0-9]+$/gm,
  message: 'Only letters and numbers',
};
export const lettersNumbersAndSpaces = {
  pattern: /^[A-Za-z0-9 ]+$/gm,
  message: 'Only letters, numbers and spaces',
};
export const urlSafe = {
  pattern: /^[a-zA-Z0-9_-]*$/,
  message: 'Only letters, numbers, dashes and underscores',
};
