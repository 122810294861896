import { CloseOutlined } from '@ant-design/icons';
import { useMobile } from '@x/stores';
import { xTheme } from '@x/styles';
import { Drawer, Row, Space } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import _toNumber from 'lodash/toNumber';
import * as R from 'ramda';
import React, { ReactNode, useEffect, useState } from 'react';
import { VSpace } from '../VSpace';
import DrawerWidthAdjuster from './DrawerWidthAdjuster';

const { breakpoints } = xTheme;

export interface XDrawerProps extends DrawerProps {
  adjustable?: boolean;
  children?: React.ReactNode;
  'data-testid'?: string;
  footerActions?: ReactNode[];
}

export function XDrawer({
  adjustable,
  children,
  open,
  width = breakpoints[2],
  footerActions,
  ...props
}: XDrawerProps): React.JSX.Element {
  const isMobile = useMobile();
  const [drawerWidth, setDrawerWidth] = useState(width);

  useEffect(() => {
    if (!open) {
      setDrawerWidth(width);
    }
  }, [open, width]);

  return (
    <Drawer
      width={isMobile ? '100%' : drawerWidth}
      open={open}
      destroyOnClose
      closeIcon={<CloseOutlined data-testid={`close-${props.title}`} />}
      styles={{ body: { background: xTheme.colors.grayLightest } }}
      {...R.omit(['data-testid'], props)}
      footer={
        <VSpace>
          {props.footer}
          {footerActions && (
            <Row justify="end">
              <Space>{footerActions}</Space>
            </Row>
          )}
        </VSpace>
      }
    >
      {open && (
        <div
          style={{ width: '100%', height: '100%', overflow: 'auto' }}
          data-testid={props['data-testid']}
        >
          {children}
        </div>
      )}
      {adjustable && (
        <DrawerWidthAdjuster
          drawerWidth={_toNumber(drawerWidth)}
          setDrawerWidth={setDrawerWidth}
        />
      )}
    </Drawer>
  );
}

export default XDrawer;
