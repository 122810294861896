import { PaginatedResponse, SelectOption } from '@x/types';
import { flattenInfiniteQueryResults } from '@x/utils';
import { Select, SelectProps, theme } from 'antd';
import React, { ReactNode, useId } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UseInfiniteQueryResult } from 'react-query';
import { InfiniteScrollLoader } from '../InfiniteScrollLoader';
import { XSpin } from '../XSpin';

type InfiniteSelectProps<T> = Omit<
  SelectProps,
  | 'options'
  | 'listHeight'
  | 'virtual'
  | 'dropdownRender'
  | 'filterOption'
  | 'getPopupContainer'
> & {
  query: UseInfiniteQueryResult<PaginatedResponse<T>>;
  optionMap: (item: T) => SelectOption;
};

export function InfiniteSelect<T>({
  query,
  optionMap,
  ...props
}: InfiniteSelectProps<T>): React.JSX.Element {
  const { token } = theme.useToken();
  const data = flattenInfiniteQueryResults(query);
  const options = data.map(optionMap);
  const scrollTargetId = useId();

  return (
    <Select
      popupMatchSelectWidth={false}
      showSearch
      allowClear
      maxTagCount="responsive"
      loading={props.loading || query.isLoading}
      filterOption={false} // TODO: move below {...props} once search param is added for getAppRegistrations (https://jira.trimble.tools/browse/PLT-8915)
      {...props}
      getPopupContainer={(trigger) => trigger.parentElement}
      options={options}
      listHeight={NaN}
      virtual={false}
      dropdownRender={(menu: ReactNode) =>
        query.isLoading ? (
          <XSpin />
        ) : (
          <div
            id={scrollTargetId}
            style={{ maxHeight: 300, overflowY: 'auto' }}
          >
            <InfiniteScroll
              next={query.fetchNextPage}
              hasMore={Boolean(query.hasNextPage)}
              scrollableTarget={scrollTargetId}
              scrollThreshold={0.8}
              loader={
                <InfiniteScrollLoader
                  fetchNext={query.fetchNextPage}
                  loading={query.isFetching}
                />
              }
              dataLength={data.length}
            >
              {menu}
            </InfiniteScroll>
          </div>
        )
      }
    />
  );
}
