import { ajv } from '@x/utils';
import { ValidatorProps } from 'features/formRenderer/types';

export function jsonSchemaValidator({
  required,
  currentValue,
  schema,
  path,
}: ValidatorProps): string | boolean | undefined {
  const fullSchema = {
    type: 'object',
    required: required ? [path] : [],
    properties: {
      [path]: schema,
    },
  };
  const validate = ajv().compile(fullSchema);
  const valid = validate({ [path]: currentValue });

  if (!valid) {
    return validate.errors
      ?.map((str) => {
        return str.message;
      })
      .join(', ');
  }

  return false;
}
