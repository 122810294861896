import { ThemeProvider } from '@emotion/react';
import {
  act,
  render,
  renderHook,
  RenderHookOptions,
  RenderResult,
} from '@testing-library/react';
import { InitialEntry } from 'history';
import * as R from 'ramda';
import React, { PropsWithChildren, ReactNode } from 'react';
import * as oidc from 'react-oidc-context';
import { QueryClientProvider } from 'react-query';
import {
  createMemoryRouter,
  MemoryRouterProps,
  RouterProvider,
  To,
} from 'react-router-dom';
import { LayoutProvider } from '../LayoutContext';
import { PromptProvider } from '../PromptContext';
import { AntdProvider, UserProvider, XAuthProvider } from '../providers';
import { queryClient } from '../reactQuery';
import { SignalrContextProvider } from '../SignalrContext';
import { MOCK_THEME } from '../__mockData__';
export const createRouter: (
  children: ReactNode,
  initialEntries?: InitialEntry[],
) => ReturnType<typeof createMemoryRouter> = (children, initialEntries) =>
  createMemoryRouter(
    [
      {
        path: '/*',
        element: (
          <LayoutProvider>
            <UserProvider>
              <SignalrContextProvider>
                <PromptProvider>{children}</PromptProvider>
              </SignalrContextProvider>
            </UserProvider>
          </LayoutProvider>
        ),
      },
    ],
    {
      initialEntries,
    },
  );

export let testRouter: ReturnType<typeof createMemoryRouter> =
  createMemoryRouter([
    {
      id: 'root',
      path: '/*',
    },
  ]);

export function testNavigate(
  to: To,
  options?: { state?: any; replace?: boolean; relative?: 'path' | 'route' },
) {
  act(() => {
    testRouter.navigate(to, options);
  });
}

export function TestProviders({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={MOCK_THEME}>
        <AntdProvider>
          <XAuthProvider>{children}</XAuthProvider>
        </AntdProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export function renderWithProviders(
  component: React.JSX.Element,
  initialEntries?: MemoryRouterProps['initialEntries'],
): RenderResult {
  testRouter = createRouter(component, initialEntries);

  const view = render(
    <TestProviders>
      <RouterProvider router={testRouter} />
    </TestProviders>,
  );

  return {
    ...view,
    rerender: (newComponent: React.ReactNode) => {
      testRouter = createRouter(newComponent, initialEntries);

      return view.rerender(
        <TestProviders>
          <RouterProvider router={testRouter} />
        </TestProviders>,
      );
    },
  };
}

export function renderHookWithProviders<TProps, TResult>(
  callback: (props: TProps) => TResult,
  options?: RenderHookOptions<TProps>,
) {
  const passthrough = R.prop('children');
  const InnerWrapper = options
    ? R.propOr(passthrough, 'wrapper', options)
    : passthrough;

  return renderHook(callback, {
    wrapper: ({ children }: PropsWithChildren<TProps>) => {
      testRouter = createRouter(children);

      return (
        <TestProviders>
          <InnerWrapper>
            <RouterProvider router={testRouter} />
          </InnerWrapper>
        </TestProviders>
      );
    },
    ...R.omit(['wrapper'], options),
  });
}

export const mockUseAuth = (
  overrides?: Partial<oidc.AuthContextProps>,
): oidc.AuthContextProps => ({
  signinRedirect: jest.fn(),
  user: undefined,
  removeUser: jest.fn(),
  signinPopup: jest.fn(),
  revokeTokens: jest.fn(),
  signinSilent: jest.fn(),
  signoutPopup: jest.fn(),
  signoutSilent: jest.fn(),
  clearStaleState: jest.fn(),
  signoutRedirect: jest.fn(),
  stopSilentRenew: jest.fn(),
  startSilentRenew: jest.fn(),
  querySessionStatus: jest.fn(),
  events: { addSilentRenewError: jest.fn() } as any,
  error: undefined,
  isAuthenticated: false,
  isLoading: false,
  settings: {} as any,
  ...overrides,
});
