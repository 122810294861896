import React from 'react';

type RequiredItemProps = React.BaseHTMLAttributes<HTMLDivElement> & {
  required?: boolean;
};

export function RequiredItemMarker({
  required = true,
  children,
}: RequiredItemProps): React.JSX.Element {
  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {required && (
        <span
          style={{
            fontFamily: 'SimSun, sans-serif',
            color: '#a11015',
            fontSize: '14px',
          }}
        >
          *
        </span>
      )}
      {children}
    </div>
  );
}
