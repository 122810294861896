export enum QuickFilters {
  lastHour = 'Last Hour',
  last4Hours = 'Last 4 Hours',
  last12Hours = 'Last 12 Hours',
  today = 'Today',
  last24Hours = 'Last 24 Hours',
  last7Days = 'Last 7 Days',
  last30Days = 'Last 30 Days',
}

export type QuickFilterValue = keyof typeof QuickFilters;
