import { SelectOption } from '@x/types';
import * as R from 'ramda';

export function enumToOptions(enumeration: unknown): SelectOption {
  return R.cond([
    [R.allPass([R.prop('label'), R.prop('value')]), R.identity],
    [
      R.is(Object),
      R.always({ label: R.prop('name', enumeration), value: enumeration }),
    ],
    [R.T, R.always({ label: String(enumeration), value: enumeration })],
  ])(enumeration);
}
