import { App } from 'antd';
import { Location } from 'history';
import React, { useContext, useEffect } from 'react';
import { PromptContext } from '../PromptContext';

export function usePrompt(
  key: string,
  active: boolean | ((nextLocation: Location) => boolean),
  message: string | React.JSX.Element,
): void {
  const { modal } = App.useApp();
  const { setPrompt, removePrompt } = useContext(PromptContext);

  useEffect(() => {
    setPrompt(key, {
      active,
      passed: (_nextLocation) =>
        new Promise((res) => {
          modal.confirm({
            title: 'Unsaved Changes Pending',
            content: message,
            onOk: () => res(true),
            onCancel: () => res(false),
          });
        }),
    });

    return () => {
      removePrompt(key);
    };
  }, [key, active, message, setPrompt, removePrompt, modal]);
}
