import { RefObject, useEffect } from 'react';

export function useOnClickOutside(
  ref: RefObject<HTMLDivElement> | RefObject<HTMLDivElement>[],
  handler: (...args: any) => void,
): void {
  useEffect(() => {
    const listener = (event: any) => {
      if (Array.isArray(ref)) {
        const refCurrentContains = ref.some((aRef) => {
          return aRef.current?.contains(event.target);
        });

        if (refCurrentContains) {
          return;
        }
      } else if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
