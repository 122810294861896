import {
  useQueryString,
  useUpdatePaginatedQueryString,
  useUpdateQueryString,
} from '@x/hooks';
import { SelectOption } from '@x/types';
import { coerce, qsClearObject } from '@x/utils';
import { SelectProps, Skeleton } from 'antd';
import * as R from 'ramda';
import React from 'react';
import HorizontalSelectFilter from './HorizontalSelectFilter';

interface HorizontalSelectFilterQueryStringProps {
  options: SelectOption[];
  qsKey: string;
  limit?: number;
  label?: string;
  joinArrayParams?: boolean;
  defaultValue?: string;
  preservePageNumber?: boolean;
  selectProps?: Omit<SelectProps, 'options' | 'mode' | 'onChange' | 'value'>;
  qsKeysToClearOnChange?: string[];
  variant?: 'select' | 'tag';
  loading?: boolean;
}

export function HorizontalSelectFilterQueryString({
  options,
  qsKey,
  limit,
  label,
  joinArrayParams,
  defaultValue,
  preservePageNumber = false,
  selectProps,
  qsKeysToClearOnChange,
  variant,
  loading,
  ...props
}: HorizontalSelectFilterQueryStringProps): React.JSX.Element {
  const rawValue = useQueryString()[qsKey];
  const { replaceQueryString } = useUpdateQueryString();
  const { replacePaginatedQueryString } = useUpdatePaginatedQueryString();
  const firstOptionValue = R.o(R.prop('value'), R.head)(options);
  const selected = R.compose(
    R.map(coerce(R.type(firstOptionValue))),
    R.when(R.is(String), R.split(',')),
    R.ifElse(
      R.always(defaultValue),
      R.defaultTo([defaultValue]),
      R.defaultTo([]),
    ),
  )(rawValue);

  const updateUrl = R.pipe(
    R.reject(R.isNil),
    R.when(R.always(joinArrayParams), R.join(',')),
    R.assoc(qsKey, R.__, {}),
    R.mergeLeft(qsClearObject(qsKeysToClearOnChange)),
    R.ifElse(
      R.always(preservePageNumber),
      replaceQueryString,
      replacePaginatedQueryString,
    ),
  );

  function handleChange(value: string[]) {
    updateUrl(value);
  }

  if (loading) return <Skeleton.Input active style={{ width: '100%' }} />;

  return (
    <HorizontalSelectFilter
      options={options}
      label={label}
      limit={limit}
      value={selected}
      onChange={handleChange}
      selectProps={selectProps}
      variant={variant}
      {...props}
    />
  );
}

export default HorizontalSelectFilterQueryString;
