import * as R from 'ramda';
import { getChangedKeyPaths } from './getChangedKeyPaths';

export function makeDiffObject<T extends Record<string, unknown>>(
  oldObject: Record<string, unknown>,
  newObject: T,
): Partial<T> {
  const changedKeyPaths = getChangedKeyPaths(oldObject, newObject);

  return R.reduce(
    (result: Partial<T>, path: string[]) => {
      const value = R.path(path, newObject);

      return R.assocPath(path, value, result);
    },
    {},
    changedKeyPaths,
  );
}
