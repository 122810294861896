import { TrueFalseInput } from 'components/Form';
import * as R from 'ramda';
import React from 'react';
import { InputProps } from '../types';

export function BooleanRadioInput(props: InputProps): React.JSX.Element {
  const { InputWrapper, schema, handleChange, currentValue, name, readOnly } =
    props;
  const { inputProps = {} } = schema;
  const cleanProps = R.omit(
    ['hideLabel', 'layout', 'changeOnBlur', 'noWrapper'],
    inputProps,
  );

  function renderInput() {
    return (
      <TrueFalseInput
        name={name}
        value={currentValue}
        onChange={handleChange}
        readOnly={readOnly}
        {...cleanProps}
      />
    );
  }

  if (inputProps?.noWrapper) {
    return renderInput();
  }

  return <InputWrapper {...props}>{renderInput()}</InputWrapper>;
}
