import { portal, useAxiosMutation } from '@x/api';
import { ArgsProps } from 'antd/es/notification/interface';
import _noop from 'lodash/noop';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { hubConnection, Proxy } from 'signalr-no-jquery';

type HubProxy = Proxy & { events?: any };
type SignalrContextValue = {
  connect: () => void;
  hubProxy?: HubProxy;
};

export const SignalrContext = createContext<SignalrContextValue>({
  connect: _noop,
});

type SignalrContextProviderProps = {
  children: ReactNode;
  onError: (msg: string, options: Omit<ArgsProps, 'message'>) => void;
};

export const SignalrContextProvider: React.FunctionComponent<any> = ({
  children,
  onError,
}: SignalrContextProviderProps) => {
  const [hubProxy, setHubProxy] = useState<HubProxy>();
  const connectionValid = useRef(true);

  const { mutateAsync: authResponse } = useAxiosMutation(
    portal.getLegacyToken(),
  );

  const connect = useCallback(async () => {
    const tokenResponse = await authResponse({});
    const token = tokenResponse.access_token;

    if (!token) {
      setHubProxy(undefined);
      return;
    }

    const newConnection = hubConnection(process.env.REACT_APP_SIGNALR_URL, {
      qs: `access_token=${token}`,
      logging: false,
      useDefaultPath: true,
    });

    const newHubProxy = newConnection.createHubProxy(
      'connectorserviceremotecommandhub',
    );

    // for the connection to send the correct connection
    // details we need to subscribe to a message on the
    // hub we want to validate before we connect to it
    newHubProxy.on('initialize', _noop);

    setHubProxy(newHubProxy);
  }, [authResponse]);
  const handleFailedConnect = useCallback(() => {
    connectionValid.current = false;

    onError('Agent Connectivity Lost', {
      description:
        'You need to refresh your browser or re-authenticate to restore the connection.',
    });
  }, [onError]);
  const context = useMemo(() => ({ hubProxy, connect }), [hubProxy, connect]);

  useEffect(() => {
    if (hubProxy) {
      hubProxy.connection.start().fail(handleFailedConnect);
      hubProxy.connection.disconnected(() => {
        if (connectionValid.current) {
          hubProxy.connection.start().fail(handleFailedConnect);
        }
      });

      return () => {
        hubProxy.connection.stop();
      };
    }

    return _noop;
  }, [handleFailedConnect, hubProxy]);

  return (
    <SignalrContext.Provider value={context}>
      {children}
    </SignalrContext.Provider>
  );
};
