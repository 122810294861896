import { JsonSchema } from '@x/types';
import * as R from 'ramda';
import React from 'react';
import { InputProps } from '../types';
import { ensureJsonFormSchema } from '../utils';
import { FormError } from './FormError';
import { FormRenderer } from './FormRenderer';
import { OptionalInputContainer } from './OptionalInputContainer';

export function ContainedObjectInput(props: InputProps): React.JSX.Element {
  const { path, schema } = props;
  const { properties, required: requiredFields } = schema;
  const pathStart = path ? `${path}.` : '';

  if (!properties) {
    return <FormError {...props} error="Object did not have any properties" />;
  }

  return (
    <OptionalInputContainer inputProps={props}>
      {R.pipe(
        R.toPairs,
        R.map(([key, property]: [string, JsonSchema]) => (
          <FormRenderer
            {...props}
            key={key}
            path={`${pathStart}${key}`}
            schema={ensureJsonFormSchema(property)}
            requiredFields={requiredFields}
          />
        )),
      )(properties)}
    </OptionalInputContainer>
  );
}

export default ContainedObjectInput;
