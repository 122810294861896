import * as R from 'ramda';

type Concatable = unknown[][] | unknown[];
type ConcatableResult = unknown[] | string;

export function safeConcat<T>(item1: unknown, item2: unknown): T {
  return R.ifElse(
    R.is(Object),
    R.concat(R.__, item2),
    R.concat(R.__, String(item2)),
  )(item1);
}

export function concatAll<T = ConcatableResult>(...args: Concatable): T {
  return R.reduce(safeConcat, R.empty(args[0]), args);
}

export function concatN(n: number, ...args: Concatable): ConcatableResult {
  return R.curryN(n, concatAll)(...args);
}
