import { JsonSchema } from '@x/types';
import * as R from 'ramda';
import { JsonFormSchema } from '../types';

export function ensureJsonFormSchema(
  schema: JsonSchema | JsonFormSchema,
): JsonFormSchema {
  if (schema === true) {
    return {};
  }

  return R.evolve(
    {
      items: ensureJsonFormSchema,
      properties: R.when(R.identity, R.map(ensureJsonFormSchema)),
    },
    schema,
  );
}
