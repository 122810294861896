import { Empty, EmptyProps } from 'antd';
import React from 'react';

type NoDataDisplayProps = EmptyProps;

export function NoDataDisplay({
  ...props
}: NoDataDisplayProps): React.JSX.Element {
  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...props} />;
}
