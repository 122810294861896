/* eslint-disable no-template-curly-in-string */
import { CompletionConstructor } from '../types';

export const flowCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'info()',
      info: "Returns initiating flow's name, registrationId, and url.",
    },
    {
      label: 'step()',
      info: 'Returns a step by its id.',
      boost: 1,
    },

    {
      label: 'trigger',
      info: 'Accesses Trigger methods.',
      type: 'property',
    },
    {
      label: 'config',
      info: "Returns the flow's config.",
      type: 'property',
    },
    {
      label: 'loopItem()',
      info: 'Returns item data within a loop step.  If called outside of a loop, the Flow will fail.',
    },
    {
      label: 'mapItem()',
      info: 'Returns the item data within an array map.  If called outside of an array map, the Flow will fail.',
    },
    {
      label: 'todo()',
      info: 'Throws an error message with the provided message.',
    },
  ],
  defaultValues: { type: 'function', detail: 'Flow' },
};

export const flowInfoCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'name',
    },
    {
      label: 'registrationId',
    },
    {
      label: 'url',
      info: "Returns this Flow's url within the app. For sharing via email.",
    },
  ],
  defaultValues: { type: 'property', detail: 'Flow' },
};

export const flowStepCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'output',
      info: "Returns a step's output.",
      type: 'property',
      boost: 2,
    },
    {
      label: 'input',
      info: "Returns a step's input.",
      type: 'property',
      boost: 1,
    },
    {
      label: 'actionRequest',
      info: "Returns a step's action request.",
      type: 'property',
      boost: 0,
    },
    {
      label: 'actionResponse',
      info: "Returns a step's action response.",
      type: 'property',
      boost: 0,
    },
    {
      label: 'isActionSuccess()',
      info: 'Returns true if the Step is a successful action.',
      boost: 1,
    },
    {
      label: 'isActionFail()',
      info: 'Returns true if the Step is a failed action.',
      boost: 1,
    },
    {
      label: 'isQueueActionSuccess()',
      info: 'Returns true if the Step is a successful queue action.',
      boost: 1,
    },
  ],
  defaultValues: { type: 'function', detail: 'Step' },
};

export const flowTriggerCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'data',
      info: 'Returns the trigger data associated with the trigger event for this flow.',
      type: 'property',
    },
    {
      label: 'event',
      info: 'Returns the trigger event for this flow.',
      type: 'property',
    },
    {
      label: 'isCacheCreate()',
      info: 'Returns true if the trigger event type is a cache create/insert.',
    },
    {
      label: 'isCacheUpdate()',
      info: 'Returns true if the trigger event type is a cache update.',
    },
    {
      label: 'isCacheDelete()',
      info: 'Returns true if the trigger event type is a cache delete.',
    },
    {
      label: 'isActionSuccess()',
      info: 'Returns true if the trigger event type is a successful action.',
    },
    {
      label: 'isActionFail()',
      info: 'Returns true if the trigger event type is a failed action.',
    },
  ],
  defaultValues: { type: 'function', detail: 'Trigger' },
};

export const flowTriggerEventCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'workspaceId',
    },
    {
      label: 'type',
    },
    {
      label: 'eventInfo',
    },
  ],
  defaultValues: { type: 'property', detail: 'Trigger Event' },
};

export const workspaceCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'info()',
      info: "Returns this workspace's id, name, and key.",
    },
  ],
  defaultValues: { type: 'function', detail: 'Workspace' },
};

export const workspaceInfoCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'id',
      info: "Returns this workspace's id.",
    },
    {
      label: 'key',
      info: "Returns this workspace's url key.",
    },
    {
      label: 'name',
      info: "Returns this workspace's name.",
    },
  ],
  defaultValues: { type: 'property', detail: 'Workspace' },
};

export const appNetworkCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'fileUrl()',
      info: 'Generates an app network file url.',
    },
    {
      label: 'legacyFileUrl()',
      info: 'Generated an app network legacy file url.',
    },
  ],
  defaultValues: { type: 'function', detail: 'AppNetwork' },
};
