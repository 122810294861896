import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import * as R from 'ramda';
import { InputProps } from '../types';
import { getPathToAttrToCheck } from './getPathToAttrToCheck';

export function passesCondition(props: InputProps): boolean {
  const { schema, value, flattenedPath } = props;
  const { condition } = schema;

  if (_isNil(condition)) {
    return true;
  }

  if (typeof condition === 'boolean') {
    return condition;
  }

  if (typeof condition === 'function') {
    return condition(props);
  }

  const [attrToCheck, expectedValue] = condition;

  if (typeof attrToCheck === 'boolean') {
    return attrToCheck === expectedValue;
  }

  const pathToCheck = getPathToAttrToCheck(attrToCheck, flattenedPath);
  const actualValue = _get(value, pathToCheck);

  if (typeof actualValue === 'number' && typeof expectedValue === 'boolean') {
    return !isNaN(actualValue) === expectedValue;
  }

  if (typeof expectedValue === 'boolean') {
    return Boolean(actualValue) === expectedValue;
  }

  return R.equals(actualValue, expectedValue);
}
