import { ThemeProvider } from '@emotion/react';
import { initDayjs } from '@x/lib';
import {
  AntdProvider,
  LayoutProvider,
  PromptProvider,
  queryClient,
  SignalrContextProvider,
  UserProvider,
  XAuthProvider,
} from '@x/stores';
import { xTheme } from '@x/styles';
import '@x/styles/src/index.less';
import { ErrorBoundary } from 'ErrorBoundary';
import { ErrorFallback } from 'ErrorFallback';
import 'lib/exposeLibs';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { worker } from '__mockServer__/browser';
import { App } from './App';

initDayjs();

if (process.env.REACT_APP_USE_MOCK === 'true') {
  // Start the MSW service worker in development mode only
  worker.start();
}

const router = createBrowserRouter(
  [
    {
      path: '/*',
      element: (
        <LayoutProvider>
          <UserProvider>
            <SignalrContextProvider>
              <PromptProvider>
                <ErrorBoundary fallback={<ErrorFallback />}>
                  <App />
                </ErrorBoundary>
              </PromptProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </SignalrContextProvider>
          </UserProvider>
        </LayoutProvider>
      ),
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={xTheme}>
      <AntdProvider>
        <XAuthProvider>
          <RouterProvider router={router} />
        </XAuthProvider>
      </AntdProvider>
    </ThemeProvider>
  </QueryClientProvider>,
);
