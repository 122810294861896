import { xTheme } from '@x/styles';
import { SelectOption } from '@x/types';
import { Form, FormItemProps, Select, SelectProps, Tag } from 'antd';
import * as R from 'ramda';
import React, { useEffect } from 'react';

type HorizontalSelectFilterProps = {
  label?: string;
  limit?: number;
  onChange: (value: string[]) => void;
  options: SelectOption[];
  value?: string[];
  selectProps?: Omit<SelectProps, 'options' | 'mode' | 'onChange' | 'value'>;
  variant?: 'select' | 'tag';
  formItemProps?: FormItemProps;
};

const { CheckableTag } = Tag;

export function HorizontalSelectFilter({
  label,
  limit,
  onChange,
  options,
  value = [],
  selectProps,
  variant,
  formItemProps,
  ...props
}: HorizontalSelectFilterProps): React.JSX.Element {
  const handleSelect = R.pipe(
    R.unless(R.is(Object), R.of),
    R.when(R.always(limit), R.takeLast(limit)),
    onChange,
  );
  const formItemName = `${label}formItem`;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ [formItemName]: value });
  }, [value, form, formItemName]);

  if (variant === 'tag' || (options.length < 5 && variant !== 'select'))
    return (
      <Form form={form}>
        <Form.Item label={label} style={{ marginBottom: 0 }} {...formItemProps}>
          {options.map((option) => {
            const optionValue = option.value.toString();
            const checked = value?.includes(optionValue) ?? false;

            return (
              <CheckableTag
                style={{
                  border: `1px solid ${
                    checked ? xTheme.colors.brandPrimary : xTheme.colors.gray
                  }`,
                }}
                key={optionValue}
                checked={checked}
                onChange={() =>
                  handleSelect(R.symmetricDifference(value, [optionValue]))
                }
                {...props}
              >
                {option.label}
              </CheckableTag>
            );
          })}
        </Form.Item>
      </Form>
    );

  if (!limit || limit > 1)
    return (
      <Form form={form} initialValues={{ [formItemName]: value }}>
        <Form.Item
          label={label}
          name={formItemName}
          style={{ marginBottom: 0 }}
          {...formItemProps}
        >
          <Select
            data-testid="horizontal-select"
            mode="multiple"
            maxTagCount="responsive"
            onChange={handleSelect}
            options={options}
            popupMatchSelectWidth={false}
            {...selectProps}
            {...props}
          />
        </Form.Item>
      </Form>
    );

  return (
    <Form form={form} initialValues={{ [formItemName]: value[0] }}>
      <Form.Item
        label={label}
        name={formItemName}
        style={{ marginBottom: 0 }}
        {...formItemProps}
      >
        <Select
          data-testid="horizontal-select"
          onChange={handleSelect}
          options={options}
          allowClear
          popupMatchSelectWidth={false}
          {...selectProps}
          {...props}
        />
      </Form.Item>
    </Form>
  );
}

export default HorizontalSelectFilter;
