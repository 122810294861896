import _noop from 'lodash/noop';
import { createContext } from 'react';

export const UserContext = createContext<{
  logout: (shouldRedirect: boolean) => void;
}>({
  logout: _noop,
});

export default UserContext;
