import * as R from 'ramda';

const getAllKeys = R.pipe(R.reduce(R.mergeRight, {}), R.keys);
const isStandardObject = R.allPass([R.is(Object), R.complement(R.is(Array))]);

export function getChangedKeyPaths(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>,
  res: string[] = [],
): string[][] {
  const allKeys = getAllKeys([obj1, obj2]);

  function getChangedKeyPath(acc: string[], key: string) {
    const v1 = R.prop(key, obj1);
    const v2 = R.prop(key, obj2);

    if (R.equals(v1, v2)) {
      return acc;
    }

    if (isStandardObject(v2)) {
      return [...acc, ...getChangedKeyPaths(v1, v2, [...res, key])];
    }

    return [...acc, [...res, key]];
  }

  return R.reduce(getChangedKeyPath, [], allKeys);
}
