import {
  BreakPointNames,
  BreakPointValues,
  Layout,
  XTheme,
  XThemeColors,
} from '@x/types';
import * as R from 'ramda';

//Base colors; use sparingly
//For example, if you need something to be green, but not because it is successful,
//use the @green variable instead of the @success variable
//But first ask yourself why it should the same as the success color if it's not indicating success
const green = '#08a186';
const red = '#a11015';
const yellow = '#edbe18';
const orange = '#ed5418';
const black = '#2d2a26';
const brandPrimary = '#0065a1';
const brandGray = '#d9d8d6';
const colors: XThemeColors = {
  green,
  red,
  yellow,
  orange,
  brandPrimary,
  brandGray,
  brandPrimaryLight: '#379cd8', // brandPrimary lightened 55%
  brandSecondary: '#00abc8',
  brandDark: '#003a5d',
  brandBlack: black,
  black,
  success: green,
  successBg: '#f6fffd', // success lightened 65%
  warning: yellow,
  warningBg: '#fffee6', // warning lightened 49%
  warningDark: '#e0b10b', // warning darkened 5%
  error: red,
  errorBg: '#fffefe', // error lightened 65%
  info: brandPrimary,
  infoBg: '#e6f4ff', // info lightened 65%
  accent: orange,
  grayLight: '#e3e2e0', // gray lightened 4%
  grayLighter: '#f0efed', // gray lightened 9%
  grayLightest: '#fdfcfa', // gray lightened 14%
  gray: brandGray,
  grey: brandGray,
  grayDark: '#b3b2b0', // gray lightened 15%
  grayDarker: '#8d8c8a', // gray lightened 30%
  grayDarkest: '#666563', // gray lightened 45%
  blackLight: '#403f3d', // gray lightened 60%
};

//Matches Ant Design's breakpoints
export const breakpoints: Record<BreakPointNames, BreakPointValues> = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

//Ant Common Scales https://ant.design/docs/spec/layout
const commonScale = [
  4, 16, 24, 40, 64, 104, 168, 272, 440, 720, 1152, 1920,
] as const;

const layout: Layout = {
  headerHeight: commonScale[4],
  sideNavWidth: commonScale[7],
  mobileBreakpoint: breakpoints.lg,
  mobileBreakpointName: 'lg',
};
const darken0 = 'rgba(0, 0, 0, 0.09)';
const darken1 = 'rgba(0, 0, 0, 0.18)';

export const xTheme: XTheme = {
  colors,
  breakpoints: Object.values(breakpoints).map((value) => `${value}px`),
  fontSizes: [12, 14, 16, 20, 24, 30, 38, 46, 56, 68],
  space: [0, 4, 8, ...R.drop(1, commonScale)],
  shadows: [
    'none',
    `1px 1px 1px 0px ${darken0}`,
    `2px 2px 2px -1px ${darken1}`,
    `4px 4px 4px -2px ${darken1}`,
  ],
  layout: layout,
  fontFamily: 'Open Sans, sans-serif',
  fontWeightNormal: 400,
};

export default xTheme;
