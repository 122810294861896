import React from 'react';

type SkeletonVerticalProps = {
  height?: number | string;
  active?: boolean;
};

/**
 * This component renders a vertical loading skeleton.
 *
 * @param {string|number} height The height of the skeleton. Use a number for straight pixel values, or a string for any CSS value.
 * @param {boolean} active Animates the skeleton when true. Styles are based off the Ant Design skeleton animation.
 */
export function SkeletonVertical({
  height = '100px',
  active = true,
}: SkeletonVerticalProps): React.JSX.Element {
  const styles = {
    width: 'calc(100% / 24)',
    height,
    background:
      'linear-gradient(rgba(0, 0, 0, 0.06) 25%,rgba(0, 0, 0, 0.15) 37%,rgba(0, 0, 0, 0.06) 63%)',
    backgroundSize: '100% 400%',
    ...(active
      ? { animation: 'skeleton-loading-vertical 1.4s infinite ease' }
      : {}),
  };

  return <div data-testid="skeletonVertical" style={styles} />;
}
