import { CompletionConstructor } from '../types';

/** defaults unlisted types to 'method' */
export const dayJsCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'isValid()',
      info: `Returns a boolean indicating whether the Dayjs's date is valid.`,
    },
    {
      label: 'utc()',
      info: `Parses or displays a date-time in UTC.`,
    },
    {
      label: 'format()',
      info: `Gets the formatted date according to the string of tokens passed in.`,
    },
    {
      label: 'clone()',
      info: `Creates a clone of the current object.`,
    },
    {
      label: 'millisecond()',
      info: `Gets or sets the millisecond.`,
    },
    {
      label: 'second()',
      info: `Gets or sets the second.`,
    },
    {
      label: 'minute()',
      info: `Gets or sets the minute.`,
    },
    {
      label: 'hour()',
      info: `Gets or sets the second.`,
    },
    {
      label: 'date()',
      info: `Gets or sets the day of the month.`,
    },
    {
      label: 'day()',
      info: `Gets or sets the day of the week.`,
    },
    {
      label: 'month()',
      info: `Gets or sets the month.`,
    },
    {
      label: 'year()',
      info: `Gets or sets the year.`,
    },
    {
      label: 'get()',
      info: `String getter, returns the corresponding information getting from Day.js object.`,
    },
    {
      label: 'set()',
      info: `Generic setter, accepting unit as first argument, and value as second, returns a new instance with the applied changes.`,
    },
    {
      label: 'add()',
      info: `Returns a cloned Day.js object with a specified amount of time added.`,
    },
    {
      label: 'subtract()',
      info: `Returns a cloned Day.js object with a specified amount of time subtracted.`,
    },
    {
      label: 'isBefore()',
      info: `Indicates whether the Day.js object is before the supplied date-time.`,
    },
    {
      label: 'isAfter()',
      info: `Indicates whether the Day.js object is after the supplied date-time.`,
    },
  ],
  defaultValues: { type: 'method', detail: 'Day.js v1.11.7' },
};
