import {
  CodeOutlined,
  FontColorsOutlined,
  NumberOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { BooleanRadioInput } from '../components/BooleanRadioInput';
import { ExpressionInput } from '../components/ExpressionInput';
import { NullInput } from '../components/NullInput';
import { NumericInput } from '../components/NumericInput';
import { TagsInput } from '../components/TagsInput';
import { TextAreaInput } from '../components/TextAreaInput';

export const expressionOption = {
  name: 'Expression',
  type: 'expression',
  icon: <CodeOutlined />,
  input: ExpressionInput,
  props: {
    schema: {
      inputProps: { noWrapper: true, style: { borderTopLeftRadius: 0 } },
    },
  },
};

export const stringOption = {
  name: 'String',
  type: 'string',
  icon: <FontColorsOutlined />,
  input: TextAreaInput,
  props: {
    schema: {
      inputProps: {
        noWrapper: true,
        style: { borderTopLeftRadius: 0, height: 32 },
      },
    },
  },
};

export const numberOption = {
  name: 'Number/Integer',
  type: 'number',
  icon: <NumberOutlined />,
  input: NumericInput,
  props: {
    schema: {
      inputProps: {
        noWrapper: true,
        style: { borderTopLeftRadius: 0, width: '100%' },
      },
    },
  },
};

export const boolOption = {
  name: 'Boolean',
  type: 'boolean',
  icon: <small>T/F</small>,
  input: BooleanRadioInput,
  props: {
    schema: { inputProps: { noWrapper: true } },
  },
};

export const arrayOption = {
  name: 'List',
  type: 'array',
  icon: <UnorderedListOutlined />,
  input: TagsInput,
  props: {
    schema: {
      inputProps: { noWrapper: true, isDynamic: true },
    },
  },
};

export const nullOption = {
  name: 'Null',
  type: 'null',
  icon: <small>Null</small>,
  input: NullInput,
};

export const defaultDynamicOptions = [
  stringOption,
  numberOption,
  boolOption,
  arrayOption,
  nullOption,
];
