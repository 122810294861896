import * as R from 'ramda';

type ConditionFunc = (val: unknown, key: string) => boolean;

export const omitBy = R.curry((condition: ConditionFunc, val: unknown) =>
  R.pickBy(R.complement(condition), val),
);

export const omitByDeep = R.curry((condition: ConditionFunc, val: unknown) =>
  R.compose(
    R.mapObjIndexed(recurseOnArrayOrObject(condition)),
    omitBy(condition),
  )(val),
);

function recurseOnArrayOrObject(condition: ConditionFunc) {
  return (val: unknown) =>
    R.cond([
      [R.is(Array), R.map(recurseOnArrayOrObject(condition))],
      [R.is(Object), omitByDeep(condition)],
      [R.T, R.identity],
    ])(val);
}
