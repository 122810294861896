import { esLint } from '@codemirror/lang-javascript';
import { Diagnostic } from '@codemirror/lint';
import { EditorView } from '@codemirror/view';
import * as eslint from 'eslint-linter-browserify';
import * as R from 'ramda';
import { jsEslintConfig } from '../config';

function customJsLinter(target: EditorView) {
  return esLint(new eslint.Linter(), jsEslintConfig)(target);
}

export function noActionJsLint(target?: EditorView): Diagnostic[] {
  if (!target) return [];

  const rawLints = customJsLinter(target);
  const noActionLints = R.map(R.dissoc('actions'))(rawLints);

  return noActionLints;
}

function setJsLintErrors(
  setError: (val: string) => void,
  target?: EditorView,
): void {
  if (!target) return;

  const lintIssues = customJsLinter(target);
  const lintErrs = lintIssues.filter((lint) => lint.severity === 'error');

  if (lintErrs.length) {
    setError(lintErrs[0].message);
  }
}

export function formatSetErrsBlur(
  setErrorMessage: (val: string) => void,
  view?: EditorView,
): Promise<void> | undefined {
  if (!view) return;

  return import('@x/utils').then(({ formatJavaScript }) => {
    const formattedSuccessfully = formatJavaScript(setErrorMessage, view);

    if (formattedSuccessfully) {
      setJsLintErrors(setErrorMessage, view);
    }

    view?.contentDOM.blur();
  });
}
