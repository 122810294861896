import { SelectOption } from '@x/types';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';

interface SelectFilterProps extends SelectProps<string> {
  options: SelectOption[];
  value?: string;
  label?: string;
  limit?: number;
}

export function SelectFilter({
  options,
  label,
  limit,
  value,
  ...props
}: SelectFilterProps): React.JSX.Element {
  const isSingle = limit === 1;

  return (
    <Select
      data-testid="select-filter"
      allowClear
      options={options}
      placeholder={label}
      value={value}
      style={{ width: '100%' }}
      optionFilterProp="label"
      mode={!isSingle ? 'tags' : undefined}
      showSearch
      {...props}
    />
  );
}
