import { List, ListProps } from 'antd';
import React from 'react';
import { XSpin } from './XSpin';

export function XList<T>({ ...props }: ListProps<T>): React.JSX.Element {
  return (
    <List
      {...props}
      loading={{ indicator: <XSpin />, spinning: Boolean(props.loading) }}
    />
  );
}
