import { URLS } from '@x/api';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren } from 'react';
import { AuthProvider } from 'react-oidc-context';

const oidcConfig = {
  authority: `${process.env.REACT_APP_TRIMBLEID_AUTHORITY}`,
  client_id: `${process.env.REACT_APP_TRIMBLEID_CLIENT_ID}`,
  redirect_uri: `${document.location.origin}`,
  response_type: 'code',
  scope: `${process.env.REACT_APP_TRIMBLEID_SCOPES}`,
  post_logout_redirect_uri: `${document.location.origin}/logout`,
  metadata: {
    issuer: `${process.env.REACT_APP_TRIMBLEID_AUTHORITY}`,
    authorization_endpoint: `${process.env.REACT_APP_TRIMBLEID_AUTHORITY}/oauth/authorize`,
    token_endpoint: `${URLS.account}/v1/token`,
    jwks_uri: `${process.env.REACT_APP_TRIMBLEID_AUTHORITY}/oauth/.well-known/jwks`,
    userinfo_endpoint: `${process.env.REACT_APP_TRIMBLEID_AUTHORITY}/oauth/userinfo`,
    end_session_endpoint: `${process.env.REACT_APP_TRIMBLEID_AUTHORITY}/oauth/logout`,
    post_logout_redirect_uri: `${document.location.origin}/logout`,
  },
  userStore: new WebStorageStateStore({
    //Override to store the user data in local storage instead of session storage
    store: localStorage,
  }),
  extraQueryParams: {
    //This will maintain the users requested URL instead of redirecting back to the root of the domain.
    originalUrl: document.location.href,
  },
};

type XAuthProviderProps = PropsWithChildren;

export function XAuthProvider({ children }: XAuthProviderProps) {
  const onSigninCallback = (_user: User | void): void => {
    const params = new URLSearchParams(window.location.search);
    const originalUrl = params.get('originalUrl');

    window.location.href = originalUrl ? originalUrl : '/';
  };

  return (
    <AuthProvider onSigninCallback={onSigninCallback} {...oidcConfig}>
      {children}
    </AuthProvider>
  );
}
