import * as R from 'ramda';

/*
 * accepts a prop, a function, and a value
 * passes the value to the function, sets returned value
 * of function to prop of value
 * resulting value is returned
 *
 * example:
 * prop = 'foo'
 * getDerivedValue = R.prop('bar')
 * value = { bar: 'biz'}
 *
 * // {
 *   bar: 'biz',
 *   foo: 'biz',
 * }
 */

type ValueType = Record<string, unknown> | unknown[];

//  deriveProp :: [string | number] -> (a -> b) -> a -> a
export const deriveProp = R.curry(
  (
    prop: string | number,
    getDerivedValue: (value: ValueType) => unknown,
    value: ValueType,
  ) => R.assoc(prop, getDerivedValue(value), value),
);

export default deriveProp;
