import { xTheme } from '@x/styles';
import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import React from 'react';
import { ReactNode } from 'react-markdown/lib/react-markdown';

type FormattedTextProps = ParagraphProps & {
  children?: ReactNode;
};

export function Pre({
  children,
  ...props
}: FormattedTextProps): React.JSX.Element {
  return (
    <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
      <Typography.Paragraph
        style={{
          marginBottom: 0,
          fontFamily: xTheme.fontFamily,
        }}
        {...props}
      >
        {children}
      </Typography.Paragraph>
    </pre>
  );
}
