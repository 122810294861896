import * as R from 'ramda';

/** https://github.com/ramda/ramda/wiki/Cookbook#rename-keys-of-an-object
 *
 * Creates a new object with the own properties of the provided object, but the
 * keys renamed according to the keysMap object as `{oldKey: newKey}`.
 * When some key is not found in the keysMap, then it's passed as-is.
 *
 * Keep in mind that in the case of keys conflict is behaviour undefined and
 * the result may vary between various JS engines!
 *
 * @sig {a: b} -> {a: *} -> {b: *}
 */
export const renameKeys = R.curry(
  (keysMap: Record<string, unknown>, obj: Record<string, unknown>) =>
    R.reduce(
      (acc: Record<string, unknown>, key: string) =>
        R.assoc(keysMap[key] ?? key, obj[key], acc),
      {},
      R.keys(obj),
    ),
);

export default renameKeys;
