import { InfoCircleOutlined } from '@ant-design/icons';
import { flowBuildPerms } from '@x/api';
import { stringPathToArrayPath } from '@x/utils';
import {
  Checkbox,
  Collapse,
  CollapseProps,
  Space,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { useHasPermission } from 'features/user/hooks';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { InputProps } from '../types';
import { getDescription } from '../utils';

type OptionalInputContainerProps = {
  inputProps: InputProps;
  ghost?: boolean;
  children: React.ReactNode;
};

export function OptionalInputContainer({
  inputProps,
  ghost,
  children,
}: OptionalInputContainerProps): React.JSX.Element {
  const { token } = theme.useToken();
  const {
    currentValue,
    required,
    name,
    value,
    setValue,
    flattenedPath,
    readOnly,
    schema,
  } = inputProps;
  const { type, items } = schema;
  const normalizedTypes = type instanceof Array ? type : [type].filter(Boolean);
  const normalizedArrayTypes =
    items?.type instanceof Array ? items?.type : [items?.type];
  const isArray = normalizedTypes.indexOf('array') > -1;
  const arrayTypes =
    isArray && normalizedArrayTypes.map((t) => `${t || 'any'}[]`);
  const typesToShow = arrayTypes
    ? [...normalizedTypes.filter((t) => t !== 'array'), ...arrayTypes]
    : normalizedTypes;
  const isReadOnly = !useHasPermission(flowBuildPerms.update) || readOnly;
  const [showForm, setShowForm] = useState<boolean>(
    required || currentValue !== undefined,
  );

  useEffect(() => {
    if (currentValue) {
      setShowForm(true);
    }
  }, [currentValue]);

  function toggleInclude() {
    const newValue = R.assocPath(
      stringPathToArrayPath(flattenedPath),
      undefined,
      value,
    );

    setValue(newValue);
    setShowForm(!showForm);
  }

  const collapseItems: CollapseProps['items'] = [
    {
      key: name,
      showArrow: false,
      collapsible: 'icon',
      label: (
        <Space style={{ marginLeft: ghost ? -token.margin : undefined }}>
          <span>
            {required && (
              <Typography.Text
                type="danger"
                style={{ marginRight: token.marginXXS }}
              >
                *
              </Typography.Text>
            )}
            {name || '<root>'}
          </span>
          <Tooltip title={getDescription(inputProps)}>
            <InfoCircleOutlined
              style={{
                display: schema.description ? 'inline-block' : 'none',
              }}
            />
          </Tooltip>
          <Typography.Text type="secondary">
            <small>
              {typesToShow.length === 0 ? 'any' : typesToShow.join(' or ')}
            </small>
          </Typography.Text>
        </Space>
      ),
      extra: !required && !isReadOnly && (
        <Checkbox
          onChange={toggleInclude}
          checked={showForm}
          style={{ marginRight: ghost ? -token.margin : undefined }}
        >
          Include
        </Checkbox>
      ),
      children,
    },
  ];

  const activeKeys = showForm ? [name] : [];

  return (
    <Collapse
      ghost={ghost}
      items={collapseItems}
      activeKey={activeKeys}
      style={{
        marginTop: ghost ? 0 : token.marginXS,
        marginBottom: token.marginXXS,
      }}
    />
  );
}
