import { JsonSchema } from '@x/types';
import * as R from 'ramda';

/*
 * path = ['data']
 * schema = { properties: { name: { type: 'object', properties: { first: {type: 'string'}}}}}
 *
 * // [
        [['data', 'name'], { type: 'object', properties: { first: {type: 'string'}}}],
        [['data', 'name', 'first'], {type: 'string'}]
      ]
 */

type SchemaPair = [string[], JsonSchema];

// flattenSchemaProps :: JsonSchema -> string[] -> [string[], JsonSchema][]
export function flattenSchemaProps(
  schema: JsonSchema,
  path: string[] = [],
): SchemaPair[] {
  return R.pipe(
    R.propOr({}, 'properties'),
    R.toPairs,
    R.map(R.adjust(0, R.append(R.__, path))),
    R.reduce((acc: SchemaPair[], cur: SchemaPair) => {
      const [curPath, curSchema] = cur;

      if (!curSchema.properties) {
        return [...acc, cur];
      }

      const children = flattenSchemaProps(curSchema, curPath);

      return [...acc, cur, ...children];
    }, []),
    R.sortBy(R.pipe(R.head, R.join(''), R.toLower)),
  )(schema);
}

/*
 * path = 'data'
 * schema = { properties: { name: { type: 'object', properties: { first: {type: 'string'}}}}}
 *
 * // [
        ['data.name', { type: 'object', properties: { first: {type: 'string'}}}],
        ['data.name.first', {type: 'string'}]
      ]
 */

// flattenSchemaProps :: string -> JsonSchema -> [string, JsonSchema][]
export const flattenSchemaProps__legacy = R.curry(
  (path: string, schema: JsonSchema) =>
    R.compose(
      R.sortBy(R.o(R.toLower, R.head)),
      R.splitEvery(2), // pairs were flattened, so need to remake them
      R.flatten, // due to recursion we get a fair bit of nesting
      R.converge(R.concat, [
        R.o(R.map(R.adjust(0, R.concat(path))), R.toPairs),
        R.compose(
          R.map(
            R.converge(flattenSchemaProps__legacy, [
              R.compose(R.concat(R.__, '.'), R.concat(path), R.head),
              R.last,
            ]),
          ),
          R.toPairs,
        ),
      ]),
      R.propOr({}, 'properties'),
    )(schema),
);
