import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import css from '@styled-system/css';
import { VSpace } from '@x/components';
import { Button, Card, Divider, Flex, theme } from 'antd';
import Box from 'components/Box';
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _set from 'lodash/set';
import * as R from 'ramda';
import React, { useState } from 'react';
import { InputProps } from '../types';
import { FormError } from './FormError';
import { FormRenderer } from './FormRenderer';
import InputWrapper from './InputWrapper';

export function ArrayInput(props: InputProps): React.JSX.Element {
  // the formVersion is part of the element key because we
  // cannot assume anything within the item to be unique
  // once it's incremented (on delete) it will forcibly
  // re-render all items
  const { token } = theme.useToken();
  const [formVersion, setFormVersion] = useState<number>(0);
  const { currentValue, path, schema, setValue, value, label, name } = props;
  const { inputProps = {}, title } = schema;
  const {
    hideLabel = !title,
    hideAdd,
    maxItems,
    hideDelete,
    onDelete,
    disabled,
    isKeyValue,
    ...dividerProps
  } = inputProps;
  const flattenedPath = path.replace(/\$flatten\./g, '');
  const itemSchema = schema.items;
  const itemType = itemSchema?.type;
  const isObject = itemType === 'object';
  const items: any[] = currentValue ?? [];

  if (!path || !itemSchema) {
    return <FormError {...props} error="Path or Item Schema missing" />;
  }

  function handleAddItem() {
    const newItems = [...items, itemSchema?.default || undefined];
    const updatedValue = _set(_cloneDeep(value ?? []), flattenedPath, newItems);

    setValue(updatedValue);
  }

  function handleDeleteItem(index: number, itemToDelete: any) {
    return async (e: any) => {
      e.stopPropagation();
      const promptResponse = onDelete
        ? await onDelete(itemToDelete, index)
        : true;

      if (!promptResponse) {
        return;
      }

      const newItems = _filter(items, (_item, i) => i !== index);
      const updatedValue = _set(_cloneDeep(value), flattenedPath, newItems);

      setValue(updatedValue);
      setFormVersion(formVersion + 1);
    };
  }

  const renderDeleteButton = (
    i: number,
    item: any,
    size: 'small' | 'middle' | 'large',
  ) => (
    <Button
      type="text"
      size={size}
      danger
      onClick={handleDeleteItem(i, item)}
      data-testid={`delete-item-${name}`}
      icon={<DeleteOutlined />}
      style={{ alignSelf: 'center' }}
    />
  );

  const renderAddButton = () => (
    <div style={{ textAlign: 'center' }}>
      <Button
        icon={<PlusCircleOutlined />}
        data-testid={`add-${name}`}
        onClick={handleAddItem}
        hidden={hideAdd || (maxItems && items.length >= maxItems) || disabled}
      >
        Add Item
      </Button>
    </div>
  );

  if (isKeyValue) {
    const [keySchema, valueSchema] = Object.entries(
      itemSchema.properties || {},
    );

    return (
      <>
        {!hideLabel && <Divider {...dividerProps}>{label}</Divider>}

        <VSpace size="middle">
          {items.map((item: any, i: number) => {
            const childPath = `${path}[${i}]`;

            return (
              <Box
                key={`${formVersion}-${i}`}
                display="grid"
                gridTemplateColumns="1fr 1fr max-content"
                gap={2}
                alignItems="end"
              >
                <FormRenderer
                  {...props}
                  schema={keySchema[1]}
                  path={`${childPath}.${keySchema[0]}`}
                />
                <FormRenderer
                  {...props}
                  schema={valueSchema[1]}
                  path={`${childPath}.${valueSchema[0]}`}
                />
                {renderDeleteButton(i, item, 'small')}
              </Box>
            );
          })}
          {renderAddButton()}
        </VSpace>
      </>
    );
  }

  if (isObject) {
    return (
      <>
        {!hideLabel && <Divider {...dividerProps}>{label}</Divider>}
        <VSpace size="middle">
          {items.map((item: any, i: number) => (
            <Card
              size="small"
              type="inner"
              key={`${formVersion}-${i}`}
              style={{ background: 'transparent' }}
              extra={
                !hideDelete && !disabled && renderDeleteButton(i, item, 'small')
              }
            >
              <FormRenderer
                {...props}
                path={`${path}[${i}]`}
                schema={itemSchema}
              />
            </Card>
          ))}
          {renderAddButton()}
        </VSpace>
      </>
    );
  }

  return (
    <InputWrapper {...props} htmlFor="_passThru">
      <VSpace size="middle">
        {items.map((item: any, i: number) => (
          <Flex key={`${formVersion}-${i}`} gap={token.marginSM} align="center">
            <Box flexGrow={1} css={css({ '& .ant-form-item': { margin: 0 } })}>
              <FormRenderer
                {...props}
                path={`${path}[${i}]`}
                schema={R.mergeDeepRight(
                  { inputProps: { hideLabel: true } },
                  itemSchema,
                )}
              />
            </Box>
            {renderDeleteButton(i, item, 'middle')}
          </Flex>
        ))}
        {renderAddButton()}
      </VSpace>
    </InputWrapper>
  );
}

export default ArrayInput;
