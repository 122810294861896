type LogTotal = {
  $id: string;
  LogLevel: string;
  Count: number;
};

export type ServiceRunnerJobLog = {
  Description?: string;
  EndDateUtc: string | null;
  JobId: string;
  JobStatus: string;
  LogTotals: LogTotal[];
  OrganizationName: string;
  ServiceConfigurationId: number;
  ServiceName: string;
  StartDateUtc: string;
};

export type RunnerJobLog = {
  ArtifactId: string;
  Description?: string;
  EndDateUtc: string | null;
  JobId: string;
  JobType: JobType;
  JobStatus: string;
  LogTotals: LogTotal[];
  ResourceId: number;
  StartDateUtc: string;
};

export type ScheduledJobLogForJob = {
  $id: string;
  ServiceRunnerJobLogs: ServiceRunnerJobLog[];
  RunnerJobLogs: RunnerJobLog[];
};

export enum JobType {
  Service = 'Service',
  Flow = 'Flow',
}

export type ScheduledJobLog = {
  $id: string;
  Description?: string;
  EndDateUtc?: string;
  JobStatus: string;
  Organizations: {
    DisplayName: string | null;
    OrganizationName: string;
    OrganizationId: number;
  }[];
  ServiceRunnerJobLogs: ServiceRunnerJobLog[];
  RunnerJobLogs: RunnerJobLog[];
  StartDateUtc: string;
};

export enum JobStatus {
  Queued = 'queued',
  Starting = 'starting',
  Started = 'started',
  Failed = 'failed',
  Finished = 'finished',
  Skipped = 'skipped',
  Cancelled = 'cancelled',
}

export const COMPLETED_JOB_STATUSES = [
  JobStatus.Failed,
  JobStatus.Finished,
  JobStatus.Skipped,
  JobStatus.Cancelled,
] as const;

export type CompletedJobStatus = typeof COMPLETED_JOB_STATUSES[number];

export type ScheduledJobLogSummary = {
  $id: string;
  JobId: string;
  JobStatus: JobStatus;
  StartDateUtc?: string;
  EndDateUtc?: string;
  Description: string;
  OrganizationId: number;
  DisplayName: string;
  ErrorLogTotal: number;
  WarnLogTotal: number;
  InfoLogTotal: number;
  DebugLogTotal: number;
  CriticalLogTotal: number;
};

export type ScheduledJobsHistory = {
  LogTotal: number;
  PageTotal: number;
  Logs: ScheduledJobLog[];
  HasLogTotals: boolean;
};

export type ScheduledJobsHistorySummary = {
  LogTotal: number;
  PageTotal: number;
  Logs: ScheduledJobLogSummary[];
};

export type NonScheduledJobLog = {
  StartDateUtc: string;
  EndDateUtc: string | null;
  JobId: string;
  Organization_Id: number;
  ServiceName: string;
  Description: string;
  ServiceConfigurationId: number;
  JobStatus: string;
  DisplayName: string | null;
};

export type NonScheduledJobsHistory = {
  LogTotal: number;
  PageTotal: number;
  Logs: NonScheduledJobLog[];
};

export type JobLogDetails = {
  Id: number;
  Date: string;
  Thread: string;
  Level: string;
  Logger: string;
  Message: string;
  Exception: string;
  MetadataId: number; // looks to always come back as 0 or null
  JobId: string;
  OrganizationId: number; // looks to always come back as 0
  CreatedUtc: string;
  IpAddress: string | null;
  FromComputerName: string;
};
