import { NODE_SEPARATOR } from '@x/config';
import { DataNode } from 'antd/es/tree';
import * as R from 'ramda';
import { XTreeDataNode, XTreeDropInfo } from '../types';
import { getTreePath } from './getTreePath';
import { getNodeName, nodeKeyToKeyArray, uniqueKey } from './helpers';

export function updateKeys(
  treeData: DataNode[],
  info: XTreeDropInfo,
): DataNode[] {
  const { node, dragNode, dropToGap } = info;
  const dropLocation = dropToGap
    ? nodeKeyToKeyArray(node.key).slice(0, -1)
    : nodeKeyToKeyArray(node.key);
  const droppingAtRootLevel = dropLocation.length === 0 && dropToGap;
  const nodeName = getNodeName(dragNode);

  if (droppingAtRootLevel) {
    const index = R.findIndex(R.propEq('key', info.dragNode.key), treeData);
    const siblings = R.remove(index, 1, treeData);
    const siblingNodeKeys = R.pluck('key', siblings);
    const newKey = uniqueKey(nodeName, siblingNodeKeys);
    const newTreeData = R.assocPath([index, 'key'], newKey, treeData);

    return R.modifyPath([index], updateChildKeys, newTreeData);
  } else {
    const treePathToDropLocation = [
      ...getTreePath(dropLocation, treeData),
      'children',
    ];

    const targetData = R.path(treePathToDropLocation, treeData);

    const targetIndex = R.findIndex(
      R.propEq('key', info.dragNode.key),
      targetData,
    );
    const siblings: XTreeDataNode[] = R.remove(targetIndex, 1, targetData);
    const siblingNodeKeys = R.map(getNodeName, siblings);
    const newKey = R.join(NODE_SEPARATOR, [
      ...dropLocation,
      uniqueKey(nodeName, siblingNodeKeys),
    ]);
    const newTreeData = R.assocPath(
      [...treePathToDropLocation, targetIndex, 'key'],
      newKey,
      treeData,
    );

    return R.modifyPath([targetIndex], updateChildKeys, newTreeData);
  }
}

export function updateChildKeys(parentNode: DataNode): DataNode {
  if (!parentNode.children) return parentNode;

  const parentKeyArray = R.split(NODE_SEPARATOR, parentNode.key);

  const updatedChildren = R.map((child: DataNode) => {
    const childKeyName = R.takeLast(1, R.split(NODE_SEPARATOR, child.key))[0];
    const newKey = R.join(NODE_SEPARATOR, [...parentKeyArray, childKeyName]);
    const updatedChild = R.assoc('key', newKey, child);

    if (!child.children) return updatedChild;

    return updateChildKeys(updatedChild);
  }, parentNode.children);

  return R.assoc('children', updatedChildren, parentNode);
}
