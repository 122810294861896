import { DownOutlined } from '@ant-design/icons';
import { DEFAULT_FORMAT, quickFilters } from '@x/config';
import { useQueryString, useUpdateQueryString } from '@x/hooks';
import { QuickFilterValue } from '@x/types';
import { qsClearObject } from '@x/utils';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Popover,
  Row,
  Space,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import _toNumber from 'lodash/toNumber';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { VSpace } from '../VSpace';
import HorizontalSelectFilterQueryString from './HorizontalSelectFilterQueryString';

interface Props {
  qsKey: string;
  label: string;
  qsKeysToClearOnChange?: string[];
  defaultValue?: QuickFilterValue;
}

const parseDate = R.ifElse(
  R.converge(R.or, [R.equals('any'), R.isNil]),
  R.always(null),
  R.o(dayjs.unix, _toNumber),
);

export function DateRangeFilter({
  qsKey,
  label,
  qsKeysToClearOnChange,
  defaultValue,
}: Props): React.JSX.Element {
  const { pushQueryString } = useUpdateQueryString();
  const [open, setOpen] = useState<any>();
  const { [qsKey]: paramValue = defaultValue } = useQueryString();
  const [start, end] = paramValue?.split('-') ?? [];
  const startDate = parseDate(start);
  const endDate = parseDate(end);
  const selectedQuickFilter = R.find(
    R.propEq('value', paramValue),
    quickFilters,
  );
  const displayValue = useMemo(() => {
    if (!paramValue) {
      return '(All)';
    }

    if (selectedQuickFilter) {
      return `(${selectedQuickFilter.display})`;
    }

    return '(Custom Range)';
  }, [paramValue, selectedQuickFilter]);

  const updateUrl = (value: string) => {
    const qsObject = {
      [qsKey]: value,
      ...qsClearObject(qsKeysToClearOnChange),
    };

    pushQueryString(qsObject);
  };

  function handleDatePickerChange(type: 'start' | 'end') {
    return R.compose(
      updateUrl,
      R.ifElse(R.equals('any-any'), R.always(''), R.identity),
      R.ifElse(
        R.always(R.equals(type, 'start')),
        R.concat(R.__, `-${R.defaultTo('any', end)}`),
        R.concat(`${selectedQuickFilter ? 'any' : R.defaultTo('any', start)}-`),
      ),
      R.ifElse(R.isNil, R.always('any'), R.invoker(1, 'format')('X')),
    );
  }

  const overlay = (
    <VSpace size="middle">
      <HorizontalSelectFilterQueryString
        qsKey={qsKey}
        options={quickFilters}
        limit={1}
        label="Time Range"
        qsKeysToClearOnChange={qsKeysToClearOnChange ?? ['test']}
        defaultValue={defaultValue}
        selectProps={{
          placeholder: 'Select Time Range',
        }}
      />
      <Divider style={{ margin: 0 }}>or</Divider>
      <Row gutter={12} align="middle">
        <Col>
          <DatePicker
            placeholder="Start Date"
            showTime={{
              format: 'h:mm a',
              use12Hours: true,
            }}
            format={DEFAULT_FORMAT}
            value={selectedQuickFilter ? null : startDate}
            onChange={handleDatePickerChange('start')}
          />
        </Col>
        <Col>-</Col>
        <Col>
          <DatePicker
            placeholder="End Date"
            showTime={{ format: 'h:mm a', use12Hours: true }}
            format={DEFAULT_FORMAT}
            value={selectedQuickFilter ? null : endDate}
            onChange={handleDatePickerChange('end')}
          />
        </Col>
      </Row>
      <Flex justify="space-between" align="center">
        <Typography.Text type="secondary">
          Local Time (UTC{dayjs().format('Z')})
        </Typography.Text>
        <Space>
          <Button type="link" onClick={() => updateUrl('')}>
            Clear
          </Button>
          <Button
            type="link"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </Space>
      </Flex>
    </VSpace>
  );

  return (
    <Popover
      content={overlay}
      onOpenChange={setOpen}
      open={open}
      trigger={['click']}
      placement="bottom"
    >
      <Button data-gainsight={qsKey}>
        <Space>
          <Typography.Text type={!paramValue ? 'secondary' : undefined}>
            <span>
              {label} {displayValue}
            </span>
          </Typography.Text>
          <Typography.Text type={'secondary'}>
            {/* sizing matches standard Select down carrot */}
            <DownOutlined style={{ fontSize: '0.8em' }} />
          </Typography.Text>
        </Space>
      </Button>
    </Popover>
  );
}

export default DateRangeFilter;
