import { Radio, RadioGroupProps } from 'antd';
import React from 'react';

type BooleanRadioInputProps = RadioGroupProps & {
  name?: string;
  value?: boolean;
  onChange?: (val: boolean) => void;
  readOnly?: boolean;
};

export function TrueFalseInput({
  name,
  value,
  onChange,
  readOnly,
  ...props
}: BooleanRadioInputProps): React.JSX.Element {
  return (
    <Radio.Group
      data-testid={`booleanRadios-${name}`}
      value={value}
      buttonStyle="solid"
      onChange={onChange ? ({ target }) => onChange(target.value) : undefined}
      disabled={readOnly}
      {...props}
    >
      <Radio.Button value>True</Radio.Button>
      <Radio.Button value={false}>False</Radio.Button>
    </Radio.Group>
  );
}
