import { stringPathToArrayPath } from '@x/utils';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _last from 'lodash/last';
import _omit from 'lodash/omit';
import _startCase from 'lodash/startCase';
import * as R from 'ramda';
import React, { useCallback, useEffect, useId, useRef } from 'react';
import { FormProps, UpdateFormOptions } from '../types';
import { getRenderer, passesCondition } from '../utils';

export function FormRenderer(props: FormProps): React.JSX.Element {
  const id = useId();
  const rendererInfo = getRenderer(props);
  const allProps = R.mergeDeepRight(rendererInfo.props ?? {}, props);
  const {
    ErrorComponent,
    schema,
    path,
    errors,
    value,
    updateValue,
    defaultValidator,
    requiredFields = [],
    setErrors,
    originalSchema,
    originalValue,
    forceValidation,
    readOnly,
    showHiddenValues,
    engineVersion = 1,
    evaluator = 1,
    ignoreDefaults,
  } = allProps;
  const name = _last(props.path.split('.')) || '';
  const {
    default: defaultValue,
    title,
    renderer,
    getter,
    setter,
    condition,
  } = schema;
  const flattenedPath = path.replace(/\$flatten\./g, '');
  const label = title || _startCase(name);
  const required = // if required isnt an array, then it isnt valid (but handle it)
    requiredFields instanceof Array ? requiredFields.indexOf(name) > -1 : false;
  const validator = schema.validator || defaultValidator;
  const originalCurrentValue = _get(originalValue, flattenedPath, defaultValue);
  const arrayPath = stringPathToArrayPath(flattenedPath);
  const currentPathValue = flattenedPath ? R.path(arrayPath, value) : value;
  const currentValue = getter ? getter(props) : currentPathValue;
  const handleChange = useCallback(
    (newVal: string, options?: UpdateFormOptions) => {
      if (setter) {
        setter(newVal, props);
      } else {
        updateValue({ path: flattenedPath, value: newVal }, options);
      }
    },
    [props, setter, flattenedPath, updateValue],
  );

  const touchedRef = useRef(false);

  if (!_isEqual(currentValue, originalCurrentValue)) {
    touchedRef.current = true;
  }

  const touched = touchedRef.current;
  const propsToPass = R.when(
    R.always(readOnly),
    R.assocPath(['schema', 'inputProps', 'disabled'], true),
    {
      ...allProps,
      readOnly: schema.readOnly ?? readOnly,
      showHiddenValues,
      engineVersion,
      evaluator,
      touched,
      validator,
      required,
      currentValue,
      handleChange,
      flattenedPath,
      name,
      label,
      id,
      ignoreDefaults,
    },
  );
  const show = passesCondition(propsToPass);

  useEffect(() => {
    if (
      currentValue === undefined &&
      defaultValue !== undefined &&
      !ignoreDefaults
    ) {
      handleChange(defaultValue, { dontTouch: true });
    }
  }, [handleChange, currentValue, defaultValue, ignoreDefaults]);

  useEffect(() => {
    const debounceDuration = forceValidation ? 0 : 100;
    const debounceValidation = setTimeout(() => {
      const fieldErrors = validator({
        required,
        value,
        currentValue,
        path,
        schema,
      });
      const errorChanged = fieldErrors
        ? !_isEqual(errors[flattenedPath], fieldErrors)
        : Boolean(errors[flattenedPath]);
      const handleError = forceValidation || touched;

      if (!handleError || !errorChanged) {
        return;
      }

      const newErrors = fieldErrors
        ? {
            ...errors,
            [flattenedPath]: fieldErrors,
          }
        : _omit(errors, [flattenedPath]);

      setErrors(newErrors);
    }, debounceDuration);

    return () => {
      clearTimeout(debounceValidation);
    };
  }, [
    forceValidation,
    errors,
    touched,
    setErrors,
    flattenedPath,
    validator,
    required,
    value,
    currentValue,
    path,
    schema,
  ]);

  useEffect(
    () => () => {
      // on unmount, reset errors
      setErrors({});
    },
    [setErrors],
  );

  if (!show) {
    return <></>;
  }

  if (renderer === 'recursion') {
    const schemaToRecurse = schema.path
      ? _get(originalSchema, schema.path)
      : originalSchema;

    if (!schemaToRecurse) {
      return <>Error Recursing, no schema found</>;
    }

    if (_isNil(condition)) {
      return (
        <ErrorComponent
          {...propsToPass}
          currentValue="Must have a condition when recursing to guard against loops"
        />
      );
    }

    return (
      <React.Suspense fallback="">
        <FormRenderer
          {...props}
          schema={schemaToRecurse}
          originalSchema={originalSchema}
        />
      </React.Suspense>
    );
  }

  try {
    const RendererComponent = rendererInfo.renderer;

    return <RendererComponent {...propsToPass} />;
  } catch (err) {
    console.error(err);

    return <ErrorComponent {...propsToPass} currentValue={err} />;
  }
}

FormRenderer.defaultProps = {
  path: '',
};
