import { WarningOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import React from 'react';

type ErrorIconProps = {
  tooltip?: string;
};

export function ErrorIcon({ tooltip }: ErrorIconProps): React.JSX.Element {
  return (
    <Tooltip title={tooltip}>
      <Typography.Text type="danger">
        <WarningOutlined />
      </Typography.Text>
    </Tooltip>
  );
}
