import { FlowEvaluators } from '@x/types';
import * as R from 'ramda';

export const isJsEvaluator = R.anyPass([
  R.equals(2),
  R.equals(FlowEvaluators.jsV1),
]);

export const isCSharpEvaluator = R.anyPass([
  R.equals(null),
  R.equals(1),
  R.equals(FlowEvaluators.cSharpV1),
]);

export const evaluatorLabel = (
  evaluator: number | FlowEvaluators | null | undefined,
  abbreviated: boolean = false,
): 'JavaScript' | 'JS' | 'C#' => {
  if (isJsEvaluator(evaluator)) return abbreviated ? 'JS' : 'JavaScript';

  return 'C#';
};
