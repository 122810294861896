import { EditorSelection } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import babelParser from 'prettier/parser-babel';
import prettier from 'prettier/standalone';
import * as R from 'ramda';

// from https://prettier.io/docs/en/options.html
type PrettierOptions = {
  parser: string;
  plugins: Record<string, any>[];
  printWidth?: number;
  tabWidth?: number;
  semi?: boolean;
  singleQuote?: boolean;
  quoteProps?: 'as-needed' | 'consistent' | 'preserve';
  jsxSingleQuote?: boolean;
  trailingComma?: 'all' | 'none' | 'es5';
  bracketSpacing?: boolean;
  bracketSameLine?: boolean;
  arrowParens?: 'always' | 'avoid';
  proseWrap?: 'always' | 'never' | 'preserve';
  htmlWhitespaceSensitivity?: 'css' | 'strict' | 'ignore';
  endOfLine?: 'lf' | 'auto' | 'crlf' | 'cr';
  embeddedLanguageFormatting?: 'auto' | 'off';
  singleAttributePerLine?: boolean;
};

const DEFAULT_PRETTIER_OPTIONS: PrettierOptions = {
  parser: 'babel',
  plugins: [babelParser],
  semi: true,
  trailingComma: 'all',
  singleQuote: true,
  printWidth: 80,
  tabWidth: 2,
  bracketSameLine: false,
  arrowParens: 'always',
  endOfLine: 'lf',
};

export function formatJavaScript(
  setError: (val: string) => void,
  target?: EditorView,
): boolean {
  if (!target) return true;

  const { state, dispatch } = target;

  const currentValue = state.doc.toString();
  const curPos = state.selection.ranges[0].to;

  try {
    const { formatted, cursorOffset } = prettier.formatWithCursor(
      currentValue,
      {
        ...DEFAULT_PRETTIER_OPTIONS,
        cursorOffset: curPos,
      },
    );
    const withoutBlankLastLine = formatted.replace(/[\r\n]+$/, '');

    if (currentValue === withoutBlankLastLine) return true;

    dispatch({
      changes: {
        from: 0,
        to: currentValue.length,
        insert: withoutBlankLastLine,
      },
      selection: EditorSelection.cursor(cursorOffset),
    });
    return true;
  } catch (err) {
    setError(R.prop('message', err));
    return false;
  }
}
