import {
  useProtectedDebounce,
  useQueryString,
  useUpdatePaginatedQueryString,
} from '@x/hooks';
import { Form, Input } from 'antd';
import { SearchProps } from 'antd/lib/input';
import React from 'react';

type SearchFilterQueryStringProps = SearchProps & {
  qsKey: string;
  label?: string;
};

export function SearchFilterQueryString({
  qsKey,
  label,
  placeholder = 'Search...',
  ...props
}: SearchFilterQueryStringProps): React.JSX.Element {
  const { [qsKey]: searchValue } = useQueryString();
  const { pushPaginatedQueryString } = useUpdatePaginatedQueryString();

  const handleSearch = useProtectedDebounce((event) => {
    pushPaginatedQueryString({ [qsKey]: event.target.value });
  }, 300);

  return (
    <Form initialValues={{ [qsKey]: searchValue }}>
      <Form.Item name={qsKey} label={label} style={{ marginBottom: 0 }}>
        <Input.Search
          placeholder={placeholder}
          allowClear
          onChange={handleSearch}
          {...props}
        />
      </Form.Item>
    </Form>
  );
}
