import { theme } from 'antd';
import React from 'react';
import { InputProps } from '../types';
import { ObjectInput } from './ObjectInput';

export function NestedObjectInput(props: InputProps): React.JSX.Element {
  const { token } = theme.useToken();

  return (
    <div
      style={{
        paddingTop: token.paddingXXS,
        paddingLeft: token.padding,
        paddingRight: token.padding,
        marginBottom: token.margin,
        background: token.colorBgContainerDisabled,
        borderRadius: token.borderRadius,
      }}
    >
      <ObjectInput {...props} />
    </div>
  );
}
