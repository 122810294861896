import { Tag, TagProps, Tooltip } from 'antd';
import React, { CSSProperties } from 'react';

type XTagProps = TagProps & {
  tooltip?: string;
  style?: CSSProperties;
  children: React.ReactNode;
};

export function XTag({
  tooltip,
  style,
  children,
  ...props
}: XTagProps): React.JSX.Element {
  return (
    <Tooltip title={tooltip}>
      <Tag style={{ margin: 0, fontWeight: 400, ...style }} {...props}>
        {children}
      </Tag>
    </Tooltip>
  );
}
