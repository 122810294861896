import { DataChange } from '@x/types';
import * as R from 'ramda';

export function findLogChanges(
  current: Record<string, any>,
  previous: Record<string, any>,
): DataChange[] {
  const currKeys = Object.keys(current);
  const prevKeys = Object.keys(previous);

  const addedChanges = currKeys
    .filter((k) => !prevKeys.includes(k))
    .map((k) => ({ key: k, newValue: current[k] }));

  const modifiedChanges = currKeys
    .filter((k) => prevKeys.includes(k) && !R.equals(current[k], previous[k]))
    .map((k) => ({ key: k, newValue: current[k], oldValue: previous[k] }));

  const removedChanges = prevKeys
    .filter((k) => !currKeys.includes(k))
    .map((k) => ({ key: k, oldValue: previous[k] }));

  return [...addedChanges, ...modifiedChanges, ...removedChanges];
}
