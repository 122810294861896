import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin, SpinProps } from 'antd';
import React from 'react';

export function XSpin({ ...props }: SpinProps): React.JSX.Element {
  return (
    <Flex justify="center">
      <Spin {...props} indicator={<LoadingOutlined spin />} />
    </Flex>
  );
}
