import { Input } from 'antd';
import _omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';
import { InputProps } from '../types';

export function TextAreaInput(props: InputProps): React.JSX.Element {
  const { id, InputWrapper, schema, handleChange, currentValue, name } = props;
  const inputVal = currentValue ?? '';
  const [newVal, setNewVal] = useState(inputVal);
  const { inputProps = {} } = schema;
  const { changeOnBlur } = inputProps;
  const inputOptions = _omit(inputProps, [
    'hideLabel',
    'layout',
    'changeOnBlur',
    'noWrapper',
  ]);

  useEffect(() => {
    setNewVal(inputVal);
  }, [inputVal]);

  function renderInput() {
    return (
      <Input.TextArea
        id={id}
        data-testid={`textarea-${name}`}
        value={changeOnBlur ? newVal : inputVal}
        onBlur={() => changeOnBlur && handleChange(newVal)}
        onChange={(evt: any) => {
          return changeOnBlur
            ? setNewVal(evt.target.value)
            : handleChange(evt.target.value);
        }}
        {...inputOptions}
      />
    );
  }

  if (inputProps?.noWrapper) {
    return renderInput();
  }

  return <InputWrapper {...props}>{renderInput()}</InputWrapper>;
}
