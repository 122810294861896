import { PlusOutlined } from '@ant-design/icons';
import { ConnectionDefinitionType, DefinitionAttribute } from '@x/api';
import { SelectOption } from '@x/types';
import { cleanObject } from '@x/utils';
import { Tooltip, Typography } from 'antd';
import { OAuthClientListItem } from 'api/types';
import { UnstyledButton } from 'components/UnstyledButton';
import { NestedObjectInput } from 'features/formRenderer/components/NestedObjectInput';
import { JsonFormSchema } from 'features/formRenderer/types';
import * as R from 'ramda';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type OAuthClientOption =
  | {
      title: string;
      label: string;
      options: (
        | SelectOption
        | {
            label: ReactNode;
            value: string;
            key: string;
            disabled?: boolean;
            hide?: boolean;
          }
      )[];
    }
  | SelectOption
  | undefined;

type MakeSchemaPropertiesFromAttributesProps = {
  attributes?: DefinitionAttribute[];
  isNew?: boolean;
  isConnected?: boolean;
  canEditConnection?: boolean;
  oauthClientOptions?: OAuthClientOption[];
  linkToClient?: string;
  onClientSearch?: (value: string) => void;
};

export function makeSchemaPropertiesFromAttributes({
  attributes = [],
  isNew,
  isConnected,
  canEditConnection = true,
  oauthClientOptions,
  linkToClient,
  onClientSearch,
}: MakeSchemaPropertiesFromAttributesProps): Record<string, JsonFormSchema> {
  const getDisabledMessage = (): string | undefined => {
    if (!canEditConnection) return 'You cannot edit this connection.';
    if (isConnected) return 'Disconnect to change this value.';
    return;
  };

  const tooltip = getDisabledMessage();

  return attributes.reduce(
    (acc: any, attr) => {
      const writeOnly = !isNew && attr.isSensitive;
      const disabled = Boolean(tooltip);
      const property = {
        type: 'string',
        required: attr.isRequired,
        description: attr.description,
        readOnly: Boolean(tooltip),
        renderer: attr.options ? 'select' : undefined,
        writeOnly,
        inputProps: cleanObject({
          tooltip,
          disabled,
          options: attr.options,
          optionRender: attr.options
            ? (option: any) => (
                <Tooltip title={option.data.description}>
                  {option.data.label}
                </Tooltip>
              )
            : undefined,
        }),
      };

      return { ...acc, [attr.key]: property };
    },
    oauthClientOptions
      ? {
          oAuthClientId: {
            type: 'string',
            title: 'OAuth Client',
            required: true,
            readOnly: !isNew,
            renderer: 'select',
            writeOnly: !isNew,
            description: linkToClient ? (
              <Link to={linkToClient}>View Client</Link>
            ) : undefined,
            inputProps: cleanObject({
              'data-testid': 'select-oauth-client',
              tooltip: !isNew
                ? 'This cannot be changed after creation.'
                : undefined,
              options: [
                ...oauthClientOptions,
                {
                  label: (
                    <UnstyledButton
                      type="link"
                      icon={<PlusOutlined />}
                      data-testid="create-client"
                    >
                      Create New Client
                    </UnstyledButton>
                  ),
                  value: '+create',
                },
              ],
              onSearch: onClientSearch,
              filterOption: () => true,
            }),
          },
        }
      : {},
  );
}

function formatProperties(
  schemaProperties: Record<string, JsonFormSchema>,
  clientForm?: JsonFormSchema,
) {
  if (!clientForm) return schemaProperties;

  return {
    oAuthClientId: schemaProperties.oAuthClientId,
    newClientForm: {
      title: 'New OAuth Client',
      renderer: NestedObjectInput,
      ...clientForm,
    },
    ...R.omit(['oAuthClientId'], schemaProperties),
  };
}

type JsonFormSchemaProps = {
  schemaProperties?: Record<string, JsonFormSchema>;
  canEditName?: boolean;
  newClientForm?: JsonFormSchema;
};

export function makeConnectionFormSchema({
  schemaProperties = {},
  canEditName,
  newClientForm,
}: JsonFormSchemaProps): JsonFormSchema {
  const required = Object.keys(schemaProperties).filter(
    (key) => schemaProperties[key].required,
  );

  return {
    type: 'object',
    required: ['name'],
    properties: {
      name: {
        type: 'string',
        inputProps: {
          autoComplete: 'off',
          tooltip:
            canEditName === false
              ? 'You cannot edit this connection.'
              : undefined,
        },
      },
      configuration: {
        type: 'object',
        required,
        properties: formatProperties(schemaProperties, newClientForm),
      },
    },
  };
}

function createClientOptions(
  title: string,
  clients: OAuthClientListItem[] | undefined,
) {
  return {
    title,
    label: `${title} Clients`,
    options:
      clients?.map((client) => ({
        label: (
          <Typography.Text
            data-testid={client.name}
            ellipsis={{ tooltip: client.name }}
          >
            {client.name}
          </Typography.Text>
        ),
        value: client.id,
        key: title + client.id,
      })) ?? [],
  };
}

export function getClientOptions({
  definitionType,
  clients,
}: {
  definitionType?: ConnectionDefinitionType;
  clients: OAuthClientListItem[] | undefined;
}) {
  if (
    !definitionType ||
    ![
      ConnectionDefinitionType.OAuth2CodeFlow,
      ConnectionDefinitionType.OAuth2ClientCredentials,
    ].includes(definitionType)
  )
    return;

  const [publicClients, accountClients] = R.partition(
    R.prop('isPublic'),
    clients,
  );

  const accountClientOptions = createClientOptions('Account', accountClients);
  const publicClientOptions = createClientOptions('Public', publicClients);

  return [accountClientOptions, publicClientOptions].filter(
    (el) => el.options?.length,
  );
}
