import { NODE_SEPARATOR } from '@x/config';
import { ObjectPath } from '@x/types';
import { DataNode } from 'antd/es/tree';
import * as R from 'ramda';

export function getTreePath(
  keyArray: string[],
  treeData: DataNode[],
): ObjectPath {
  return keyArray.reduce((acc, _key, index) => {
    const nodeKey = R.take(index + 1, keyArray).join(NODE_SEPARATOR) as string;
    const targetLocation = R.path(acc, treeData);

    return [
      ...acc,
      R.findIndex(R.propEq('key', nodeKey), targetLocation),
      ...(index < keyArray.length - 1 ? ['children'] : []),
    ];
  }, [] as ObjectPath);
}
