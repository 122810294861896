/* eslint-disable no-redeclare */ // TODO clear
import * as R from 'ramda';

export function toggleArrayItem<T>(arr: T[]): (item: T) => T[];

export function toggleArrayItem<T>(arr: T[], item: T): T[];

export function toggleArrayItem<T>(
  _arr: T[],
  _item?: T,
): T | ((item: T) => T[]) {
  return R.curry((arr: T[], item: T) => R.symmetricDifference(arr, [item]))(
    ...arguments,
  );
}
