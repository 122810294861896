import { PaginatedResponse } from './PaginatedResponse';

export interface Task {
  id: string;
  subscriberId: number;
  workflowName: string;
  workflowInstanceId: number;
  initiatingWorkflowInstanceId: string;
  title: string;
  description: string;
  jsonSchema: string;
  initialValue: string;
  type: string;
  createdOn: string;
  finishedOn: string;
  finishedBy: string;
  finishedStatus: 'Cancelled' | 'Completed' | null;
  resumeInput?: string;
}

export interface TaskFilterInterface {
  taskType: string | null;
  subscriber: number | null;
  search: string | null;
  title: string | null;
  description: string | null;
  workflowName: string | null;
}

export interface TaskSearchParams {
  pageNumber?: number;
  pageSize?: number;
  isFinished?: boolean;
  ascending?: boolean;
  orderBy?: string;
  search?: string;
  title?: string;
  description?: string;
  workflowName?: string;
  subscriberId?: number;
  initiatingWorkflowInstanceId?: string;
}

export type TaskData = PaginatedResponse<Task>;

export enum TaskAction {
  Resolve = 1,
  Cancel = 2,
}

export interface BulkResolveTaskByIdsBody {
  action: TaskAction;
  subscriberId: number | undefined;
  userTaskIds: string[] | undefined;
}

export interface ResolveTaskByIdBody {
  input: Record<string, unknown>;
}

export interface BulkResolveTaskByFiltersBody {
  action: TaskAction;
  subscriberId: number;
  title: string | null;
  description: string | null;
  workflowName: string | null;
  taskType: string | null;
  search: string | null;
  isFinished?: boolean;
  workflowInstanceId?: number;
}
