import { useAxiosQuery } from '@x/api';
import { getUserAccounts } from 'api/account';
import { useAuthenticatedUser } from './useAuthenticatedUser';

export function useUserHasAccountAccess(
  accountId: number | undefined | null,
): boolean {
  const user = useAuthenticatedUser();
  const { data: userAccounts } = useAxiosQuery(
    getUserAccounts({
      $userId: user?.id,
      enabled: !user.isPrincipal && Boolean(accountId),
    }),
  );

  const userAccountIds = userAccounts?.accountAssignments.map(
    (assignment) => assignment.account.id,
  );

  return (
    user.isPrincipal ||
    Boolean(accountId && userAccountIds?.includes(accountId))
  );
}
