import * as R from 'ramda';
import { DynamicInput } from '../components/DynamicInput';
import { FormProps, Renderer } from '../types';

const getKeyFromPath = R.compose(R.defaultTo(''), R.last, R.split('.'));

const sortRenderers = R.sortBy(
  R.cond([
    [R.prop('$id'), R.always(0)],
    [R.prop('key'), R.always(1)],
    [R.prop('name'), R.always(2)],
    [R.prop('format'), R.always(3)],
    [R.prop('writeOnly'), R.always(4)],
    [R.prop('type'), R.always(5)],
    [R.T, R.always(6)],
  ]),
);

const propEqOrMissing = R.curry((key: string, value: unknown, obj: Renderer) =>
  R.anyPass([R.propEq(key, value), R.complement(R.has)(key)])(obj),
);

export function getRenderer({ path, schema, renderers }: FormProps): Renderer {
  const { renderer, type, format, writeOnly, $id } = schema;
  const rendererKey = getKeyFromPath(path);
  const defaultRenderer = schema.enum ? 'select' : renderer;
  const rendererName = renderer || defaultRenderer;
  const normalizedType = type instanceof Array ? type : [type];
  const firstType = normalizedType[0];

  if (typeof renderer === 'function' || typeof renderer === 'object') {
    return { renderer };
  }

  return R.pipe(
    R.filter(
      R.allPass([
        propEqOrMissing('writeOnly', writeOnly),
        propEqOrMissing('type', firstType),
        propEqOrMissing('format', format),
        propEqOrMissing('name', rendererName),
        propEqOrMissing('key', rendererKey),
        propEqOrMissing('$id', $id),
      ]),
    ),
    sortRenderers,
    R.head,
    R.defaultTo({ renderer: DynamicInput }),
  )(renderers);
}

export default getRenderer;
