import * as R from 'ramda';

/*
 * key = 'id'
 * value = 1
 * collection = { data: [{ id: 1, foo: 'bar' }, { id: 2, foo: 'baz' }]}
 * // { id: 1, foo: 'bar' }
 */

// findFromData :: string -> value -> { data: a[] } -> a
export const findFromData = R.curry(
  (key: string, value: unknown, collection: unknown) =>
    R.o(R.find(R.propEq(key, value)), R.propOr([], 'data'))(collection),
);

export default findFromData;
