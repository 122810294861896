import { hasValue } from '@x/utils';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

// export function useRequiredParams<T extends Record<string, string>>(
//   requiredParams: string[],
// ) {
//   const params = useParams<T>() as Record<string, any>;

//   requiredParams.forEach((param) => {
//     invariant(hasValue(params[param]), `Missing required param: ${param}`);
//   });

//   return params as T;
// }

export function useRequiredParams<T extends string>(
  requiredParams: readonly T[],
): { [K in T]: string } {
  const params = useParams() as Record<string, any>;

  requiredParams.forEach((param) => {
    invariant(hasValue(params[param]), `Missing required param: ${param}`);
  });

  return params as { [K in T]: string };
}
