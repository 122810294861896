import _map from 'lodash/map';
import { v4 } from 'uuid';

export function getSanitizedKeyName(
  key: string,
  value: Record<string, any> = {},
): string {
  const sanitizedKey = key.replaceAll('.', '-');

  if (value[sanitizedKey] === undefined) {
    return sanitizedKey;
  }

  return getSanitizedKeyName(`${sanitizedKey} - duplicate`, value);
}

export const makeItems = (
  obj: Record<string, any>,
): {
  $key: string;
  $value: any;
  id: string;
}[] =>
  _map(obj, (value, key) => ({
    $key: key,
    $value: value,
    id: v4(),
  }));
