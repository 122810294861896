import { message } from 'antd';
import _isNil from 'lodash/isNil';
import * as R from 'ramda';
import React from 'react';

export const sortBy =
  (sortKey: string) =>
  (a: Record<string, any>, b: Record<string, any>): -1 | 0 | 1 => {
    const path = sortKey.split('.');

    let aVal = R.path(path, a);
    let bVal = R.path(path, a);

    if (typeof aVal === 'string') {
      aVal = aVal.toLowerCase();
    }

    if (typeof bVal === 'string') {
      bVal = bVal.toLowerCase();
    }

    if (aVal === bVal) {
      return 0;
    }

    if (_isNil(aVal)) {
      return 1;
    }

    if (_isNil(bVal)) {
      return -1;
    }

    return aVal < bVal ? -1 : 1;
  };

export const stopIt = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
): void => {
  e.preventDefault();
  e.stopPropagation();
};

export const convertForCodeMirror = (val?: unknown): string => {
  return R.cond([
    [R.isNil, R.always('')],
    [R.is(String), R.identity],
    [R.T, R.always(JSON.stringify(val, null, 2))],
  ])(val);
};

export const errorMessage = (
  err: Record<string, any> | null,
  defaultMessage: string,
): void => {
  if (err?.code === 20) {
    console.error('Aborted request');
  } else {
    message.error(
      err?.message || err?.Message || defaultMessage || 'An error occurred.',
    );
  }
};
