import { TCurry2, TError } from '@x/types';
import { AxiosError } from 'axios';
import * as R from 'ramda';
import { isNot } from './predicates';

const isPlainObject = R.allPass([R.is(Object), isNot(Array)]);
const allHave = R.pipe(R.has(R.__), R.all);

type ApiErrorDetails = { title: string; detail: string };
type ApiErrorsResponse = { errors: TError[] };

export const getAxiosErrors: TCurry2<
  string,
  AxiosError<string | ApiErrorDetails | ApiErrorDetails[] | ApiErrorsResponse>,
  string[]
> = R.curry(
  (
    defaultErrorMessage: string,
    errorResponse: AxiosError<
      string | ApiErrorDetails | ApiErrorDetails[] | ApiErrorsResponse
    >,
  ) =>
    R.pipe(
      R.path(['response', 'data']),
      R.cond([
        [R.allPass([R.identity, R.is(String)]), R.of],
        [
          R.allPass([isPlainObject, R.has('detail')]),
          R.pipe(R.prop('detail'), R.of),
        ],
        [R.allPass([R.is(Array), allHave('detail')]), R.map(R.prop('detail'))],
        [
          R.allPass([
            isPlainObject,
            R.pipe(
              R.prop('errors'),
              R.allPass([R.is(Array), allHave('message')]),
            ),
          ]),
          R.pipe(R.prop('errors'), R.map(R.prop('message'))),
        ],
        [R.T, R.always([defaultErrorMessage])],
      ]),
    )(errorResponse),
);

/**
 * used to throw errors in pipes
 *  or composes, should be used something like this:
 *  R.pipe(
 *    R.prop('requiredProp'),
 *
 *    // can easilty read what the thrown error will be
 *    // but it won't be thrown unless the when condition is me
 *    R.when(R.isNil, throwError('that prop is required'),
 *    // ... continue with your logic
 *  )(badData)
 **/
export function throwError(errorMessage: string): () => never {
  return () => {
    throw new Error(errorMessage);
  };
}
