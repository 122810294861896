import * as R from 'ramda';

export const schemaPropertyTypes = [
  'string',
  'number',
  'integer',
  'object',
  'array',
  'boolean',
  'null',
  '$ref',
] as const;

export const stringFormats = [
  'date-time',
  'time',
  'date',
  'duration',
  'email',
  'idn-email',
  'hostname',
  'idn-hostname',
  'ipv4',
  'ipv6',
  'uuid',
  'uri',
  'uri-reference',
  'iri',
  'iri-reference',
  'uri-template',
  'json-pointer',
  'relative-json-pointer',
  'regex',
] as const;

export const openApiIntegerFormats = ['int32', 'int64'];

export const openApiNumberFormats = ['double', 'float'];

export const openApiStringFormats = R.sortBy(R.identity, [
  ...stringFormats,
  'byte',
  'binary',
  'password',
]);

export const schemaPropertyFormats = {
  string: stringFormats,
  openApiString: openApiStringFormats,
  number: openApiNumberFormats,
  integer: openApiIntegerFormats,
};

export const contentEncodings = [
  '7bit',
  '8bit',
  'binary',
  'quoted-printable',
  'base16',
  'base32',
  'base64',
] as const;

export const basePropertyBooleans = [
  { value: 'deprecated', label: 'Deprecated' },
  { value: 'readOnly', label: 'Read Only' },
  { value: 'writeOnly', label: 'Write Only' },
];

export const openApiBooleans = [{ value: 'nullable', label: 'Nullable' }];

export const arrayPropertyBooleans = [
  { value: 'uniqueItems', label: 'Unique Items' },
];

export const objectPropertyBooleans = [
  { value: 'allowAdditionalProperties', label: 'Allow Additional Properties' },
];

export const DEFAULT_HIDDEN_SUBSCHEMA_FIELDS = [
  'name',
  'required',
  'title',
  'description',
  'default',
];
