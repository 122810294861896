import { useEffect, useState } from 'react';

export function useContainerHeight({
  fullHeightOffset,
  height,
  loaded,
}: {
  fullHeightOffset?: number;
  height?: number;
  loaded?: boolean;
}): number | undefined {
  const [containerHeight, setContainerHeight] = useState<number>();

  useEffect(() => {
    if (height) {
      setContainerHeight(height);
    } else if (fullHeightOffset) {
      setContainerHeight(window.innerHeight - fullHeightOffset);
    }
  }, [fullHeightOffset, height, loaded]);

  return containerHeight;
}
