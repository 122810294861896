import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { KEY_PROPERTY_SEPARATOR, NODE_SEPARATOR } from '@x/config';
import { xTheme } from '@x/styles';
import { parseIfNumber } from '@x/utils';
import {
  Button,
  Skeleton,
  Space,
  theme,
  Tree,
  TreeProps,
  Typography,
} from 'antd';
import * as R from 'ramda';
import React, { ReactNode, useEffect, useState } from 'react';
import { NoDataDisplay } from '../NoDataDisplay';
import { RequiredItemMarker } from '../RequiredItemMarker';
import { XTreeDataNode, XTreeKey } from './types';
import { getAllKeysFromTree } from './utils';

const { space } = xTheme;

type XTreeProps = TreeProps & {
  isNodeEditable?: (node: XTreeDataNode) => boolean;
  onNodeEdit?: (pathToProperty: string[]) => void;
  isNodeAddable?: (node: XTreeDataNode) => boolean;
  onNodeAdd?: (pathToProperty: string[]) => void;
  isNodeDeletable?: (node: XTreeDataNode) => boolean;
  onNodeDelete?: (pathToProperty: string[]) => void;
  isNodeRequired?: (node: XTreeDataNode) => boolean;
  hideNodeValue?: boolean;
  expandOnSelect?: boolean;
  boldTitles?: boolean;
  emptyDisplay?: ReactNode;
  treeData?: XTreeDataNode[];
};

export function XTree({
  showLine = { showLeafIcon: false },
  height,
  treeData,
  defaultExpandedKeys = [],
  isNodeEditable,
  onNodeEdit,
  isNodeAddable,
  onNodeAdd,
  isNodeDeletable,
  onNodeDelete,
  isNodeRequired,
  hideNodeValue,
  expandOnSelect,
  boldTitles,
  emptyDisplay,
  ...props
}: XTreeProps): React.JSX.Element {
  const { token } = theme.useToken();
  const { defaultExpandAll = false } = props;
  const [expandedTreeKeys, setExpandedTreeKeys] = useState<XTreeKey[]>(
    defaultExpandAll ? getAllKeysFromTree(treeData) : defaultExpandedKeys,
  );

  useEffect(() => {
    if (!treeData) return;

    if (defaultExpandAll) {
      setExpandedTreeKeys(getAllKeysFromTree(treeData));
    }
  }, [defaultExpandAll, treeData]);

  function handleNodeClick(key: XTreeKey) {
    setExpandedTreeKeys((prevExpandedKeys) => {
      if (prevExpandedKeys.includes(key)) {
        return R.remove(prevExpandedKeys.indexOf(key), 1, prevExpandedKeys);
      }

      return [...prevExpandedKeys, key];
    });
  }

  function handleExpanderClick(_keys: any, data: any) {
    handleNodeClick(data.node.key);
  }

  if (!treeData) return <Skeleton active />;

  if (treeData && !treeData.length)
    return emptyDisplay ? <>{emptyDisplay}</> : <NoDataDisplay />;

  return (
    <Tree
      showLine={showLine}
      height={height}
      expandedKeys={expandedTreeKeys}
      switcherIcon={<DownOutlined data-testid={`expandIcon`} />}
      treeData={treeData}
      onExpand={(keys, data) => handleExpanderClick(keys, data)}
      titleRender={(data) => {
        const key = data.key as string;
        const propertyPath = R.pipe(
          R.split(NODE_SEPARATOR),
          R.map(parseIfNumber),
        )(data.key);
        const [propertyName, propertyValue] = R.split(
          KEY_PROPERTY_SEPARATOR,
          data.title,
        );
        const isEditable = isNodeEditable && isNodeEditable(data) && onNodeEdit;
        const isAddable = isNodeAddable && isNodeAddable(data) && onNodeAdd;
        const isDeletable =
          isNodeDeletable && isNodeDeletable(data) && onNodeDelete;
        const isRequired = isNodeRequired ? isNodeRequired(data) : false;

        if (data.children) {
          return (
            <Space style={{ paddingBottom: space[1], paddingTop: space[1] }}>
              <Button
                onClick={() => {
                  if (expandOnSelect) {
                    handleNodeClick(key);
                  }
                }}
                size="small"
                type="text"
                style={{
                  fontWeight: boldTitles
                    ? token.fontWeightStrong
                    : xTheme.fontWeightNormal,
                }}
              >
                <RequiredItemMarker required={isRequired}>
                  {typeof data.title === 'function'
                    ? data.title(data)
                    : data.title}
                </RequiredItemMarker>
              </Button>
              {isEditable && (
                <Button
                  type="link"
                  size="small"
                  data-testid={`edit-${data.title}`}
                  onClick={() => onNodeEdit(propertyPath)}
                >
                  <EditOutlined />
                </Button>
              )}
              {isAddable && (
                <Button
                  type="text"
                  size="small"
                  data-testid={`add-${data.title}`}
                  onClick={() => onNodeAdd(propertyPath)}
                >
                  <Typography.Text type="success">
                    <PlusOutlined />
                  </Typography.Text>
                </Button>
              )}
              {isDeletable && (
                <Button
                  danger
                  type="link"
                  size="small"
                  data-testid={`delete-${data.title}`}
                  onClick={() => onNodeDelete(propertyPath)}
                >
                  <DeleteOutlined />
                </Button>
              )}
            </Space>
          );
        } else {
          return (
            <div
              style={{
                whiteSpace: 'nowrap',
                width: '100%',
                paddingBottom: space[1],
                paddingTop: space[1],
              }}
            >
              <Typography.Text
                style={{ marginLeft: space[2], display: 'inline-block' }}
              >
                <RequiredItemMarker required={isRequired}>
                  {propertyName}
                </RequiredItemMarker>
              </Typography.Text>
              {!hideNodeValue && propertyValue && (
                <Typography.Text
                  type="secondary"
                  ellipsis={{ tooltip: propertyValue }}
                >
                  : {propertyValue}
                </Typography.Text>
              )}
            </div>
          );
        }
      }}
      {...props}
    />
  );
}
