import { SelectOption } from '@x/types';
import { Select } from 'antd';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import React from 'react';
import { InputProps } from '../types';

function makeOption(enumeration: any): SelectOption {
  if (enumeration.label && enumeration.value) {
    return enumeration;
  }

  if (typeof enumeration === 'object') {
    return { label: enumeration.name, value: JSON.stringify(enumeration) };
  }

  return { label: enumeration, value: enumeration };
}

export function TagsInput(props: InputProps): React.JSX.Element {
  const { id, InputWrapper, schema, handleChange, currentValue, name } = props;
  const { options, inputProps = {} } = schema;
  const enums = _get(schema, 'enum', _get(schema, 'items.enum', []));
  const optionsToUse = options ? options : _map(enums, makeOption);
  const inputOptions = _omit(inputProps, [
    'hideLabel',
    'layout',
    'noWrapper',
    'isDynamic',
  ]);

  function renderInput() {
    return (
      <Select
        data-testid={`tagsInput-${name}`}
        id={id}
        value={currentValue || []}
        onChange={handleChange}
        mode="tags"
        open={false}
        options={optionsToUse}
        optionFilterProp="label"
        tokenSeparators={[',', ';']}
        css={
          inputProps.isDynamic
            ? { '.ant-select-selector': { borderTopLeftRadius: 0 } }
            : undefined
        }
        {...inputOptions}
      />
    );
  }

  if (inputProps?.noWrapper) {
    return renderInput();
  }

  return <InputWrapper {...props}>{renderInput()}</InputWrapper>;
}
