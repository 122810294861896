import { useAxiosQuery } from '@x/api';
import { XSpin } from '@x/components';
import { useQueryString } from '@x/hooks';
import { getInvitesVerify } from 'api/account';
import { Box } from 'components/Box';
import { UnauthorizedPage } from 'components/Layout/UnauthorizedPage';
import { useUsername } from 'features/user/hooks';
import * as R from 'ramda';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { AcceptInvite } from './AcceptInvite';
import { ExpiredInvite } from './ExpiredInvite';
import { InvalidInvite } from './InvalidInvite';

export function VerifyInvite(): React.JSX.Element {
  const auth = useAuth();
  const userName = useUsername();
  const { token } = useQueryString();
  const invite = useAxiosQuery(
    getInvitesVerify({
      params: { token: token },
      enabled: Boolean(token),
    }),
  );

  if (invite.isLoading) {
    return <XSpin />;
  }

  if (!token || invite.isError || !invite.data) {
    return (
      <ExpiredInvite
        message={R.pathOr(
          'Could not find Invite.',
          ['response', 'data', 'errors', 0, 'message'],
          invite.error,
        )}
      />
    );
  }

  if (
    auth.user &&
    userName.toLowerCase() !== invite.data?.email?.toLowerCase()
  ) {
    return (
      <InvalidInvite
        message={`The current user ${userName} doesn't match the Invite. Log out below and log in as the correct user.`}
      />
    );
  }

  return (
    <UnauthorizedPage top={'35%'}>
      <Box mx="auto" height="100%" width={['100%', '100%', 450]}>
        {<AcceptInvite invite={invite.data} />}
      </Box>
    </UnauthorizedPage>
  );
}
