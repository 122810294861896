import {
  CompletionContext,
  CompletionResult,
  snippetCompletion,
} from '@codemirror/autocomplete';
import { javascriptLanguage } from '@codemirror/lang-javascript';
import { syntaxTree } from '@codemirror/language';
import * as R from 'ramda';
import {
  appNetworkCompletionsConstructor,
  dayJsCompletionsConstructor,
  flowCompletionsConstructor,
  flowInfoCompletionsConstructor,
  flowStepCompletionsConstructor,
  flowTriggerCompletionsConstructor,
  flowTriggerEventCompletionsConstructor,
  lodashMethods,
  numeralJsCompletionsConstructor,
  workspaceCompletionsConstructor,
  workspaceInfoCompletionsConstructor,
} from '../config';
import { uuidCompletionsConstructor } from '../config/uuidCompletions';
import { Completion, CompletionConstructor } from '../types/completion';

function makeCompletions({ data, defaultValues }: CompletionConstructor) {
  return data.map((el) => {
    if (typeof el === 'string') {
      return {
        label: el,
        ...defaultValues,
      };
    }

    if (el.snippet) {
      const completion = R.omit(['snippet'], el);

      return snippetCompletion(
        el.snippet,
        R.mergeRight(defaultValues, completion),
      );
    }

    return R.mergeRight(defaultValues, el);
  });
}

const workspaceCompletions = makeCompletions(workspaceCompletionsConstructor);
const workspacInfoCompletions = makeCompletions(
  workspaceInfoCompletionsConstructor,
);
const appNetworkCompletions = makeCompletions(appNetworkCompletionsConstructor);
const flowCompletions = makeCompletions(flowCompletionsConstructor);
const flowInfoCompletions = makeCompletions(flowInfoCompletionsConstructor);
const flowStepCompletions = makeCompletions(flowStepCompletionsConstructor);
const triggerCompletions = makeCompletions(flowTriggerCompletionsConstructor);
const triggerEventCompletions = makeCompletions(
  flowTriggerEventCompletionsConstructor,
);
const dayJsCompletions = makeCompletions(dayJsCompletionsConstructor);
const lodashCompletions = makeCompletions({
  data: lodashMethods,
  defaultValues: {
    type: 'function',
    info: 'See Lodash documentation.',
    detail: 'Lodash v4.17.21',
  },
});
const numeralJsCompletions = makeCompletions(numeralJsCompletionsConstructor);
const uuidCompletions = makeCompletions(uuidCompletionsConstructor);
const globalCompletions: Completion[] = [
  {
    label: 'workspace',
    info: 'Accesses Workspace properties and methods.',
    type: 'namespace',
  },
  {
    label: 'appNetwork',
    info: 'Accesses App Network properties and methods.',
    type: 'namespace',
  },
  {
    label: 'flow',
    info: 'Accesses Flow properties and methods.',
    type: 'namespace',
  },
  {
    label: 'dayjs()',
    info: 'Returns a Day.js object.',
    type: 'function',
    detail: 'Day.js v1.11.7',
  },
  {
    label: 'numeral()',
    info: 'Returns a Numeral.js object.',
    type: 'function',
    detail: 'Numeral.js v2.06',
  },
  {
    label: '_',
    info: 'Accesses Lodash methods.',
    type: 'namespace',
    detail: 'Lodash v4.17.21',
  },
  {
    label: 'uuid',
    info: 'Accesses uuid methods.',
    type: 'namespace',
    detail: 'uuid v9.0.0',
  },
];

// TODO replace with language server
function getScopedCompletions(str?: string) {
  if (!str) return;
  const firstCall = str.match(/[a-z_]+/gi)?.[0];
  const cleanedStr = str.replace(/\s/g, '');

  if (firstCall === 'dayjs') return dayJsCompletions;
  if (firstCall === 'numeral') return numeralJsCompletions;
  if (cleanedStr.startsWith('flow.step(')) return flowStepCompletions;
  if (cleanedStr === '_.' || cleanedStr.startsWith('_.chain('))
    return lodashCompletions;

  switch (cleanedStr) {
    case 'workspace.':
      return workspaceCompletions;
    case 'workspace.info().':
      return workspacInfoCompletions;
    case 'appNetwork.':
      return appNetworkCompletions;
    case 'flow.':
      return flowCompletions;
    case 'flow.info().':
      return flowInfoCompletions;
    case 'flow.trigger.':
      return triggerCompletions;
    case 'flow.trigger.event.':
      return triggerEventCompletions;
    case 'uuid.':
      return uuidCompletions;
    default:
      return;
  }
}

const completePropertyAfter = ['PropertyName', '.', '?.'];
const dontCompleteAfter = [
  'TemplateString',
  'LineComment',
  'BlockComment',
  'VariableDefinition',
  'PropertyDefinition',
];

function jsCompletions(context: CompletionContext): CompletionResult | null {
  const nodeBefore = syntaxTree(context.state).resolveInner(context.pos, -1);
  const from = /\./.test(nodeBefore.name) ? nodeBefore.to : nodeBefore.from;

  if (
    completePropertyAfter.includes(nodeBefore.name) &&
    nodeBefore.parent?.name === 'MemberExpression'
  ) {
    const preceedingToken = context.tokenBefore(['MemberExpression']);

    return {
      from: from,
      options: getScopedCompletions(preceedingToken?.text) ?? [],
      validFor: /^[\w$]*$/,
    };
  }

  const dontComplete = dontCompleteAfter.includes(nodeBefore.name);
  const word = context.matchBefore(/\w*/);
  const noCompletion =
    !word || word.text.trim().length <= 0 || word.from === word.to;

  if (dontComplete || noCompletion) return null;

  return {
    from: from,
    options: globalCompletions,
    validFor: /^[\w$]*$/,
  };
}

// add completions to language
export const jsCompletionExt = javascriptLanguage.data.of({
  autocomplete: jsCompletions,
});
