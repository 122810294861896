import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface UseResizableOptions {
  minHeight?: number;
  initialHeight?: number;
}

interface UseResizable {
  styles: { height?: number };
  listeners: { onMouseDown: (e: React.MouseEvent) => void };
  nodeRef: React.RefObject<HTMLDivElement>;
  setHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export function useResizable({
  minHeight = 0,
  initialHeight,
}: UseResizableOptions): UseResizable {
  const [dragging, setDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ height: 0, y: 0 });
  const [height, setHeight] = useState<number | undefined>(initialHeight);
  const nodeRef = useRef<HTMLDivElement>(null);
  const styles = useMemo(() => {
    return { height };
  }, [height]);

  const onMouseDown = useCallback((e: React.MouseEvent) => {
    if (nodeRef.current) {
      const startHeight = nodeRef.current.getBoundingClientRect().height;

      setStartPosition({ height: startHeight, y: e.clientY });
      setDragging(true);
    }
  }, []);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (dragging && nodeRef.current) {
        const heightChange = e.clientY - startPosition.y;
        const newHeight = heightChange + startPosition.height;

        if (newHeight < minHeight) {
          setHeight(minHeight);

          return;
        }

        setHeight(newHeight);
      }
    },
    [dragging, minHeight, startPosition],
  );

  const onMouseUp = useCallback((e: MouseEvent) => {
    setDragging(false);
  }, []);

  const listeners = useMemo(() => {
    return { onMouseDown };
  }, [onMouseDown]);

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  return { styles, listeners, nodeRef, setHeight };
}
