import { TCurry2 } from '@x/types';
import * as R from 'ramda';

export const whereEquals: TCurry2<
  Record<string, unknown>,
  Record<string, unknown>,
  boolean
> = R.curry(
  (partialObj: Record<string, unknown>, obj: Record<string, unknown>) =>
    R.compose(
      R.where(R.__, obj),
      R.mapObjIndexed(R.unary(R.equals)),
    )(partialObj),
);
