import { Button } from 'antd';
import * as R from 'ramda';
import React, { useState } from 'react';
import AllSelectedTableHeader from './AllSelectedTableHeader';
import { XTable } from './XTable';

interface XSelectableTableProps extends Record<string, any> {
  handleSelectedRows?: (
    selectedRowKeys: unknown[],
    allRecordsSelected: boolean,
  ) => void;
  selectedRowsActionText?: string;
  rowKey: string;
  offset?: number;
  tableData?: {
    data?: unknown[];
    total?: number | null;
  };
  error: boolean | string;
}

export function XSelectableTable({
  handleSelectedRows,
  selectedRowsActionText,
  rowKey,
  offset,
  tableData,
  error,
  ...props
}: XSelectableTableProps): React.JSX.Element {
  const [allRecordsSelected, _setAllRecordsSelected] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<unknown[]>([]);
  const selectedCount = selectedRowKeys.length;
  const showRunButton = Boolean(allRecordsSelected || selectedCount);
  const dataLength = R.pathOr(0, ['data', 'length'], tableData);
  const total = R.propOr(0, 'total', tableData);
  const allOnPageSelected =
    selectedCount > 0 ? dataLength === selectedCount : false;
  const moreCouldBeSelected = dataLength < total;
  const showSelectedHeader =
    allRecordsSelected || (allOnPageSelected && moreCouldBeSelected);

  function getSelectedCount() {
    if (allRecordsSelected) {
      return ` (${total} selected)`;
    }

    const flattenedIds = tableData?.data?.map((obj: any) => obj.id);
    const availableKeys: unknown[] = selectedRowKeys.filter((key) =>
      flattenedIds?.includes(key),
    );

    if (availableKeys.length !== selectedRowKeys.length) {
      setSelectedRowKeys(availableKeys);
    }

    return availableKeys.length ? `(${availableKeys.length} selected)` : '';
  }

  function setAllRecordsSelected(newVal: boolean) {
    if (newVal) {
      setSelectedRowKeys(R.map(R.prop(rowKey), tableData?.data));
    }

    _setAllRecordsSelected(newVal);
  }

  const defaultProps = {
    tableData,
    rowKey,
    rowSelection: {
      selectedRowKeys,
      onChange: (val: any) => {
        setSelectedRowKeys(val);
        setAllRecordsSelected(false);
      },
      hideDefaultSelections: true,
      selections: [
        {
          key: 'select-all',
          text: 'Select all rows',
          onSelect: () => {
            setAllRecordsSelected(true);
          },
        },
        {
          key: 'unselect-all',
          text: 'Unselect all rows',
          onSelect: () => {
            setAllRecordsSelected(false);
            setSelectedRowKeys([]);
          },
        },
      ],
    },
    components: {
      header: {
        wrapper: ({ children }: { children: React.ReactNode }) => (
          <>
            <thead className="ant-table-thead" data-testid="table-header">
              {children}
            </thead>
            {showSelectedHeader && (
              <AllSelectedTableHeader
                tableData={tableData}
                selectedCount={selectedCount}
                allRecordsSelected={allRecordsSelected}
                setSelectedRowKeys={setSelectedRowKeys}
                setAllRecordsSelected={setAllRecordsSelected}
              />
            )}
          </>
        ),
      },
    },
  };
  const allProps = R.mergeDeepRight(defaultProps, props);

  return (
    <XTable
      {...allProps}
      offset={offset}
      error={error}
      pagination={{
        showSizeChanger: false,
        pageSize: total,
        showTotal: (totalLength: number, [start]: unknown[]) => (
          <>
            {`${start}-${dataLength} of ${totalLength} ${getSelectedCount()}`}

            {showRunButton && handleSelectedRows && (
              <Button
                type="link"
                style={{ marginRight: 24, height: 24 }}
                onClick={() =>
                  handleSelectedRows(selectedRowKeys, allRecordsSelected)
                }
              >
                {selectedRowsActionText}
              </Button>
            )}
          </>
        ),
      }}
    />
  );
}
