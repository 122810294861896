import { CompletionConstructor } from '../types';

/** defaults unlisted types to 'method' */
export const numeralJsCompletionsConstructor: CompletionConstructor = {
  data: [
    {
      label: 'format()',
      info: 'Formats a Numeral.js object.',
    },
    {
      label: 'value()',
      info: 'Returns the value of a Numeral.js object.',
    },
    {
      label: 'add()',
      info: 'Adds the given number to a Numeral.js value.',
    },
    {
      label: 'subtract()',
      info: 'Subtracts the given number from a Numeral.js value.',
    },
    {
      label: 'multiply()',
      info: 'Multiplies a Numeral.js value by the given number.',
    },
    {
      label: 'divide()',
      info: 'Divides a Numeral.js value by the given number.',
    },
    {
      label: 'clone()',
      info: 'Clones a Numeral.js object.',
    },
    {
      label: 'difference()',
      info: 'Finds the difference between a numeral object and the given value.',
    },
  ],
  defaultValues: { type: 'method', detail: 'Numeral.js v2.06' },
};
