import { NavigationState, QueryStringValues, TCurry2 } from '@x/types';
import { hasValue } from '@x/utils';
import * as R from 'ramda';
import { useSearchParams } from 'react-router-dom';

type TUseUpdateQueryString = {
  pushQueryString: (
    newQsValues: QueryStringValues,
    ...args: [NavigationState] | []
  ) => void;
  pushQueryStringWithDefaults: TCurry2<
    QueryStringValues,
    QueryStringValues,
    void,
    [NavigationState] | []
  >;
  replaceQueryString: (
    newQsValues: QueryStringValues,
    ...args: [NavigationState] | []
  ) => void;
  replaceQueryStringWithDefaults: TCurry2<
    QueryStringValues,
    QueryStringValues,
    void,
    [NavigationState] | []
  >;
};

export function useUpdateQueryString(): TUseUpdateQueryString {
  const [, setSearchParams] = useSearchParams();

  function modifyQS(values: QueryStringValues, qs: URLSearchParams) {
    Object.entries(values).forEach(([key, value]) => {
      if (hasValue(value)) {
        if (Array.isArray(value)) {
          qs.delete(key);
          value.forEach((v) => qs.append(key, v.toString()));
        } else {
          qs.set(key, value.toString());
        }
      } else {
        qs.delete(key);
      }
    });
  }

  function updateQS(
    newQsValues: QueryStringValues,
    replace = false,
    state?: NavigationState,
  ) {
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev);

        modifyQS(newQsValues, newParams);

        return newParams;
      },
      { replace, state },
    );
  }

  function updateQSWithDefaults(
    defaultQSValues: QueryStringValues,
    newQsValues: QueryStringValues,
    replace = false,
    state?: NavigationState,
  ) {
    setSearchParams(
      (prev) => {
        const newParams = new URLSearchParams(prev);

        modifyQS(defaultQSValues, newParams);
        modifyQS(newQsValues, newParams);

        return newParams;
      },
      { replace, state },
    );
  }

  function pushQueryString(
    newQsValues: QueryStringValues,
    ...args: [NavigationState] | []
  ) {
    updateQS(newQsValues, false, ...args);
  }

  const pushQueryStringWithDefaults = R.curryN(
    2,
    (
      defaultQSValues: QueryStringValues,
      newQsValues: QueryStringValues,
      state?: NavigationState,
    ) => {
      updateQSWithDefaults(defaultQSValues, newQsValues, false, state);
    },
  );

  function replaceQueryString(
    newQsValues: QueryStringValues,
    ...args: [NavigationState] | []
  ) {
    updateQS(newQsValues, true, ...args);
  }

  const replaceQueryStringWithDefaults = R.curryN(
    2,
    (
      defaultQSValues: QueryStringValues,
      newQsValues: QueryStringValues,
      state?: NavigationState,
    ) => {
      updateQSWithDefaults(defaultQSValues, newQsValues, true, state);
    },
  );

  return {
    pushQueryString,
    pushQueryStringWithDefaults,
    replaceQueryString,
    replaceQueryStringWithDefaults,
  };
}
