import { useQueryString, useUpdateQueryString } from '@x/hooks';
import { PaginatedResponse, SelectOption } from '@x/types';
import { coerce, flattenInfiniteQueryResults } from '@x/utils';
import { SelectProps } from 'antd/lib/select';
import * as R from 'ramda';
import React from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { InfiniteSelectFilter } from './InfiniteSelectFilter';

type SelectFilterProps<T> = SelectProps<any> & {
  query: UseInfiniteQueryResult<PaginatedResponse<T>>;
  optionMap: (item: T) => SelectOption;
  qsKey: string;
  label?: string;
  limit?: number;
  joinArrayParams?: boolean;
  defaultQsValues?: Record<string, string>;
};

export function InfiniteSelectFilterQueryString<T>({
  query,
  optionMap,
  qsKey,
  label,
  limit,
  joinArrayParams,
  defaultQsValues = {},
  ...props
}: SelectFilterProps<T>): React.JSX.Element {
  const isSingle = limit === 1;
  const rawValue = useQueryString()[qsKey];
  const { pushQueryStringWithDefaults } = useUpdateQueryString();
  const data = flattenInfiniteQueryResults(query);
  const options = data.map(optionMap);
  const firstOptionValue = R.o(R.prop('value'), R.head)(options);
  const selected = R.pipe(
    R.defaultTo([]),
    R.when(R.is(String), R.split(',')),
    R.map(coerce(R.type(firstOptionValue))),
  )(rawValue);

  const updateUrl = R.pipe(
    R.defaultTo([]),
    R.unless(R.is(Array), R.of),
    R.when(R.always(joinArrayParams), R.join(',')),
    R.assoc(qsKey, R.__, {}),
    pushQueryStringWithDefaults({
      PageNumber: '',
      pageNumber: '',
      ...defaultQsValues,
    }),
  );

  return (
    <InfiniteSelectFilter
      query={query}
      optionMap={optionMap}
      value={isSingle ? selected[0] : selected}
      placeholder={label}
      onChange={updateUrl}
      mode={!isSingle ? 'tags' : undefined}
      {...props}
    />
  );
}
