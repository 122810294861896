import _toNumber from 'lodash/toNumber';
import _toString from 'lodash/toString';
import * as R from 'ramda';

/*
 * type = 'Number',
 * value = '2'
 * // 2
 *
 * type = 'String'
 * value = '2'
 * // '2'
 *
 * type = 'String'
 * value = 2
 * // '2'
 *
 * type = 'Boolean'
 * value = 'false'
 * // false

 * type = 'Boolean'
 * value = '1'
 * // false
 *
 * type = 'Boolean'
 * value = 'true'
 * // true
 *
 * type = 'Number'
 * value = 'true'
 * // NaN
 *
 */

// coerce :: a -> b -> a | b
export const coerce = R.curry((type: string, value: unknown) =>
  R.cond([
    [R.always(R.equals(type, 'Number')), _toNumber],
    [R.always(R.equals(type, 'String')), _toString],
    [
      R.always(R.equals(type, 'Boolean')),
      R.ifElse(R.equals('false'), R.F, Boolean),
    ],
    [R.T, R.identity],
  ])(value),
);

export default coerce;
