import { allTrue } from '@x/utils';
import { Code } from 'components/Form';
import { isJsEvaluator } from 'features/flow/utils/evaluatorHelpers';
import _omit from 'lodash/omit';
import React, { useEffect, useRef, useState } from 'react';
import { InputProps } from '../types';

export function ExpressionInput(props: InputProps): React.JSX.Element {
  const {
    InputWrapper,
    schema,
    handleChange,
    currentValue,
    name,
    readOnly,
    engineVersion,
    evaluator,
  } = props;
  const { inputProps } = schema;
  const mode = inputProps?.mode ?? 'text/x-csharp';
  const inputOptions = _omit(inputProps, [
    'hideLabel',
    'layout',
    'noWrapper',
    'mode',
  ]);
  const [showEqualsMessage, setShowEqualsMessage] = useState(false);
  const jsOverride = allTrue([
    mode === 'text/x-csharp',
    engineVersion === 2,
    isJsEvaluator(evaluator),
  ]);
  const parseEqualsSign =
    inputProps?.parseEquals ??
    (mode === 'text/x-csharp' || mode === 'javascript');
  const trimEquals = parseEqualsSign && currentValue?.[0] === '=';
  const initialValue = trimEquals ? currentValue.substring(1) : currentValue;
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentValue?.toString().indexOf('==') === 0) {
      setShowEqualsMessage(true);
    } else {
      setShowEqualsMessage(false);
    }
  }, [currentValue]);

  function handleExpressionChange(val: string) {
    // without this it's impossible to set an empty value
    // an '=' will always be prepended
    if (val === '') {
      handleChange(val);
      return;
    }

    handleChange(parseEqualsSign ? '=' + val : val);
  }

  function renderCode() {
    return (
      <div ref={parentRef} style={inputProps?.style}>
        <Code
          data-testid={`expression-${name}`}
          mode={jsOverride ? 'javascript' : mode}
          initialValue={initialValue}
          onChange={handleExpressionChange}
          readOnly={readOnly}
          tooltipParent={
            parentRef.current?.closest('.ant-drawer-content-wrapper') ??
            parentRef.current
          }
          warningMessage={
            showEqualsMessage
              ? "'=' no longer required for expressions. Rejoice or despair at your leisure."
              : undefined
          }
          isFlow
          {...inputOptions}
        />
      </div>
    );
  }

  if (inputProps?.noWrapper) {
    return renderCode();
  }

  return <InputWrapper {...props}>{renderCode()}</InputWrapper>;
}
