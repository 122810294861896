import { useUpdateQueryString } from '@x/hooks';
import { qsValue } from '@x/utils';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface InputFilterProps extends InputProps {
  qsKey: string;
}

export function InputFilterQueryString({
  qsKey,
  ...props
}: InputFilterProps): React.JSX.Element {
  const { pushQueryString } = useUpdateQueryString();
  const { search } = useLocation();
  const filterValue = qsValue(qsKey, search);
  const [filter, setFilter] = useState(filterValue);

  useEffect(() => {
    setFilter(filterValue);
  }, [filterValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      pushQueryString({ [qsKey]: filter });
    }, 300);

    return () => clearTimeout(timeout);
  }, [filter, qsKey, pushQueryString]);

  return (
    <Input
      value={filter}
      onChange={(evt) => setFilter(evt.target.value)}
      {...props}
    />
  );
}

export default InputFilterQueryString;
