/* eslint-disable */

import { DataNode } from 'antd/es/tree';
import { XTreeDropInfo } from '../types';
import { updateKeys } from './updateKeys';

//Taken from the Ant docs. Seems like this should be built in...
//https://ant.design/components/tree#components-tree-demo-draggable
export const handleDrop = (
  info: XTreeDropInfo,
  gData: DataNode[],
): DataNode[] => {
  const dropKey = info?.node.key;
  const dragKey = info.dragNode.key;
  const dropPos = info.node.pos.split('-');
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

  // fixes an Ant Tree bug that allows subgroups to be dragged into themselves when they are the last item in the hierarchy, causing them to disappear from the hierarchy
  if (dropKey === dragKey) return gData;

  const loop = (
    data: DataNode[],
    key: React.Key,
    callback: (node: DataNode, i: number, data: DataNode[]) => void,
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }

      if (data[i].children) {
        loop(data[i].children!, key, callback);
      }
    }
  };
  const data = [...gData];

  // Find dragObject
  let dragObj: DataNode;
  loop(data, dragKey, (item, index, arr) => {
    arr.splice(index, 1);
    dragObj = item;
  });

  if (!info.dropToGap) {
    // Drop on the content
    loop(data, dropKey, (item) => {
      item.children = item.children || [];
      // where to insert
      item.children.unshift(dragObj);
    });
  } else if (
    ((info.node as any).props.children || []).length > 0 && // Has children
    (info.node as any).props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    loop(data, dropKey, (item) => {
      item.children = item.children || [];
      // where to insert
      item.children.unshift(dragObj);
      // in previous version, we use item.children.push(dragObj) to insert the
      // item to the tail of the children
    });
  } else {
    let ar: DataNode[] = [];
    let i: number;
    loop(data, dropKey, (_item, index, arr) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      ar.splice(i!, 0, dragObj!);
    } else {
      ar.splice(i! + 1, 0, dragObj!);
    }
  }
  return updateKeys(data, info);
};
