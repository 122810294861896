import { useTheme } from '@emotion/react';
import { App, ConfigProvider } from 'antd';
import { PropsWithChildren } from 'react';

type AntdProviderProps = PropsWithChildren;

export function AntdProvider({ children }: AntdProviderProps) {
  const xTheme = useTheme();

  return (
    <ConfigProvider
      theme={{
        components: {
          Notification: {
            zIndexPopup: 2050,
          },
        },
        token: {
          borderRadius: 6,
          colorError: xTheme.colors.error,
          colorSuccess: xTheme.colors.success,
          colorWarning: xTheme.colors.warning,
          colorInfo: xTheme.colors.info,
          colorPrimary: xTheme.colors.brandPrimary,
          fontFamily: xTheme.fontFamily,
          colorInfoBg: xTheme.colors.infoBg,
          colorSuccessBg: xTheme.colors.successBg,
          colorErrorBg: xTheme.colors.errorBg,
          colorWarningBg: xTheme.colors.warningBg,
          colorPrimaryBg: xTheme.colors.infoBg,
          green: xTheme.colors.success,
          green1: xTheme.colors.successBg,
          red: xTheme.colors.error,
          red1: xTheme.colors.errorBg,
          gold: xTheme.colors.warning,
          gold1: xTheme.colors.warningBg,
          blue: xTheme.colors.info,
          blue1: xTheme.colors.infoBg,
          orange: xTheme.colors.accent,
        },
      }}
    >
      <App notification={{ bottom: 100 }}>{children}</App>
    </ConfigProvider>
  );
}
