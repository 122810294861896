import * as R from 'ramda';
import { defaultDynamicOptions, stringOption } from '../config';
import { JsonFormSchema } from '../types';

function transformRequestBody(schema: JsonFormSchema): JsonFormSchema {
  const path = ['properties', 'RequestBodyParameters', 'properties'];

  return R.pipe(
    R.assocPath([...path, 'Body', 'renderer'], 'expression'),
    R.assocPath([...path, 'Body', 'inputProps'], { hideLabel: false }),
    R.assocPath([...path, 'ContentType', 'renderer'], 'select'),
    R.assocPath([...path, 'ContentType', 'inputProps'], {
      hideLabel: false,
    }),
    R.assocPath([...path, 'Encoding', 'renderer'], 'select'),
    R.assocPath([...path, 'Encoding', 'inputProps'], { hideLabel: false }),
  )(schema);
}

export function transformHttpActionSchema(
  schema: JsonFormSchema,
): JsonFormSchema {
  const hasRequestBody = Boolean(schema.properties?.RequestBodyParameters);
  const propsToModify = ['Headers', 'QueryParameters'];
  const keyPlaceHolders: Record<string, string> = {
    Headers: 'Header Name',
    QueryParameters: 'Parameter Name',
  };

  return R.pipe(
    R.assocPath(['properties', 'RequestURL', 'renderer'], 'dynamic'),
    R.assocPath(['properties', 'RequestURL', 'inputProps'], {
      inputOptions: defaultDynamicOptions,
    }),
    R.when(R.always(hasRequestBody), transformRequestBody),
    R.reduce(
      (acc: any, prop: string) => {
        const keyPath = ['properties', prop, 'items', 'properties', 'Name'];
        const valuePath = ['properties', prop, 'items', 'properties', 'Value'];

        return R.pipe(
          R.assocPath(['properties', prop, 'inputProps'], {
            hideLabel: true,
            isKeyValue: true,
          }),
          R.assocPath([...keyPath, 'renderer'], 'text'),
          R.assocPath([...keyPath, 'inputProps'], {
            placeholder: keyPlaceHolders[prop],
            autoComplete: 'off',
            hideLabel: true,
          }),
          R.assocPath([...valuePath, 'renderer'], 'dynamic'),
          R.assocPath([...valuePath, 'inputProps'], {
            placeholder: 'Value',
            inputOptions: [stringOption],
            defaultInputType: 'string',
            hideLabel: true,
          }),
        )(acc);
      },
      R.__,
      propsToModify,
    ),
  )(schema);
}
