import * as R from 'ramda';

/*
 * searchString = 'test'
 * targetString = 'testing'
 * true
 *
 * searchString = 'test'
 * targetString = 'nesting'
 * false
 */

/** Returns true when the target string includes the search string */
export const caseIndifferentSearch = R.curry(
  (searchString: string, targetString: string) =>
    R.includes(R.toLower(searchString), R.toLower(targetString)),
);

export default caseIndifferentSearch;
