import { TCurry2 } from '@x/types';
import * as R from 'ramda';

export const isTrue = R.equals(true);
export const isFalse = R.equals(false);

/**Returns true if any of the supplied array elements are true. */
export const anyTrue = R.any(isTrue);

/**Returns true only if all of the supplied array elements are true. */
export const allTrue = R.all(isTrue);

export const isNot = R.complement(R.is);

export const notEquals: TCurry2<unknown, unknown, boolean> = R.complement(
  R.equals,
);

/**Returns true if val has any of the provided props. */
export const hasAny = R.curry((props: string[], val: unknown) =>
  R.pipe(R.map(R.has), R.anyPass, R.apply(R.__, [val]))(props),
);
