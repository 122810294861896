import { LoadingOutlined } from '@ant-design/icons';
import { defaultKeymap } from '@codemirror/commands';
import { EditorView, keymap, tooltips } from '@codemirror/view';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { useProtectedDebounce } from '@x/hooks';
import { Card } from 'antd';
import React, { useRef, useState } from 'react';
import { useContainerHeight, useLoaded, useValues } from './hooks';
import './styles.css';
import { getErrorPanel, getWarningPanel } from './utils/codePanels';
import { altEnter } from './utils/customKeymaps';
import { getLanguageExtensions } from './utils/getLanguageExtensions';
import { formatSetErrsBlur } from './utils/jsHelpers';

type CodeProps = {
  mode?: string;
  initialValue?: Record<string, unknown> | string;
  value?: Record<string, unknown> | string;
  onChange?: (val: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  height?: number;
  fullHeightOffset?: number;
  noContainer?: boolean;
  loading?: boolean;
  warningMessage?: string;
  name?: string;
  tooltipParent?: HTMLElement | null; // used to ensure tooltips are not cut off by the code container
  isFlow?: boolean;
  'data-testid'?: string;
  style?: React.CSSProperties;
};

export const Code = ({
  mode,
  initialValue,
  value,
  onChange,
  placeholder,
  readOnly,
  height,
  fullHeightOffset,
  noContainer,
  loading,
  warningMessage,
  name,
  tooltipParent,
  isFlow,
  ...props
}: CodeProps): React.JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const editor = useRef<ReactCodeMirrorRef>(null);
  const loaded = useLoaded();
  const containerHeight = useContainerHeight({
    height,
    fullHeightOffset,
    loaded,
  });
  const { memoizedInitialValue, codeMirrorValue } = useValues(
    initialValue,
    value,
  );

  function handleChange(val: string) {
    if (errorMessage) setErrorMessage('');

    if (onChange) {
      onChange(val);
    }
  }

  const onChangeValue = useProtectedDebounce(handleChange, 100);

  async function handleBlur() {
    if (mode === 'javascript') {
      await formatSetErrsBlur(setErrorMessage, editor.current?.view);
    }
  }

  if (!loaded || loading) {
    return <LoadingOutlined />;
  }

  return (
    <Card
      style={{
        maxHeight: containerHeight,
        overflow: 'auto',
        textAlign: 'left',
        width: '100%',
        borderWidth: noContainer ? 0 : 1,
        ...props.style,
      }}
      styles={{ body: { padding: 0 } }}
    >
      <CodeMirror
        ref={editor}
        data-testid={props['data-testid']}
        value={
          memoizedInitialValue.current
            ? memoizedInitialValue.current
            : codeMirrorValue
        }
        onChange={onChangeValue}
        extensions={[
          keymap.of([
            {
              key: 'Alt-Enter',
              run: altEnter(mode, setErrorMessage),
            },
            ...defaultKeymap,
          ]),
          EditorView.lineWrapping,
          tooltips({
            position: 'absolute',
            parent: tooltipParent as HTMLElement,
          }),
          ...getWarningPanel(warningMessage),
          ...getErrorPanel(errorMessage),
          ...getLanguageExtensions(mode, isFlow, readOnly),
        ]}
        basicSetup={{
          crosshairCursor: false,
          highlightActiveLine: false,
          highlightActiveLineGutter: false,
          autocompletion: mode === 'javascript',
        }}
        placeholder={placeholder}
        readOnly={readOnly}
        onBlur={handleBlur}
        height="100%"
        className={readOnly ? 'readOnly' : ''}
      />
    </Card>
  );
};
