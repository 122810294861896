/**
 * Component-Level Error Boundary to catch unhandled exceptions
 */
import { Component, ReactNode } from 'react';
import { ComponentError } from './ComponentError';

type ComponentErrorBoundaryProps = {
  componentName: string;
  children: ReactNode;
};

type ComponentErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
};

export class ComponentErrorBoundary extends Component<
  ComponentErrorBoundaryProps,
  ComponentErrorBoundaryState
> {
  public state: ComponentErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(
    error: Error,
  ): ComponentErrorBoundaryState {
    return { hasError: true, error: error };
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <ComponentError componentName={this.props.componentName} />;
    }

    return this.props.children;
  }
}
