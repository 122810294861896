import { DynamicInputOption } from '@x/types';
import { Radio, Tooltip } from 'antd';
import * as R from 'ramda';
import React from 'react';

type InputSelectorProps<T1, T2> = {
  inputProps: T1;
  inputOptions: DynamicInputOption<T2>[];
  activeInput: string | undefined;
  defaultInput?: string;
  onInputChange?: (cur: string, prev: string) => void;
};

export function InputSelector<T1, T2>({
  inputProps,
  inputOptions,
  activeInput,
  defaultInput = 'expression',
  onInputChange,
}: InputSelectorProps<T1, T2>): React.JSX.Element {
  const currentType = activeInput || defaultInput;

  function renderActiveInput() {
    const currentOpt = R.find(R.propEq('type', currentType), inputOptions);
    const mergedProps = R.mergeDeepRight(inputProps, currentOpt.props ?? {});

    return React.createElement(currentOpt.input, {
      ...mergedProps,
      key: currentOpt.name,
    });
  }

  return (
    <>
      <Radio.Group
        value={currentType}
        onChange={({ target }) => {
          if (onInputChange) onInputChange(target.value, currentType);
        }}
        size="small"
        buttonStyle="solid"
        style={{ marginRight: 24, marginBottom: -1 }}
      >
        {inputOptions.map((opt, index) => {
          const firstButton = index === 0;
          const lastButton = index === inputOptions.length - 1;

          return (
            <Radio.Button
              key={opt.name}
              value={opt.type}
              disabled={opt.disabled}
              data-testid={`valueToggle-${opt.type.toLowerCase()}`}
              style={{
                ...(firstButton ? { borderBottomLeftRadius: 0 } : {}),
                ...(lastButton ? { borderBottomRightRadius: 0 } : {}),
              }}
            >
              <Tooltip title={opt.disabled ? 'Not available' : opt.name}>
                {opt.icon}
              </Tooltip>
            </Radio.Button>
          );
        })}
      </Radio.Group>
      {renderActiveInput()}
    </>
  );
}
