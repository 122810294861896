import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';

export function UnstyledButton({ ...props }: ButtonProps): React.JSX.Element {
  return (
    <Button type="link" className="no-btn-style" {...props}>
      {props.children}
    </Button>
  );
}
