import _debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';

export function useProtectedDebounce<T extends (...args: any[]) => void>(
  fn: T,
  delay: number,
): T {
  const cancelRef = useRef(false);

  useEffect(
    () => () => {
      cancelRef.current = true;
    },
    [cancelRef],
  );

  return useMemo(
    () =>
      _debounce((...args) => {
        if (cancelRef.current) return;
        fn(...args);
      }, delay),
    [fn, delay],
  ) as unknown as T; //Typescript and React workaround
}
