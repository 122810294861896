import { useEffect, useRef } from 'react';

/**Ensures the supplied callback function is called on update after the component has successfully mounted. Accepts dependencies.*/
export function useOnUpdate(callbackFn: () => void, deps: unknown[]): void {
  const mountedRef = useRef(false);
  const callbackRef = useRef(callbackFn);

  callbackRef.current = callbackFn;

  useEffect(() => {
    if (mountedRef.current) {
      callbackRef.current();
    } else {
      mountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mountedRef, callbackRef, ...deps]);
}
