import { InputRef } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { RefSelectProps } from 'antd/lib/select';
import { RefObject, useEffect } from 'react';

export function useFocusOnMount(
  ref?: RefObject<InputRef | TextAreaRef | RefSelectProps>,
): void {
  useEffect(() => {
    if (ref?.current) ref.current.focus();
  }, [ref]);
}
