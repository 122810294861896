import _camelCase from 'lodash/camelCase';
import * as R from 'ramda';

const isNotNilObject = R.both(R.is(Object), R.complement(R.isNil));
const camelizeKey = R.juxt([
  R.o(_camelCase, R.head),
  R.o(camelizeKeys, R.last),
]);

const camelizeObj = R.compose(R.fromPairs, R.map(camelizeKey), R.toPairs);
const camelizeArray = R.map(camelizeKeys);

// camelizeKeys :: a -> b
// source: https://github.com/ramda/ramda/wiki/Cookbook#camelizekeys
export function camelizeKeys<T = unknown>(obj: unknown): T {
  return R.cond([
    [R.is(Array), camelizeArray],
    [R.is(Function), R.identity],
    [isNotNilObject, camelizeObj],
    [R.T, R.identity],
  ])(obj);
}
