import {
  arrayPropertyBooleans,
  basePropertyBooleans,
  KEY_PROPERTY_SEPARATOR,
  NODE_SEPARATOR,
  objectPropertyBooleans,
  openApiBooleans,
} from '@x/config';
import { SchemaPropertyTypes, SelectOption } from '@x/types';
import * as R from 'ramda';
import React from 'react';
import { XTreeDataNode } from '../types';
import { getNodeName } from './helpers';

export function getFormBooleans(
  type?: SchemaPropertyTypes,
  isOpenApi = false,
): SelectOption[] {
  const booleans = isOpenApi
    ? R.concat(basePropertyBooleans, openApiBooleans)
    : basePropertyBooleans;

  switch (type) {
    case 'array':
      return R.concat(arrayPropertyBooleans, booleans);
    case 'object':
      return R.concat(objectPropertyBooleans, booleans);
    default:
      return booleans;
  }
}

type ConvertJsonToTreeDataArgs = {
  data?: Record<string, unknown>;
  parentLocation?: string;

  // arrayDisplayMap provides a way to specify an object property to use
  // for the display of object arrays. e.g. {parameters: 'name'} will
  // list the children of the parameters array using the name property of the children.
  // Otherwise, they will simply be listed by their index.
  arrayDisplayMap?: Record<string, string>;

  sort?: boolean;

  // renders undefined properties in the tree - makes for a more responsive minimap
  // experience, i.e. when used in a flow map step
  includeUndefined?: boolean;

  // completely ignores provided strings; useful for properties or keys you don't want rendered in
  // the minimap, i.e. '$InputJson'
  ignore?: string[];

  // includes the provided strings as part of the key path prefix/parent location only; will not return as a standalone key
  prefixOnly?: string[];

  // activates filtering specific to a map step JSON, i.e. ignores '$InputJson' and 'Map' objects. Still allows for properties and maps with a key of 'Map'
  isMap?: boolean;

  // particularly for JSON Schema, allow the title property of an object to be displayed as the node title instead of the key
  displayTitlesAsKeys?: boolean;
};

export function convertJsonToTreeData({
  data,
  parentLocation,
  arrayDisplayMap,
  sort = true,
  includeUndefined,
  ignore,
  prefixOnly,
  isMap,
  displayTitlesAsKeys,
}: ConvertJsonToTreeDataArgs): XTreeDataNode[] {
  if (!data) return [];

  const treeData = Object.keys(data).flatMap((key) => {
    const property = data[key];

    if (
      ignore?.includes(key) ||
      ((property === '' || property === undefined) && !includeUndefined)
    )
      return [];

    if (isMap) {
      if (key === '$InputJson') return [];

      if (
        key === 'Map' &&
        typeof property === 'object' &&
        !R.prop('$InputJson', property)
      ) {
        return convertJsonToTreeData({
          data: property,
          parentLocation: `${parentLocation}${NODE_SEPARATOR}${key}`,
          sort,
          includeUndefined,
          ignore,
          prefixOnly,
          isMap,
        } as ConvertJsonToTreeDataArgs);
      }
    } else if (prefixOnly?.includes(key)) {
      return convertJsonToTreeData({
        data: property,
        parentLocation: `${parentLocation}${NODE_SEPARATOR}${key}`,
        sort,
        includeUndefined,
        ignore,
        prefixOnly,
        isMap,
      } as ConvertJsonToTreeDataArgs);
    }

    const title = getTreeDataTitle({
      property,
      key,
      data,
      arrayDisplayMap,
      parentLocation,
      displayTitlesAsKeys,
    });
    const location = `${
      parentLocation ? parentLocation + NODE_SEPARATOR : ''
    }${key}`;

    return [
      {
        title,
        key: location,
        children:
          typeof property === 'object'
            ? convertJsonToTreeData({
                data: property,
                parentLocation: location,
                arrayDisplayMap,
                sort,
                includeUndefined,
                ignore,
                prefixOnly,
                isMap,
              } as ConvertJsonToTreeDataArgs)
            : undefined,
      },
    ];
  });

  return sort
    ? treeData.sort((obj) => {
        return obj.children ? 1 : -1;
      })
    : treeData;
}

type GetTreeDataTitleArgs = {
  property: any;
  key: string;
  data: Record<string, unknown>;
  arrayDisplayMap?: Record<string, string>;
  parentLocation?: string;
  displayTitlesAsKeys?: boolean;
};

function getTreeDataTitle({
  property,
  key,
  data,
  arrayDisplayMap,
  parentLocation,
  displayTitlesAsKeys,
}: GetTreeDataTitleArgs): string {
  if (Array.isArray(data) && arrayDisplayMap) {
    if (typeof property === 'string') return property;

    return R.pipe(
      R.split(NODE_SEPARATOR),
      R.last(),
      R.prop(R.__, arrayDisplayMap),
      R.propOr(key, R.__, property),
    )(parentLocation);
  }

  if (typeof property === 'object')
    if (displayTitlesAsKeys && property.title) return property.title;
    else return key;

  return `${key}${KEY_PROPERTY_SEPARATOR}${property}`;
}

export const splitNodePath = R.split(NODE_SEPARATOR);

export function moveKey(
  from: React.Key,
  to: string,
  pos: number,
  obj: Record<string, any>,
  shouldNest: boolean,
): Record<string, any> {
  const fromPath = splitNodePath(from);
  const fromVal = R.path(fromPath, obj);
  const parentFromPath = R.dropLast(1, fromPath);
  const toPath = splitNodePath(to);
  const parentToPath = R.dropLast(1, toPath);
  const destinationPath = shouldNest ? R.append('Map', toPath) : parentToPath;
  const updatedPos = R.cond([
    [R.always(shouldNest), R.always(0)],
    [R.equals(-1), R.always(0)],
    [R.T, R.identity],
  ])(pos);
  const nulledObj = R.assocPath(fromPath, null, obj);

  const addKey = R.pipe(
    R.toPairs,
    R.insert(updatedPos, [R.last(fromPath), fromVal]),
    R.reject(R.pathEq([1], null)),
    R.fromPairs,
  );

  const withAddedKey = destinationPath.length
    ? R.modifyPath(destinationPath, addKey, nulledObj)
    : addKey(nulledObj);

  const removeKey = R.pipe(
    R.toPairs,
    R.reject(R.pathEq([1], null)),
    R.fromPairs,
  );

  const updatedObject = parentFromPath.length
    ? R.modifyPath(parentFromPath, removeKey, withAddedKey)
    : removeKey(withAddedKey);

  return updatedObject;
}

export function filterTreeDataByKeys(
  treeData?: XTreeDataNode[],
  keys?: (string | number)[],
): XTreeDataNode[] | undefined {
  if (!treeData || !keys) return undefined;

  return treeData
    .map((dataNode) => {
      if (dataNode.children) {
        return {
          ...dataNode,
          children: filterTreeDataByKeys(dataNode.children, keys),
        };
      }

      return dataNode;
    })
    .filter((dataNode) => {
      if (dataNode.children) {
        return dataNode.children.length > 0;
      }

      return keys.includes(getNodeName(dataNode));
    });
}
