import { ObjectPath, TCurry2, TCurry3, TError } from '@x/types';
import { getAxiosErrors } from '@x/utils';
import { App } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import { AxiosError } from 'axios';
import * as R from 'ramda';
import { ReactNode, useCallback } from 'react';

function getNodeText(node: ReactNode): string {
  if (!node) return '';
  if (['string', 'number'].includes(typeof node)) return node.toString();
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node)
    return getNodeText((node as any).props.children);
  return '';
}

function getDurationBasedOnMessageLength(
  message: string,
  description?: ReactNode,
) {
  //50ms per character based on https://ux.stackexchange.com/questions/11203/how-long-should-a-temporary-notification-toast-appear
  const totalMessageLength = message.length + getNodeText(description).length;
  const suggestedDuration = (totalMessageLength * 50) / 1000;

  return Math.max(3, suggestedDuration);
}

type NotificationProps = Omit<ArgsProps, 'message'>;

export type UseFeedbackMessageResponse = {
  showErrorMessage: (
    message: string,
    notificationProps?: NotificationProps,
  ) => void;
  showInfoMessage: (
    message: string,
    notificationProps?: NotificationProps,
  ) => void;
  showSuccessMessage: (
    message: string,
    notificationProps?: NotificationProps,
  ) => void;
  showWarningMessage: (
    message: string,
    notificationProps?: NotificationProps,
  ) => void;
  showAxiosErrors: TCurry2<string, unknown, void>;
  showErrorAt: TCurry3<string, ObjectPath, unknown, void>;
};

export function useFeedbackMessage(): UseFeedbackMessageResponse {
  const { notification } = App.useApp();
  const placement = 'bottomRight';

  function showSuccessMessage(
    message: string,
    notificationProps?: NotificationProps,
  ) {
    const duration = getDurationBasedOnMessageLength(
      message,
      notificationProps?.description,
    );

    notification.success({
      message,
      duration,
      placement,
      ...notificationProps,
    });
  }

  const showErrorMessage = useCallback(
    (message: string, notificationProps?: NotificationProps) => {
      const duration = getDurationBasedOnMessageLength(
        message,
        notificationProps?.description,
      );

      notification.error({
        message,
        duration,
        placement,
        ...notificationProps,
      });
    },
    [notification],
  );

  function showWarningMessage(
    message: string,
    notificationProps?: NotificationProps,
  ) {
    const duration = getDurationBasedOnMessageLength(
      message,
      notificationProps?.description,
    );

    notification.warning({
      message,
      duration,
      placement,
      ...notificationProps,
    });
  }

  function showInfoMessage(
    message: string,
    notificationProps?: NotificationProps,
  ) {
    const duration = getDurationBasedOnMessageLength(
      message,
      notificationProps?.description,
    );

    notification.info({ message, duration, placement, ...notificationProps });
  }

  const showAxiosErrors = R.curry(
    (
      defaultErrorMessage: string,
      errorResponse: AxiosError<{ errors: TError[] }>,
    ) => {
      const errors = getAxiosErrors(defaultErrorMessage, errorResponse);

      function renderErrors() {
        if (errors.length === 1) return errors[0];

        return (
          <ul
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            {errors.map((error: string, index: number) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        );
      }

      showErrorMessage(`Error${errors.length > 1 ? 's' : ''}`, {
        description: renderErrors(),
      });
    },
  );

  const showErrorAt = R.curry(
    (defaultMessage: string, pathToMessage: ObjectPath, error: unknown) => {
      const errMessage = R.pathOr(defaultMessage, pathToMessage, error);

      if (!errMessage || typeof errMessage !== 'string')
        return showErrorMessage(defaultMessage);

      showErrorMessage(errMessage);
    },
  );

  return {
    showErrorMessage,
    showInfoMessage,
    showSuccessMessage,
    showWarningMessage,
    showAxiosErrors,
    showErrorAt,
  };
}
