import { aptrinsic } from '@x/lib';
import { decode } from 'jsonwebtoken';
import _capitalize from 'lodash/capitalize';
import _castArray from 'lodash/castArray';
import React, { ReactNode, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { UserContext } from '../UserContext';

interface UserProviderProps {
  children: ReactNode;
}

export function UserProvider({
  children,
}: UserProviderProps): React.JSX.Element {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (aptrinsic && auth.user?.profile) {
      const { email, given_name, family_name, sub } = auth.user.profile;
      const jwtDetails = decode(auth.user.access_token);
      const validJwt = jwtDetails && typeof jwtDetails === 'object';
      const accountId = email ? email.match(/@(\w+)\./)?.[1] : 'unknown';

      invariant(validJwt, 'JWT is invalid');

      //passing user and account objects:
      aptrinsic(
        'identify',
        {
          //User Fields
          id: sub, // Required for logged in app users
          email,
          firstName: given_name,
          lastName: family_name,
          roles: _castArray(jwtDetails.roles).join(','),
        },
        {
          //Account Fields
          id: accountId, // Required
          name: _capitalize(accountId),
        },
      );
    }
  }, [auth]);

  function logout(shouldRedirect: boolean) {
    localStorage.removeItem('auth');
    localStorage.removeItem('authPortal');
    localStorage.removeItem('userDetails');

    if (shouldRedirect) {
      navigate('/');
    }
  }

  return (
    <UserContext.Provider value={{ logout }}>{children}</UserContext.Provider>
  );
}
