import { useCallback, useState } from 'react';
import { useProtectedDebounce } from './useProtectedDebounce';

export function useDebouncedState<T>(
  initialValue: T,
  ms = 300,
): [T, T, (val: T) => void] {
  const [immediateState, setImmediateState] = useState<T>(initialValue);
  const [debouncedState, setDebouncedState] = useState<T>(initialValue);
  const updateDebouncedState = useProtectedDebounce(setDebouncedState, ms);

  const setState = useCallback(
    (value: T): void => {
      setImmediateState(value);
      updateDebouncedState(value);
    },
    [updateDebouncedState],
  );

  return [immediateState, debouncedState, setState];
}
