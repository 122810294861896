import _noop from 'lodash/noop';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';

/*
 * sometimes we use a query, but the second it's done loading the data
 * isn't ready yet, so we can show the skeleton a bit longer while we
 * get everything ready
 */
const checkNotLoading = R.compose(
  R.all(R.anyPass([R.prop('isError'), R.prop('isIdle'), R.prop('isSuccess')])),
  R.unless(R.is(Array), R.of),
);

export function useShowSkeleton(
  queries: UseQueryResult<unknown> | UseQueryResult<unknown>[],
  delay = 100,
): boolean {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const notLoading = checkNotLoading(queries);

    if (notLoading) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, delay);

      return () => clearTimeout(timeout);
    } else {
      setLoading(true);
    }

    return _noop;
  }, [queries, delay]);

  return loading;
}
