import {
  CacheObject,
  PaginatedCursorResponse,
  PaginatedNextRowKeyResponse,
  PaginatedResponse,
} from '@x/types';
import { AxiosResponse } from 'axios';
import * as R from 'ramda';
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';

type PaginatedQuery<T> = UseQueryResult<PaginatedResponse<T>>;

export function getPaginatedData<T>(paginatedQuery: PaginatedQuery<T>): T[] {
  return R.pathOr([], ['data', 'data'])(paginatedQuery);
}

export function flattenPaginatedQueriesResults<T>(
  paginatedQueries: PaginatedQuery<T>[],
): T[] {
  return R.chain(getPaginatedData)(paginatedQueries);
}

/**Defaults to an empty array. */
export function flattenInfiniteQueryResults<T>(
  query: UseInfiniteQueryResult<
    | PaginatedResponse<T>
    | PaginatedCursorResponse<T>
    | PaginatedNextRowKeyResponse<T>
  >,
): T[] {
  return R.pipe(
    R.pathOr([], ['data', 'pages']),
    R.chain(R.prop('data')),
  )(query);
}

export function flattenInfiniteCosmosData(
  query: UseInfiniteQueryResult<AxiosResponse<CacheObject[]>, unknown>,
): CacheObject[] {
  return R.pipe(
    R.pathOr([], ['data', 'pages']),
    R.chain(R.pathOr([], ['data', 'documents'])),
  )(query);
}
