import dayjs from 'dayjs';

export function secondsToUnix(time: string | number): string {
  return dayjs
    .unix(+time)
    .format()
    .replace('Z', '+00:00');
}

export default secondsToUnix;
