import { FlowEvaluators, JsonSchema } from '@x/types';
import { stringPathToArrayPath } from '@x/utils';
import * as R from 'ramda';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { FormRenderer } from '../components/FormRenderer';
import {
  FormInterface,
  InputProps,
  JsonFormSchema,
  MakeFormProps,
  UpdateFormOptions,
} from '../types';
import { ensureJsonFormSchema } from './ensureJsonFormSchema';

interface UseFormOptions extends Partial<MakeFormProps> {
  ErrorComponent?: (props: InputProps) => React.JSX.Element;
  readOnly?: boolean;
  showHiddenValues?: boolean;
  engineVersion?: number;
  evaluator?: FlowEvaluators | number | null;
}

const valueDidntChange = R.curry(
  (
    path: string,
    value: unknown,
    force: boolean,
    data: Record<string, unknown>,
  ) =>
    R.compose(
      R.when(R.always(force), R.F),
      R.equals(value),
      R.path(stringPathToArrayPath(path)),
    )(data),
);

export function makeForm(defaultOptions: MakeFormProps) {
  return function useForm<T>(
    initialData: T,
    options: UseFormOptions = {},
  ): FormInterface<T> {
    const [formKey, setFormKey] = useState(v4());
    const [errors, setErrors] = useState<Record<string, any>>({});
    const [formData, setFormData] = useState<T>(initialData);
    const [forceValidation, setForceValidation] = useState(false);
    const [touched, setTouched] = useState(false);
    const memoizedInitialData = useRef(initialData);
    const ErrorComponent =
      options.ErrorComponent || defaultOptions.ErrorComponent;
    const defaultValidator =
      options.defaultValidator || defaultOptions.defaultValidator;
    const defaultRenderers = defaultOptions.renderers;
    const instanceRenderers = options.renderers;
    const renderers = useMemo(
      () => [...(instanceRenderers || []), ...defaultRenderers],
      [defaultRenderers, instanceRenderers],
    );
    const stringifiedData = JSON.stringify(memoizedInitialData.current);
    const stringifiedForm = JSON.stringify(formData);
    const hasChanges = stringifiedData !== stringifiedForm;
    const setAndTouchFormData = useCallback(
      (newData: any, ignoreTouch = false) => {
        // checking ignoreTouch !== true instead of !ignoreTouch or something similar
        // because the function this is overwriting is being called with extra params
        // in multiple places and trying to limit the scope of change
        if (ignoreTouch !== true) {
          setTouched(true);
        }

        setFormData(newData);
      },
      [],
    );
    const updateValue = useCallback(
      (
        {
          path,
          value,
        }: {
          path: string;
          value: unknown;
        },
        updateOpts?: UpdateFormOptions,
      ) =>
        setAndTouchFormData(
          R.unless(
            valueDidntChange(path, value, updateOpts?.force),
            R.assocPath(stringPathToArrayPath(path), value),
          ),
          updateOpts?.dontTouch,
        ),
      [setAndTouchFormData],
    );

    const InputWrapper = options.InputWrapper ?? defaultOptions.InputWrapper;
    const Render = (schema: JsonSchema | JsonFormSchema) => (
      <React.Suspense fallback="loading...">
        <FormRenderer
          InputWrapper={InputWrapper}
          key={formKey}
          ErrorComponent={ErrorComponent}
          originalSchema={schema}
          originalValue={initialData}
          renderers={renderers}
          defaultValidator={defaultValidator}
          schema={ensureJsonFormSchema(schema)}
          errors={errors}
          setErrors={setErrors}
          value={formData}
          setValue={setAndTouchFormData}
          updateValue={updateValue}
          forceValidation={forceValidation}
          readOnly={options.readOnly}
          showHiddenValues={options.showHiddenValues}
          engineVersion={options.engineVersion}
          evaluator={options.evaluator}
          setTouched={setTouched}
        />
      </React.Suspense>
    );
    const hasErrors = useRef(R.keys(errors).length > 0);

    hasErrors.current = R.keys(errors).length > 0;

    const reset = useCallback(() => {
      setForceValidation(false);
      setFormKey(v4());
      setAndTouchFormData(memoizedInitialData.current, true);
      setErrors({});
      setTouched(false);
    }, [memoizedInitialData, setAndTouchFormData]);

    if (!R.equals(initialData, memoizedInitialData.current)) {
      memoizedInitialData.current = initialData;
      reset();
    }

    return {
      readOnly: Boolean(options.readOnly),
      render: Render,
      hasChanges,
      touched,
      hasErrors: hasErrors.current,
      errors,
      formData: formData,
      setFormData: setAndTouchFormData,
      reset,
      validate: () =>
        new Promise((res) => {
          setForceValidation(true);

          setTimeout(() => {
            res(!hasErrors.current);
          }, 100); // waiting form to re-render, need to make better
        }),
    };
  };
}
