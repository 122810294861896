import { NODE_SEPARATOR } from '@x/config';
import * as R from 'ramda';
import { XTreeDataNode } from '../types';
import { getTreePath } from './getTreePath';

export function nodeKeyToKeyArray(key: string): string[] {
  return R.split(NODE_SEPARATOR, key);
}

export function getNodeName(node: XTreeDataNode): string {
  return getKeyName(node.key);
}

export function getKeyName(key: string): string {
  return R.last(nodeKeyToKeyArray(key));
}

export function keyArrayToNodeKey(keyArray: string[]): string {
  return R.join(NODE_SEPARATOR, keyArray);
}

export function getNode(
  nodePath: string[],
  treeData: XTreeDataNode[],
): XTreeDataNode {
  const treePath = getTreePath(nodePath, treeData);

  return R.path(treePath, treeData);
}

export function getAllKeysFromTree(data?: XTreeDataNode[]): string[] {
  if (!data || !data.length) return [];

  return data.flatMap((node) => {
    const key = node.key;

    const childKeys = node.children ? getAllKeysFromTree(node.children) : [];

    return [key, ...childKeys];
  });
}

export function uniqueKey(key: string, siblingNodeKeys: string[]): string {
  return siblingNodeKeys.includes(key)
    ? uniqueKey(`${key}_`, siblingNodeKeys)
    : key;
}

export function mapTreeData<T>(
  transformer: (n: XTreeDataNode) => XTreeDataNode,
  treeData: XTreeDataNode[] | undefined,
): T[] {
  if (!treeData) return [];

  function recurse({ children = [], ...node }: XTreeDataNode) {
    return {
      ...node,
      children: mapTreeData(transformer, children),
    };
  }

  return treeData.map((n) => {
    return R.pipe(R.when(R.prop('children'), recurse), transformer)(n);
  });
}
