import querystring from 'querystring';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryString<T = Record<string, string>>(): T {
  const { search } = useLocation();

  return useMemo(
    // I don't know exactly what querystring.parse is returning
    // but this is the easiest way I could think of to get it to behave
    // as a normal object. Specific issue I was running into was when
    // a value was an array it wasn't a real array
    // so val instanceof Array was false, which was an issue for our camelizeKeys helper
    () => JSON.parse(JSON.stringify(querystring.parse(search.substring(1)))),
    [search],
  );
}
