import { TCurry3 } from '@x/types';
import { ensureArrayPath } from '@x/utils';
import { AppRegistration } from 'api/types';
import * as R from 'ramda';

export const findPartnerIdByUrlPart: TCurry3<
  string | (string | number)[],
  string,
  AppRegistration[],
  number | undefined
> = R.curry(
  (
    appPath: string,
    value: string,
    appRegistrations: { partnerUrlPart: string }[],
  ) => {
    return R.pipe(
      R.find(
        R.propEq('partnerUrlPart', R.path(ensureArrayPath(appPath), value)),
      ),
      R.prop('partnerId'),
    )(appRegistrations);
  },
);
