import { QueryClient } from 'react-query';

const isTest = process.env.NODE_ENV === 'test';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: isTest
        ? Infinity // always use cache in tests
        : 1000, // use cache for 1 second
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
    },
  },
});
