import { VSpace } from '@x/components';
import { Divider } from 'antd';
import _map from 'lodash/map';
import React from 'react';
import { InputProps, JsonFormSchema } from '../types';
import { FormError } from './FormError';
import { FormRenderer } from './FormRenderer';

export function ObjectInput(props: InputProps): React.JSX.Element {
  const { path, schema, label } = props;
  const { inputProps = {}, properties, title, required } = schema;
  const { hideLabel = !title, ...dividerProps } = inputProps;
  const pathStart = path ? `${path}.` : '';

  if (!properties) {
    return <FormError {...props} error="Object did not have any properties" />;
  }

  return (
    <>
      {!hideLabel && <Divider {...dividerProps}>{label}</Divider>}
      <VSpace>
        {_map(properties, (property: JsonFormSchema, key) => (
          <FormRenderer
            {...props}
            key={key}
            path={`${pathStart}${key}`}
            schema={property}
            requiredFields={required}
          />
        ))}
      </VSpace>
    </>
  );
}
