import { useCallback, useEffect, useRef, useState } from 'react';

export function useActiveTyping(delay = 300): boolean {
  const [isTyping, setIsTyping] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const cancelRef = useRef(false);

  useEffect(
    () => () => {
      cancelRef.current = true;
    },
    [cancelRef],
  );

  const handleKeyDown = useCallback(() => {
    if (!cancelRef.current) {
      setIsTyping(true);
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (cancelRef.current) return;
      setIsTyping(false);
    }, delay);
  }, [cancelRef, delay]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return isTyping;
}
