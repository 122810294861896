import { Diagnostic } from '@codemirror/lint';
import { Text } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import * as R from 'ramda';

/**Basic linting for JSON. Does not complain with an empty editor. */
export const jsonLinter = (view: EditorView): readonly Diagnostic[] => {
  const stringCode = view.state.doc.toString();

  if (!stringCode) return [];

  try {
    JSON.parse(stringCode);
  } catch (err) {
    return lintJsonParseErrors(err, view);
  }

  return [];
};

export function lintJsonParseErrors(
  e: unknown,
  view: EditorView,
): {
  from: number;
  message: string;
  severity: 'info' | 'error' | 'warning';
  to: number;
}[] {
  const pos = getPositionFromString(e, view.state.doc);

  return [
    {
      from: pos,
      message: R.propOr('Could not parse code', 'message', e),
      severity: 'error',
      to: pos,
    },
  ];
}

export function lintYamlParseErrors(e: unknown): {
  from: number;
  message: string;
  severity: 'info' | 'error' | 'warning';
  to: number;
}[] {
  return [
    {
      from: R.pathOr(0, ['source', 'range', 'start'], e),
      message: R.propOr('Could not parse code', 'message', e),
      severity: 'error' as 'info' | 'error' | 'warning',
      to: R.pathOr(0, ['source', 'range', 'end'], e),
    },
  ];
}

// from @codemirror/lang-json
export function getPositionFromString(error: unknown, doc: Text): number {
  const message = R.prop('message', error);
  const m = message.match(/at position (\d+)/);

  return m ? Math.min(+m[1], doc.length) : 0;
}
