import { PaginatedResponse, SelectOption } from '@x/types';
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { InfiniteSelect } from '../InfiniteSelect';

type InfiniteSelectFilterProps<T> = SelectProps<string> & {
  query: UseInfiniteQueryResult<PaginatedResponse<T>>;
  optionMap: (item: T) => SelectOption;
  value?: any;
  label?: string;
  limit?: number;
};

export function InfiniteSelectFilter<T>({
  query,
  optionMap,
  label,
  limit,
  value,
  ...props
}: InfiniteSelectFilterProps<T>): React.JSX.Element {
  const isSingle = limit === 1;

  return (
    <InfiniteSelect
      data-testid="select-filter"
      allowClear
      query={query}
      optionMap={optionMap}
      placeholder={label}
      value={value}
      style={{ width: '100%' }}
      optionFilterProp="label"
      mode={!isSingle ? 'tags' : undefined}
      showSearch
      {...props}
    />
  );
}
