import { useUpdatePaginatedQueryString } from '@x/hooks';
import { SelectOption } from '@x/types';
import { parseQueryString, qsValue } from '@x/utils';
import { Checkbox, Form } from 'antd';
import * as R from 'ramda';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface HorizontalToggleFiltersProps {
  filters: SelectOption[];
}

export function HorizontalToggleFilters({
  filters,
}: HorizontalToggleFiltersProps): React.JSX.Element {
  const { search } = useLocation();
  const { replacePaginatedQueryString } = useUpdatePaginatedQueryString();
  const getToggledValue = R.compose(
    R.ifElse(R.identity, R.always(''), R.T),
    qsValue(R.__, search),
  );

  function handleFilterChange(value: string | number) {
    replacePaginatedQueryString({ [value]: getToggledValue(value) });
  }

  return (
    <Form
      style={{ display: 'inline-block' }}
      initialValues={parseQueryString(search)}
    >
      {filters.map(({ label, value }) => (
        <Form.Item
          key={value}
          name={value}
          style={{ marginBottom: 0 }}
          valuePropName="checked"
        >
          <Checkbox
            onChange={() => handleFilterChange(value)}
            data-testid={`checkbox-${value}`}
          >
            {label}
          </Checkbox>
        </Form.Item>
      ))}
    </Form>
  );
}
